import React from "react";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import ConditionalLink from "@/components/ConditionalLink";

import { discountMatrixRoute } from "@/constants/routes";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";
import { useTranslation } from "react-i18next";

export const useTacticalCampaignsColumns = () => {
  const { t } = useTranslation();
  return [
    {
      field: "name",
      headerName: t("tacticalCampaignsPage.datagridColumns.column1"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.name) {
          return null;
        }
        return params.row.name;
      },
      renderCell: (params) => {
        if (!params.row.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row?.name} />;
      },
    },
    {
      field: "salesModel",
      headerName: t("tacticalCampaignsPage.datagridColumns.column2"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.salesModel) {
          return null;
        }
        return params.row.salesModel;
      },
      renderCell: (params) => {
        if (!params.row.salesModel) {
          return "--";
        }
        return <ConditionalTooltip title={params.row?.salesModel} />;
      },
    },
    {
      field: "campaignCode",
      headerName: t("tacticalCampaignsPage.datagridColumns.column3"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.campaignCode) {
          return null;
        }
        return params.row.campaignCode;
      },
      renderCell: (params) => {
        if (!params.row.campaignCode) {
          return "--";
        }
        return <ConditionalTooltip title={params.row?.campaignCode} />;
      },
    },
    {
      field: "campaignStatus",
      headerName: t("tacticalCampaignsPage.datagridColumns.column4"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.campaignStatus?.name) {
          return null;
        }
        return params.row.campaignStatus?.name;
      },
      renderCell: (params) => {
        if (!params.row.campaignStatus?.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.campaignStatus?.name} />;
      },
    },
    {
      field: "discountType",
      headerName: t("tacticalCampaignsPage.datagridColumns.column5"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.discountType) {
          return null;
        }
        return params.row.discountType;
      },
      renderCell: (params) => {
        if (!params.row.discountType) {
          return "--";
        }
        return <ConditionalTooltip title={params.row?.discountType} />;
      },
    },
    {
      field: "startDate",
      headerName: t("tacticalCampaignsPage.datagridColumns.column6"),
      type: "date",
      flex: 1,
      maxWidth: 150,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.startDate) {
          return null;
        }
        return new Date(params.row.startDate);
      },
      renderCell: (params) => {
        if (!params.row.startDate) {
          return "--";
        }
        return (
          <ConditionalTooltip
            title={
              params.row.startDate &&
              convertISODateToDayMonthYear(params.row.startDate)
            }
          />
        );
      },
    },
    {
      field: "endDate",
      headerName: t("tacticalCampaignsPage.datagridColumns.column7"),
      type: "date",
      flex: 1,
      maxWidth: 150,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.endDate) {
          return null;
        }
        return new Date(params.row.endDate);
      },
      renderCell: (params) => {
        if (!params.row.endDate) {
          return "--";
        }
        return (
          <ConditionalTooltip
            title={
              params.row.endDate &&
              convertISODateToDayMonthYear(params.row.endDate)
            }
          />
        );
      },
    },
    {
      field: "discountMatrix",
      headerName: t("tacticalCampaignsPage.datagridColumns.column8"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.discountMatrix?.name) {
          return null;
        }
        return params.row.discountMatrix?.name;
      },
      renderCell: (params) => {
        if (!params.row.discountMatrix?.name) {
          return "--";
        }
        return (
          <ConditionalLink
            title={params.row.discountMatrix?.name}
            url={discountMatrixRoute + params.row.discountMatrix?.id}
          />
        );
      },
    },
    {
      field: "overridingContractTerm",
      headerName: t("tacticalCampaignsPage.datagridColumns.column9"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.overridingContractTerm?.name) {
          return null;
        }
        return params.row.overridingContractTerm?.name;
      },
      renderCell: (params) => {
        if (!params.row.overridingContractTerm?.name) {
          return "--";
        }
        return (
          <ConditionalTooltip title={params.row.overridingContractTerm?.name} />
        );
      },
    },
  ];
};
