import React from 'react';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useTranslation } from 'react-i18next';
const Filters = ({
  disabled,
  filters,
  route,
  title = "Quick Filters",
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  let activeFilter = searchParams.get("quickFilter");
  const filter = (key) => {
    navigate({
      pathname: route,
      search: `?${createSearchParams({
        page: 1,
        quickFilter: activeFilter === key ? "" : key
      })}`,
      replace: true
    });
  };

  return (
    <Box display="flex" alignItems="baseline" mt={2} data-testid="filtersBox">
      <StyledTitleTypography>{title}</StyledTitleTypography>

      <Grid container spacing={1}>
        {filters.map((item, key) => (
          <Grid item key={`${key}-${item.name}`}>
            <FilterButton
              className={activeFilter === item.key ? "active" : ""}
              onClick={() => filter(item.key)}
              color="primary"
              disabled={disabled && activeFilter !== key}
              key={key}
              size="small"
              variant="outlined"
              data-testid={item.key}
            >
              {t(item.name)}
            </FilterButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  flex: "0 0 auto",
  marginRight: theme.spacing(1),
}));
const FilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.text.hint,
  borderWidth: 1,
  boxShadow: "0px 1px 5px 0px rgba(33, 33, 33, 0.19)",
  color: theme.palette.text.hint,
  fontSize: 12,
  padding: theme.spacing(0.5, 2),
  "&:hover, &.active": {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  }
}))

export default Filters;
