import React, { useContext, useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AppError from "@/AppError";
import Dialog from "@/components/Dialog";
import { BMW_ACCOUNT, SUCCESS } from "@/constants/constants";
import { AppContext } from "@/context/AppContext";
import apiContractsData from "@/services/apiContracts";
import { handleApiError } from "@/utils/handleApiError";
import apiUIData from "@/services/apiUI";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const PrintConfirmationDialog = ({
  id,
  open,
  setOpen,
  setIsLoading,
  setShowAlert,
  setUpdateTimeline,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { setAlertStatus, setAlertMessage, primaryLanguageOptions } =
    useContext(AppContext);
  const [printConfirmationTemplates, setPrintConfirmationTemplates] = useState(
    [],
  );
  const [template, setTemplate] = useState("");
  const [templateLanguage, setTemplateLanguage] = useState("");
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [errors, setErrors] = useState({
    templateId: false,
    templateLanguage: false,
  });
  const [apiError, setApiError] = useState({});

  const clearForm = () => {
    setTemplate("");
    setTemplateLanguage("");
    setErrors({
      templateId: false,
      templateLanguage: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  useEffect(() => {
    if (templateLanguage) {
      setErrors((prevState) => ({
        ...prevState,
        templateLanguage: false,
      }));
    }
    const getPrintConfirmationTemplatesByLanguage = async () => {
      setLoadingTemplates(true);

      if (templateLanguage?.id) {
        await apiUIData
          .getPrintConfirmationTemplatesByLanguage(templateLanguage?.id)
          .then((response) => {
            setPrintConfirmationTemplates(response.data.result);
            setLoadingTemplates(false);
            setApiError({});
          })
          .catch((err) => {
            setLoadingTemplates(false);
            setApiError(handleApiError(err));
          });
      } else {
        setPrintConfirmationTemplates([]);
        setLoadingTemplates(false);
      }
    };

    getPrintConfirmationTemplatesByLanguage();
  }, [templateLanguage]);

  // Clear errors when updated
  useEffect(() => {
    if (template) {
      setErrors((prevState) => ({
        ...prevState,
        template: false,
      }));
    }
  }, [template]);

  // NKAM-17921: Not requierd another useEffect with same dependancy.
  //Moved the code above useEffect which has same dependancy and commenting below code.

  const validateForm = () => {
    if (!template && !templateLanguage) {
      setErrors({
        template: true,
        templateLanguage: true,
      });
    } else if (!template) {
      setErrors((prevState) => ({
        ...prevState,
        template: true,
      }));
    } else if (!templateLanguage) {
      setErrors((prevState) => ({
        ...prevState,
        templateLanguage: true,
      }));
    } else {
      setErrors({
        template: false,
        templateLanguage: false,
      });
    }
  };

  const generatePrintConfirmationDocument = async () => {
    validateForm();

    if (template && templateLanguage) {
      setIsLoading(true);

      await apiContractsData
        .sendDocumentToBeGenerated(id, BMW_ACCOUNT, template)
        .then(() => {
          setIsLoading(false);
          setApiError({});

          setShowAlert(true);
          setAlertStatus(SUCCESS);
          setAlertMessage(
            t("businessPartnerDetailPage.printConfirmationDialog.alertMessage"),
          );
          setUpdateTimeline(true);
          queryClient.refetchQueries({
            queryKey: ["timelineList"],
          });
          handleClose();
        })
        .catch((err) => {
          setIsLoading(false);
          setApiError(handleApiError(err));
        });
    }
  };

  return (
    <>
      <Dialog
        confirmationButtonText="Confirm"
        handleClose={handleClose}
        id="printConfirmation"
        open={open}
        handler={generatePrintConfirmationDocument}
        prompt={t(
          "businessPartnerDetailPage.printConfirmationDialog.dialog.prompt",
        )}
        title={t(
          "businessPartnerDetailPage.printConfirmationDialog.dialog.title",
        )}
      >
        <Autocomplete
          disabled={loadingTemplates}
          options={primaryLanguageOptions}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          onChange={(event, newValue) => {
            setTemplateLanguage(newValue);
            setTemplate("");
            setErrors({
              templateId: false,
              templateLanguage: false,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors.templateLanguage}
              helperText={
                errors.templateLanguage &&
                t(
                  "businessPartnerDetailPage.printConfirmationDialog.selectLanguage.helperText",
                )
              }
              label={t(
                "businessPartnerDetailPage.printConfirmationDialog.selectLanguage.label",
              )}
              InputLabelProps={{ shrink: true }}
              required
            />
          )}
        />

        {printConfirmationTemplates.length === 0 ? (
          <TextField
            disabled
            label={t(
              "businessPartnerDetailPage.printConfirmationDialog.selectTemplate.label",
            )}
            error={errors.template}
            loading={loadingTemplates}
            helperText={
              errors.template &&
              t(
                "businessPartnerDetailPage.printConfirmationDialog.selectTemplate.helperText",
              )
            }
            select
            inputProps={{ "data-testid": "select-template" }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <>{loadingTemplates && <CircularProgress size={20} />}</>
              ),
            }}
            required
          />
        ) : (
          <Autocomplete
            disabled={printConfirmationTemplates.length === 0}
            loading={loadingTemplates}
            options={printConfirmationTemplates}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={(event, newValue) => {
              setTemplate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.template}
                helperText={
                  errors.template &&
                  t(
                    "businessPartnerDetailPage.printConfirmationDialog.selectTemplate.helperText",
                  )
                }
                label={t(
                  "businessPartnerDetailPage.printConfirmationDialog.selectTemplate.label",
                )}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  "data-testid": "select-template",
                  endAdornment: (
                    <>
                      {loadingTemplates && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                required
              />
            )}
          />
        )}
      </Dialog>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default PrintConfirmationDialog;
