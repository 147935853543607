import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Loader from "@/components/Loader";
import Paging from "@/components/Paging";
import { orderRoute } from "@/constants/routes";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledContainer,
  StyledContents,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledPagination,
  StyledTbody,
  StyledTd,
  StyledTr,
} from "@/components/Styles/PagesComponentsCommon";
import { useOrdersByContRact } from "@/services/queries";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const ContractOrders = ({ contractId }) => {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [orders, setOrders] = useState([]);
  const [receivedResponse, setReceivedResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});

  const getOrdersByContract = useOrdersByContRact(contractId, page);

  useEffect(() => {
    setIsLoading(getOrdersByContract.isFetching);
    if (getOrdersByContract.isError) {
      setApiError(handleApiError(getOrdersByContract.error));
    }
    if (getOrdersByContract.isSuccess) {
      setOrders(getOrdersByContract?.data?.data?.result);
      setTotalPages(getOrdersByContract?.data?.data?.totalPages);
      setReceivedResponse(true);
    }
  }, [getOrdersByContract.status]);

  useEffect(() => {
    return () => {
      // controller.abort();
    };
  }, []);

  const headings = [
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.orderNumber"), isAction: false },
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.orderStatus"), isAction: false },
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.orderVerificationStatus"), isAction: false },
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.action"), isAction: true },
  ];

  return (
    <>
      {receivedResponse && (
        <StyledContainer id="orders">
          <StyledHeader>
            <StyledContents>
              <StyledHeading component="h2" variant="h4">
                {t("businessPartnerDetailPage.ordersPanel.sectionTitle")}
              </StyledHeading>
            </StyledContents>
          </StyledHeader>

          <TableHeaders headings={headings}>
            {orders.length > 0 ? (
              <StyledTbody>
                {isLoading && <Loader />}

                {orders?.map(
                  (
                    { id, orderNumber, orderStatus, verificationStatus },
                    index,
                  ) => (
                    <StyledTr key={index}>
                      <StyledTd>
                        <ConditionalTooltip title={orderNumber} />
                      </StyledTd>

                      <StyledTd>
                        <ConditionalTooltip title={orderStatus} />
                      </StyledTd>

                      <StyledTd>
                        <ConditionalTooltip title={verificationStatus?.name} />
                      </StyledTd>

                      <StyledTd>
                        <ConditionalTooltip
                          title={t(
                            "businessPartnerDetailPage.ordersPanel.viewOrderTooltip",
                            { orderNumber: orderNumber },
                          )}
                        >
                          <IconButton
                            color="primary"
                            component={Link}
                            size="small"
                            to={orderRoute + id}
                          >
                            <LaunchIcon fontSize="inherit" />
                          </IconButton>
                        </ConditionalTooltip>
                      </StyledTd>
                    </StyledTr>
                  ),
                )}
              </StyledTbody>
            ) : (
              <StyledNoResults>
                {t("businessPartnerDetailPage.ordersPanel.noResults")}
              </StyledNoResults>
            )}
          </TableHeaders>

          <StyledPagination>
            {totalPages > 1 && <Paging count={totalPages} page={page} />}
          </StyledPagination>
        </StyledContainer>
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default ContractOrders;
