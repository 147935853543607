import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Paging from "@/components/Paging";
import { PANEL_PAGE_SIZE } from "@/constants/constants";
import {
  StyledContainer,
  StyledContents,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledPagination,
  StyledTbody,
  StyledTd,
  StyledTr,
} from "@/components/Styles/PagesComponentsCommon";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const EligibleDomains = ({ eligibleDomains }) => {
  const controller = new AbortController();
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [apiError, setApiError] = useState({});
  const [arrStart, setArrStart] = useState(0);
  const [arrEnd, setArrEnd] = useState(eligibleDomains?.length);

  useEffect(() => {
    if (page > 1) {
      setArrStart(Math.floor(page * PANEL_PAGE_SIZE) - PANEL_PAGE_SIZE);
    } else {
      setArrStart(0);
    }
    setArrEnd(page * PANEL_PAGE_SIZE);
  }, [page]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);
  // Un used styled components - Removed

  const headings = [
    {
      heading: t("businessPartnerDetailPage.eligibleDomainsPanel.tableHeading"),
      isAction: false,
    },
  ];

  return (
    <>
      <StyledContainer id="eligibledomains">
        <Box>
          <StyledHeader>
            <StyledContents>
              <StyledHeading component="h2" variant="h4" gutterBottom>
                {t("businessPartnerDetailPage.eligibleDomainsPanel.heading")}
              </StyledHeading>
              <Typography component="p" variant="p">
                {t("businessPartnerDetailPage.eligibleDomainsPanel.subheading")}
              </Typography>
            </StyledContents>
          </StyledHeader>
        </Box>

        <TableHeaders headings={headings}>
          {eligibleDomains.length > 0 ? (
            <StyledTbody>
              {eligibleDomains.slice(arrStart, arrEnd).map((item, key) => {
                return (
                  <StyledTr key={key}>
                    <StyledTd>
                      <ConditionalTooltip
                        title={item?.name}
                        value={item?.name}
                      />
                    </StyledTd>
                  </StyledTr>
                );
              })}
            </StyledTbody>
          ) : (
            <StyledNoResults>
              {t("businessPartnerDetailPage.eligibleDomainsPanel.noneFound")}
            </StyledNoResults>
          )}
        </TableHeaders>

        <StyledPagination>
          {eligibleDomains?.length > PANEL_PAGE_SIZE && (
            <Paging
              count={Math.ceil(eligibleDomains?.length / PANEL_PAGE_SIZE)}
              page={page}
            />
          )}
        </StyledPagination>
      </StyledContainer>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default EligibleDomains;
