import React, { useContext, useEffect, useState } from "react";
import AppError from "@/AppError";
import Filters from "@/components/Filters";
import PageTitle from "@/components/PageTitle";
import Paging from "@/components/Paging";
import Search from "@/components/Search";
import filters from "@/constants/orderFilters";
import { OrdersContext } from "@/context/OrdersContext";
import { handleApiError } from "@/utils/handleApiError";
import DataGridTable from "@/components/DataGridTable/DataGridTable";
import useOrdersTable from "@/components/Orders/Table";
import { orderRoute, ordersRoute } from "@/constants/routes";
import { StyledPageHeader } from "@/components/Styles/PagesComponentsCommon";
import { useDebounce } from "@/hooks/useDebounce";
import { useFetchOrders } from "@/services/queries";
import { useSearchParams } from "@/hooks/useSearchParams";
import { createSearchParams, useNavigate } from "react-router-dom";
import useFirstRender from "@/hooks/useFirstRender";
import { useTranslation } from "react-i18next";

export const OrdersPage = () => {

  const controller = new AbortController();

  const {
    orders,
    setOrders,
    searchText,
    setSearchText,
    pageSize,
    totalPages,
    setTotalPages,
  } = useContext(OrdersContext);
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const isFirstRender = useFirstRender();
  const [apiError, setApiError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [searched, setSearched] = useState(false);
  const ordersColumns = useOrdersTable();

  let page = searchParams.get("page") || 1;
  let quickFilter = searchParams.get("quickFilter") || "";

  const debouncedSearch = useDebounce([searchText]);
  const searchOrdersQuery = useFetchOrders(searchText, quickFilter, page, debouncedSearch, pageSize);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(searchOrdersQuery.isFetching);
    if (searchOrdersQuery.isError) {
      setApiError(handleApiError(searchOrdersQuery.error))
    }
    if (searchOrdersQuery.isSuccess) {
      setSearched(true);
      let searchOrders = searchOrdersQuery?.data?.data?.result || [];
      searchOrders.forEach((row) => {
        row["orderRoute"] = orderRoute;
      });
      setOrders(searchOrders);
      setTotalPages(searchOrdersQuery?.data?.data?.totalPages);
      setIsTyping(false);
    }
  }, [searchOrdersQuery.dataUpdatedAt, searchOrdersQuery.errorUpdatedAt])

  useEffect(() => {
    if (!isFirstRender) {
      navigate({
        pathname: ordersRoute,
        search: `?${createSearchParams({
          page: 1,
          quickFilter: quickFilter
        })}`,
        replace: true
      })
    }
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  return (
    <>
      <StyledPageHeader>
        <PageTitle title={t("ordersPage.pageTitle")} />

        <Search
          id="orders-search"
          label={t("ordersPage.searchLabel")}
          placeholder={t("ordersPage.searchPlaceholder")}
          searchText={searchText}
          setSearchText={setSearchText}
          setIsTyping={setIsTyping}
        />
      </StyledPageHeader>

      <Filters
        disabled={isLoading || isTyping || (!quickFilter && totalPages === 0)}
        filters={filters}
        route={ordersRoute}
      />

      <DataGridTable
        data={orders}
        columns={ordersColumns}
        isLoading={isLoading}
        searched={searched}
      />
      {totalPages > 1 && (
        <Paging count={totalPages} page={page} route={ordersRoute} />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
