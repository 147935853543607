import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { EsoDunsResultsListRow } from "./EsoDunsResultsListRow";
import { ResultsListRow } from "./ResultsListRow";
export const ResultsList = ({ header, data, createEsoRecord, isDunsData }) => (
  <TableContainer>
    <Table>
      <TableBody>
        {data && (
          <>
            {header}

            {data.map((item, index) =>
              isDunsData ? (
                <EsoDunsResultsListRow
                  key={`item-${index}`}
                  uniqueKey={`item-${index}`}
                  item={item}
                  createEsoRecord={createEsoRecord}
                />
              ) : (
                <ResultsListRow
                  key={`item-${index}`}
                  uniqueKey={`item-${index}`}
                  item={item}
                  createEsoRecord={createEsoRecord}
                />
              ),
            )}
          </>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
