import React from "react";
import { Controller } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import TooltipConditionalWrapper from "@/components/TooltipConditionalWrapper";
import TextField from "@mui/material/TextField";

const SelectController = ({
    control,
    disabled,
    label,
    name,
    options,
    required,
    showPlaceholderOption = true,
    onChange,
    title,
    testid
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange: fieldOnChange, ref, value }, fieldState: { error } }) => (
                <TooltipConditionalWrapper
                    condition={title}
                    wrapper={children => <Tooltip title={title}>{children}</Tooltip>}>
                    <TextField
                        data-testid={testid}
                        select
                        SelectProps={{
                            native: true,
                        }}
                        disabled={disabled}
                        label={label}
                        value={value}

                        onChange={(e) => {
                            try {
                                if (isNaN(Number(e.target.value))) {
                                    fieldOnChange(e.target.value);
                                }
                                else {
                                    fieldOnChange(Number(e.target.value))
                                }
                            }

                            catch (error) {
                                fieldOnChange(e.target.value)
                            }
                            if (onChange) {
                                onChange(e);
                            }
                        }
                        }
                        error={!!error}
                        required={required}
                        helperText={error ? error.message : null}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputRef={ref}
                    >
                        {showPlaceholderOption && <option value="">Please select...</option>}

                        {options?.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </TooltipConditionalWrapper>
            )}
            rules={{
                required: required && "This field is required",
            }}
        />
    );
};

export default SelectController;
