import { apiQuery } from "./apiHandler";

const searchConnectionsByBP = (
  id,
  pageSize,
  pageNumber = 1,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "connections/search?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&bpId=" +
      id;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const apiConnectionsData = {
  searchConnectionsByBP,
};

export default apiConnectionsData;
