import React, { Children } from 'react';
import { styled } from '@mui/material/styles';

const MultiElementFloatingActionBar = ({ children }) => {
  const childrenMap = Children.map(children, (child) => {
    return child;
  })

  return (
    <MainDiv
      data-testid="multi-element-floating-action-bar"
    >
      {childrenMap}
    </MainDiv>
  )
}
const MainDiv = styled('div')(() => ({
  position: 'sticky',
  width: '100%',
  bottom: '3rem',
  zIndex: '666',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'centre',
  gap: '3em',
  marginTop: '3em',
}));

export default MultiElementFloatingActionBar;