import React from "react";
import { Controller } from "react-hook-form";

import { styled } from "@mui/material/styles"
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import TooltipConditionalWrapper from "@/components/TooltipConditionalWrapper";
import { VIEW } from "@/constants/constants";

const SwitchController = ({ control, label, mode, name, disabled, title, testid }) => {

  return (
    <Controller
      name={name}
      label={label}
      control={control}
      render={({ field: { onChange, ref, value } }) => (
        <TooltipConditionalWrapper
          condition={title}
          wrapper={children => <Tooltip title={title}>{children}</Tooltip>}>
          <StyledFormControl
            disabled={mode === VIEW || disabled}
            data-testid={testid}
          >
            <StyledFormControlLabel
              label={label}
              onChange={onChange}
              checked={value}
              control={<Switch checked={value} />}
              inputRef={ref}
            />
          </StyledFormControl>
        </TooltipConditionalWrapper>
      )}
    />
  );
};

const StyledFormControl = styled(FormControl)(() => ({
  width: "100%",
  marginTop: "8px"
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: theme?.shape?.borderRadius,
  margin: theme.spacing(0),
  fontSize: theme?.typography?.body1?.fontSize,
  lineHeight: "1.1876em",
  letterSpacing: "normal",
  padding: "7px",
  width: "100%",

  "&.Mui-disabled": {
    borderColor: "transparent",
  },

  "& .MuiFormControlLabel-label": {
    fontSize: theme?.typography?.body1?.fontSize,
    lineHeight: "1.1876em",
    transform: "scale(0.75)",
    transformOrigin: "left",
    marginLeft: 6,

    "&.Mui-disabled": {
      fontWeight: theme?.typography?.fontWeightBold,
      color: theme?.palette?.common?.black,
    },
  },
}));

export default SwitchController;
