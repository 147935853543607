import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";

const Section = ({ id, children }) => {

  return (
    <SectionBox id={id}>
      {children}
    </SectionBox>
  );
};
const SectionBox = styled(Box)(({ theme }) => ({
  border: "1px solid " + theme.palette.common.gray,
  padding: theme.spacing(3),
  "& + $section": {
    borderTopWidth: 0,
  },
}));
export default Section;
