import React, { useEffect } from "react";
import { DropzoneArea } from "mui-file-dropzone";
import { MAX_FILE_SIZE } from "@/constants/forms";
import { useTranslation } from "react-i18next";

// The material-ui-dropzone package is overriding entire app styles and it's not supporting MUI V5.
// mui-filedropzone is an alternative to material-ui-dropzone and supports MUI V5. 
// Still not able to convert the component to Styled component but it's not overriding the styles atleast.

const Dropzone = ({
  acceptedFiles,
  files,
  filesLimit,
  setBase64File,
  setFiles,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (files.length > 0) {
      getBase64(files[0]);
    } else {
      setBase64File(null);
    }
  }, [files]);

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setBase64File(reader.result);
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  return (
    <DropzoneArea
      acceptedFiles={acceptedFiles}
      alertSnackbarProps={{ autoHideDuration: 6000 }}
      filesLimit={filesLimit}
      maxFileSize={MAX_FILE_SIZE}
      onChange={(files) => setFiles(files)}
      showPreviewsInDropzone={false}
      showPreviews
      useChipsForPreview
      dropzoneClass="dropzone"
      previewGridClasses={{ container: 'dropzonePreview', item: "previewChip", image: 'deleteIcon' }}
      dropzoneText={t("dropzone.dropFilesText")}
    />
  );
};
export default Dropzone;
