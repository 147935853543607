import React, { useState } from "react";

import Timeline from "@/components/Timeline";
import TimelineDialog from "@/components/ContractDetails/TimelineDialog";

import apiContractsData from "@/services/apiContracts";

const ContractTimeline = ({
  id,
  setIsSaving,
  setShowAlert,
  setUpdateTimeline,
  updateTimeline,
  disableCreateNote,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TimelineDialog
        id={id}
        open={open}
        setOpen={setOpen}
        setIsSaving={setIsSaving}
        setShowAlert={setShowAlert}
        setUpdateTimeline={setUpdateTimeline}
      />

      <Timeline
        id={id}
        func={apiContractsData.searchContractTimeline}
        createFunctionality={true}
        setOpen={setOpen}
        searchFunctionality={true}
        setUpdateTimeline={setUpdateTimeline}
        updateTimeline={updateTimeline}
        disableCreateNote={disableCreateNote}
      />
    </>
  );
};

export default ContractTimeline;
