import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Trans } from "react-i18next";

function InstructionalTooltip(props) {
  const { values } = props;
  return (
    <Box display="flex" alignItems="center">
      <ArrowLeft />
      <Container px={2} py={3}>
        <Typography component="p" variant="body1" color="secondary">
          <Trans key="instructionalTooltip" />
        </Typography>
        {values && (
          <Box component="ul" p={0} mt={2} mb={0}>
            {values.map((item, i, key) => {
              return (
                <ListItem
                  component="li"
                  mb={i + 1 === key.length ? 0 : 2}
                  key={key}
                >
                  <Box component="span" mr={1} display="flex">
                    {item.bool === true ? (
                      <StyledCheckIcon fontSize="medium" />
                    ) : (
                      <StyledClearIcon fontSize="medium" />
                    )}{" "}
                  </Box>
                  {item.content}
                </ListItem>
              );
            })}
          </Box>
        )}
      </Container>
    </Box>
  );
}

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.common.black,
  minWidth: "300px",
  borderRadius: 3,
}));
const ArrowLeft = styled(Box)(({ theme }) => ({
  width: 0,
  height: 0,
  borderTop: "10px solid transparent",
  borderBottom: "10px solid transparent",
  borderRight: `10px solid ${theme.palette.common.black}`,
}));
const ListItem = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.common.white,
  listStyle: "none",
  display: "flex",
  alignItems: "center",
}));
const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontWeight: 'bold'
}));
const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  color: theme.palette.error.main
}))

export default InstructionalTooltip;
