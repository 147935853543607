import { apiQuery } from "./apiHandler";

const searchRetailersByBP = (bpId, pageSize, pageNumber, controller = null) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "retailers/search?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&bpId=" +
      bpId;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getRetailerById = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "retailers/" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const apiRetailersData = {
  searchRetailersByBP,
  getRetailerById,
};

export default apiRetailersData;
