import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Loader from "@/components/Loader";
import { PAGE_SIZE } from "@/constants/constants";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const DataGridTable = ({ data, columns, isLoading, searched, paginationMode = "server", page = 0 }) => {
  const { t } = useTranslation();
  if (isLoading) {
    return <Loader />;
  }
  if (data?.length === 0 && searched) {
    return <div>{t("datagrid.noResults")}</div>;
  }
  if (data?.length > 0) {
    return (
      <div
        style={{
          width: "100%",
          marginTop: "52px",
        }}
      >
        <StyledDataGrid
          rows={data}
          columns={columns}
          paginationMode={paginationMode}
          slots={{ toolbar: GridToolbar, loadingOverlay: <Loader /> }}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          hideFooter={true}
          rowHeight={52}
          pagination={null}
          autoHeight
          autoPageSize
          paginationModel={{
            page: page,
            pageSize: PAGE_SIZE
          }}
          disableDensitySelector
          localeText={{
            toolbarFilters: t("datagrid.toolbarFilters"),
            toolbarExport: t("datagrid.toolbarExport"),
          }}
          isLoading={isLoading}
          data-testid="dataGridTable"
        />
      </div>
    );
  } else {
    return ''
  }
};

const StyledDataGrid = styled(DataGrid)(() => ({
  fontFamily: "BMWGroupTNPro, Arial, sans-serif",
  fontSize: "14px",
  border: "none",
  ".MuiButtonBase-root": {
    color: "#2f80ed",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 4,
    padding: "4px 5px",
    boxShadow: "none",
    textTransform: "uppercase",
  },
  ".MuiButtonBase-root:hover": {
    textDecoration: "none",
    backgroundColor: "#1976d20a",
    boxShadow: "inset 0 -2px 0 0 #2f80ed",
  },
  "& .MuiTextField-root": {
    marginTop: "0px",
    marginBottom: "0px"
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 700,
  },
  "& .custom-header.MuiDataGrid-withBorderColor": {
    borderBottom: "1px solid #000",
  },
  "& .MuiDataGrid-withBorderColor": {
    borderBottom: "none",
  },
}));
export default DataGridTable;
