import React, { useState } from "react";

import Button from "@mui/material/Button";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useTranslation } from "react-i18next";

import PrintConfirmationDialog from "@/components/BusinessPartnerDetails/PrintConfirmationDialog";

const PrintConfirmationButton = ({
  bpid,
  setIsLoading,
  setShowAlert,
  setUpdateTimeline,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        endIcon={<PrintOutlinedIcon />}
      >
        {t("businessPartnerDetailPage.printConfirmationButton.buttonText")}
      </Button>

      <PrintConfirmationDialog
        id={bpid}
        open={open}
        setOpen={setOpen}
        setIsLoading={setIsLoading}
        setShowAlert={setShowAlert}
        setUpdateTimeline={setUpdateTimeline}
      />
    </>
  );
};

export default PrintConfirmationButton;
