import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@/components/Dialog";
import { AppContext } from "@/context/AppContext";
import { SUCCESS } from "@/constants/constants";
import { handleApiError } from "@/utils/handleApiError";
import AppError from "@/AppError";
import { useSentForApprovalMutation } from "@/services/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const ApprovalDialog = ({
  entity,
  id,
  open,
  options,
  setOpen,
  setIsLoading,
  setShowAlert,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setAlertStatus, setAlertMessage } = useContext(AppContext);

  const [approvalSetupId, setApprovalSetupId] = useState("");
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState({});

  const errorMessage = t("approvalDialog.requiredError");

  const approvalSendMutation = useSentForApprovalMutation();
  useEffect(() => {
    // Clears the error when approval option is updated.
    if (approvalSetupId) {
      setError(false);
    }
  }, [approvalSetupId]);

  useEffect(() => {
    setIsLoading(approvalSendMutation.isPending);
    if(approvalSendMutation.isPending) return;
    if (approvalSendMutation.isError) {
      setApiError(handleApiError(approvalSendMutation.error));
      handleClose();
    }
    if (approvalSendMutation.isSuccess) {
      handleClose();
      setShowAlert(true);
      setAlertStatus(SUCCESS);
      setAlertMessage(t("approvalDialog.successAlert"));
      queryClient.refetchQueries({ queryKey: ["timelineList", { id: id }] });
      queryClient.refetchQueries({ queryKey: ["getApprovalButtonVisibility", { id: id }] });
      queryClient.refetchQueries({ queryKey: ["getApprovalTypes", { id: id }] });
      queryClient.refetchQueries({ queryKey: ["contractDetails", {id: id}] });
      queryClient.refetchQueries({ queryKey: ["permissionToEditContract", {id: id}] });
    }
  }, [approvalSendMutation.status, approvalSendMutation.isPending])

  useEffect(() => {
    // If there's only one approval setup option available,
    // automatically select the option.
    const setApprovalSetupOption = (options) => {
      if (options.length === 1) {
        setApprovalSetupId(options[0].id);
      } else {
        setApprovalSetupId("");
      }
    };

    setApprovalSetupOption(options);
  }, [open]);

  const clearForm = () => {
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const sendForApproval = async () => {
    if (approvalSetupId) {
      approvalSendMutation.mutate([id, entity, approvalSetupId]);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Dialog
        handleClose={handleClose}
        handler={sendForApproval}
        id="approvalConfirmation"
        open={open}
        prompt={t("approvalDialog.promptMsg")}
        title={t("approvalDialog.title")}
      >
        <TextField
          data-testid="approval-dialog"
          error={!!error}
          helperText={error ? errorMessage : null}
          label={t("approvalDialog.label")}
          required
          select
          value={approvalSetupId}
          onChange={(event) => setApprovalSetupId(event.target.value)}
          SelectProps={{
            native: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {options?.length > 1 && (
            <option value="">{t("approvalDialog.optionPlaceholder")}</option>
          )}

          {options?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </TextField>
      </Dialog>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default ApprovalDialog;
