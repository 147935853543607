import React from "react";

import Moment from "react-moment";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { StyledInfoPanel, StyledInfoPanelGrid, StyledInfoPanelStatusBox, StyledInfoPanelSubTitle, StyledInfoPanelTitle, StyledInfoPanelValue, StyledInfoPanelValueTitle } from "./panelStyles";
import { useTranslation } from "react-i18next";
import { useCalenderStrings } from "@/helperFunctions/generic";

export const InfoPanelContract = ({
  contractName,
  modifiedOn,
  contractStatus,
  contractId,
  owner,
}) => {
  const { t } = useTranslation();
  const calendarStrings = useCalenderStrings();

  return (
    <StyledInfoPanel py={4} px={2}>
      <Grid container spacing={2}>
        <StyledInfoPanelGrid item lg>
          <Box sx={{
            width: "218px",
            padding: "0 26px 0 0 !important",
          }}>
            <StyledInfoPanelTitle component="h1" variant="h1">
              {contractName}
            </StyledInfoPanelTitle>
            <StyledInfoPanelSubTitle
              component="h2"
              variant="h2"
            >
              {t("contractDetailsPage.infoPanel.lastUpdated")}:{" "}
              <Moment calendar={calendarStrings}>{modifiedOn}</Moment>
            </StyledInfoPanelSubTitle>
          </Box>
        </StyledInfoPanelGrid>
        <StyledInfoPanelGrid item md>
          <StyledInfoPanelValueTitle
            component="h3"
            variant="h3"
          >
            {t("contractDetailsPage.infoPanel.status")}
          </StyledInfoPanelValueTitle>
          <StyledInfoPanelValue component="p">
            <StyledInfoPanelStatusBox status={contractStatus?.name}>
              {contractStatus?.name}
            </StyledInfoPanelStatusBox>
          </StyledInfoPanelValue>
        </StyledInfoPanelGrid>
        <StyledInfoPanelGrid item md>
          <StyledInfoPanelValueTitle
            component="h3"
            variant="h3"
          >
            {t("contractDetailsPage.infoPanel.contractId")}
          </StyledInfoPanelValueTitle>
          <StyledInfoPanelValue component="p">
            {contractId}
          </StyledInfoPanelValue>
        </StyledInfoPanelGrid>
        <Grid item md sx={{
            padding: "0 0 0 26px !important",
        }}>
          <StyledInfoPanelValueTitle
            component="h3"
            variant="h3"
          >
            {t("contractDetailsPage.infoPanel.owner")}
          </StyledInfoPanelValueTitle>
          <StyledInfoPanelValue component="p">
            {owner?.name}
          </StyledInfoPanelValue>
        </Grid>
      </Grid>
    </StyledInfoPanel>
  );
};
