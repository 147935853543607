import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "@/components/Form/Alert";
import AppError from "@/AppError";
import Approval from "@/components/Approval";
import Backdrop from "@/components/Backdrop";
import MainDetails from "@/components/OrderDetails/MainDetails";
import OrderOptions from "@/components/OrderDetails/OrderOptions";
import PageActions from "@/components/PageActions";
import PagePoints from "@/components/PagePoints";
import TacticalCampaigns from "@/components/OrderDetails/TacticalCampaigns";
import Verification from "@/components/OrderDetails/Verification";
import VerificationTimeline from "@/components/OrderDetails/VerificationTimeline";
import { InfoPanelOrder } from "@/components/InfoPanels/InfoPanelOrder";
import { AppContext } from "@/context/AppContext";
import { orderDetailsMapper } from "@/mappers/orderDetailsMapper";
import {
  BMW_ORDER,
  RE_UPLOAD,
  SUCCESS,
  UPLOAD,
  VIEW,
  VERIFICATION_STATUS_REUPLOAD,
  VERIFICATION_STATUS_DECLINED,
} from "@/constants/constants";
import { handleApiError } from "@/utils/handleApiError";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import { StyledPageContainer, StyledPageMain, StyledPageSide } from "@/components/Styles/PagesComponentsCommon";
import { useGetOrderDetailsQuery, useGetOrderVerificationInstructionsQuery } from "@/services/queries";
import { useUpdateOrderMutation } from "@/services/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const OrderDetailsPage = () => {
  const { id } = useParams();
  const controller = new AbortController();
  const queryClient = useQueryClient();

  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
    setBreadcrumbLevel3Text,
  } = useContext(AppContext);

  // eslint-disable-next-line no-unused-vars
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mode, setMode] = useState(VIEW);
  const [order, setOrder] = useState(null);
  const [orderRejected, setOrderRejected] = useState("");
  const [orderVerificationInstructions, setOrderVerificationInstructions] =
    useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [sentForApproval, setSentForApproval] = useState(false);
  const [updateTimeline, setUpdateTimeline] = useState(false);
  const [apiError, setApiError] = useState({});
  const canSave = true;
  const { t } = useTranslation();


  const points =
    mode === VIEW
      ? [
        {
          name: t("orderDetails.pagePoints.details"),
          url: "#details",
        },
        {
          name: t("orderDetails.pagePoints.orderOptions"),
          url: "#orderOptions",
        },
        {
          name: t("orderDetails.pagePoints.orderVerificationTimeline"),
          url: "#timeline",
        },
        {
          name: t("orderDetails.pagePoints.tacticalCampaigns"),
          url: "#tacticalCampaigns",
        },
      ]
      : [
        {
          name: t("orderDetails.pagePoints.details"),
          url: "#details",
        },
      ];

  const orderDetailsQuery = useGetOrderDetailsQuery(id, mode, sentForApproval);
  const orderVerificationInstructionsQry = useGetOrderVerificationInstructionsQuery();
  const updateOrderMutation = useUpdateOrderMutation();

  useEffect(() => {
    setIsLoading(orderDetailsQuery.isFetching);
    if (orderDetailsQuery.isError) {
      setApiError(handleApiError(orderDetailsQuery.error));
    }
    if (orderDetailsQuery.isSuccess) {
      if (
        (orderDetailsQuery.data?.data?.result?.verificationStatus?.value ===
          VERIFICATION_STATUS_REUPLOAD ||
          orderDetailsQuery.data?.data?.result?.verificationStatus?.value ===
          VERIFICATION_STATUS_DECLINED) &&
        orderDetailsQuery.data?.data?.result?.verificationComment
      ) {
        setOrderRejected(
          orderDetailsQuery.data?.data?.result?.verificationComment,
        );
      }
      setOrder(orderDetailsQuery.data?.data?.result);
      // Set Breadcrumb Level 3 text
      setBreadcrumbLevel3Text(
        orderDetailsQuery.data?.data?.result?.orderNumber,
      );
    }
  }, [orderDetailsQuery.status]);

  useEffect(() => {
    setIsLoading(orderVerificationInstructionsQry.isFetching);
    if (orderVerificationInstructionsQry.isError) {
      setApiError(handleApiError(orderVerificationInstructionsQry.error));
    }
    if (orderVerificationInstructionsQry.isSuccess) {
      setOrderVerificationInstructions(
        orderVerificationInstructionsQry.data?.data?.result,
      );
    }
  }, [orderVerificationInstructionsQry.status]);

  useEffect(() => {
    setIsSaving(updateOrderMutation.isPending);
    if (updateOrderMutation.isError) {
      setApiError(handleApiError(updateOrderMutation.error));
    }
    if (updateOrderMutation.isSuccess) {
      queryClient.invalidateQueries({ queryKey: ["getOrderDetails", { id, mode, sentForApproval }] })
      createAlert(SUCCESS, t("orderDetails.updateOrderSuccessAlert"));
      setMode(VIEW);
    }
  }, [updateOrderMutation.status])

  useEffect(() => {
    permissionToEditOrder();

    setValue("deliveryAgreement", order?.deliveryAgreement);
    setValue("spotVolume", order?.spotVolume);
  }, [order, id]);

  const permissionToEditOrder = async () => {
    order?.verificationStatus?.value === UPLOAD ||
      order?.verificationStatus?.value === RE_UPLOAD
      ? setIsEditable(true)
      : setIsEditable(false);
  };

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      deliveryAgreement: false,
      spotVolume: false,
    },
  });

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  const onSubmit = async (data) => {
    updateOrderMutation.mutate([id, orderDetailsMapper(data)]);
  };

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  return (
    <>
      {order && mode === VIEW && <InfoPanelOrder {...order} />}
      {order && (
        <>
          <StyledPageContainer
            component={mode === VIEW ? "div" : "form"}
            mt={2}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <StyledPageMain>
              <MainDetails
                control={control}
                order={order}
                mode={mode}
                watch={watch}
              />

              {mode === VIEW && (
                <>
                  <OrderOptions bpid={id} />

                  <VerificationTimeline
                    id={id}
                    orderRejectedComment={orderRejected}
                    setUpdateTimeline={setUpdateTimeline}
                    updateTimeline={updateTimeline}
                  />

                  <TacticalCampaigns id={id} />
                </>
              )}
              <MultiElementFloatingActionBar>
                <PageActions
                  entityType="order"
                  isEditable={true}
                  mode={mode}
                  reset={reset}
                  setMode={setMode}
                  canSave={canSave}
                />
                {mode === VIEW && (
                  <Verification
                    id={id}
                    isLoading={isLoading}
                    instructions={orderVerificationInstructions}
                    setIsLoading={setIsLoading}
                    setShowAlert={setShowAlert}
                    setUpdateTimeline={setUpdateTimeline}
                  />
                )}
                {id && mode === VIEW && (
                  <Approval
                    id={id}
                    entity={BMW_ORDER}
                    sentForApproval={sentForApproval}
                    setSentForApproval={setSentForApproval}
                    setIsLoading={setIsLoading}
                    setShowAlert={setShowAlert}
                    updateTimeline={updateTimeline}
                  />
                )}
              </MultiElementFloatingActionBar>
            </StyledPageMain>

            <StyledPageSide>
              <PagePoints points={points} backButton />
            </StyledPageSide>
          </StyledPageContainer>
        </>
      )}
      {(isLoading || isSaving) && <Backdrop text={isSaving && t("backdrop.saving")} />}

      {showAlert && alertStatus && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
