import React from "react";
import { useTranslation } from "react-i18next";
import {
  BAD_REQUEST_400,
  FORBIDDEN_403,
  INTERNAL_SERVER_ERROR_500,
  NOT_FOUND_404,
  UNAUTHORISED_401,
} from "@/constants/errors";
const StatusCodeTextLookUp = ({ status }) => {
  const { t } = useTranslation();
  let errorTitle;
  if (status === BAD_REQUEST_400) {
    errorTitle = t("webapi.errorMessages.badRequest");
  } else if (status === UNAUTHORISED_401) {
    errorTitle = t("webapi.errorMessages.unAuthorised");
  } else if (status === FORBIDDEN_403) {
    errorTitle = t("webapi.errorMessages.forbidden");
  } else if (status === NOT_FOUND_404) {
    errorTitle = t("webapi.errorMessages.notFound");
  } else if (status === INTERNAL_SERVER_ERROR_500) {
    errorTitle = t("webapi.errorMessages.internalServerError");
  }
  return (<>{errorTitle}</>)
};
export default StatusCodeTextLookUp 