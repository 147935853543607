import { React } from "react";

import { styled } from '@mui/material/styles';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import BackgroundImage from "@/assets/images/dashboard-background.jpg";

import utils from "@/utils/common";
import { userNameKey } from "@/constants/constants";

export const DashboardPage = () => {
  const { t } = useTranslation();
  return (
    <Banner>
      <Box p={5} zIndex={1} position="relative">
        <Title component="h1" variant="h1" data-testid="welcome">
          {t("dashboard.welcome")}, {utils.secureGetFromLocalStorage(userNameKey)}
        </Title>
      </Box>
    </Banner>
  );
};

/**
 * @author Rob Cornelius
 * New Higher Order Components for styling to avoid using the makeStyles() hook which depends on react v17.x.x
 */
export const Banner = styled(Box)(() => ({
  backgroundImage: `url(${BackgroundImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  minHeight: "400px",
  borderRadius: 5,
  position: "relative",
  display: "flex",
  "&:before": {
    content: "''",
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,0.4)",
    position: "absolute",
    top: 0,
    left: 0,
  },
}))
export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}))
