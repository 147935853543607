import React from "react";

const StringToHTML = ({text}) => {
  // Split the text into an array using the newline character
  const lines = text.split("\n");

  return (
    <div>
      <p>{lines[0]}</p> {/* This displays the intro line */}
      <ul>
        {lines.slice(1, -1).map((line, index) => (
          <li key={index}>{line.replace(/.\t/g, "")}</li> // Remove any tab characters
        ))}
      </ul>
      <p>{lines[lines.length - 1]}</p> {/* This displays the final line */}
    </div>
  );
};

export default StringToHTML;
