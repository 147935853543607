import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import AppError from "@/AppError";
import ActionsBar from "@/components/ActionsBar";
import Backdrop from "@/components/Backdrop";
import PagePoints from "@/components/PagePoints";
import {
  DiscountMatrixLineItems,
  IndividualModelDiscountLines,
  UpfrontDiscountMatrix,
} from "@/components/DiscountMatrix";
import { handleApiError } from "@/utils/handleApiError";
import { StyledPageContainer, StyledPageMain, StyledPageSide } from "@/components/Styles/PagesComponentsCommon";
import { useDiscountMatrixData } from "@/services/queries";
import { useTranslation } from "react-i18next";

export const DiscountMatrixPage = () => {
  const { id } = useParams();
  const controller = new AbortController();
  const [upfrontDiscountMatrix, setUpfrontDiscountMatrix] = useState([]);
  const [individualDiscountLines, setIndividualDiscountLines] = useState([]);
  const [discountMatrixLineItems, setDiscountMatrixLineItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({});
  const { t } = useTranslation();

  const points = [
    {
      name: t("discountMatrixPage.points.upfrontDiscountMatrix"),
      url: "#upfrontDiscountMatrix",
    },
    {
      name: t("discountMatrixPage.points.lineItems"),
      url: "#discountMatrixLineItems",
    },
    {
      name: t("discountMatrixPage.points.individualModel"),
      url: "#individualModelDiscountLines",
    },
  ];

  const getDiscountMatrixData = useDiscountMatrixData(id);

  useEffect(() => {
    if (getDiscountMatrixData.some(query => query.isLoading)) {
      setIsLoading(true);
    }
    if (getDiscountMatrixData.some(query => query.isError)) {
      let error = getDiscountMatrixData.find(query => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (getDiscountMatrixData.every(query => query.isSuccess)) {
      setIsLoading(false);
      setUpfrontDiscountMatrix(getDiscountMatrixData[0]?.data?.data?.result);
      setDiscountMatrixLineItems({
        data: getDiscountMatrixData[1]?.data?.data?.result?.filter(
          ({ individualModelDiscount }) => individualModelDiscount === "No",
        ),
        ValueType: getDiscountMatrixData[0]?.data?.result?.valueType,
      });
      setIndividualDiscountLines({
        data: getDiscountMatrixData[1]?.data?.data?.result?.filter(
          ({ individualModelDiscount }) =>
            individualModelDiscount === "Yes",
        ),
        ValueType: getDiscountMatrixData[0]?.data?.data?.result?.valueType,
      });
    }
  }, [getDiscountMatrixData[0].status, getDiscountMatrixData[1].status])

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  return (
    <>
      {upfrontDiscountMatrix && (
        <>
          <ActionsBar backButton={false} />

          <StyledPageContainer mt={2}>
            <StyledPageMain>
              <Box mb={3}>
                <UpfrontDiscountMatrix {...upfrontDiscountMatrix} />
              </Box>

              {discountMatrixLineItems && (
                <DiscountMatrixLineItems items={discountMatrixLineItems} />
              )}

              {individualDiscountLines && (
                <Box mb={3}>
                  <IndividualModelDiscountLines
                    items={individualDiscountLines}
                  />
                </Box>
              )}
            </StyledPageMain>

            <StyledPageSide>
              <PagePoints points={points} backButton />
            </StyledPageSide>
          </StyledPageContainer>
        </>
      )}

      {isLoading && <Backdrop />}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
