const handleApiError = ({ message, responseURL, statusCode, title }) => {
  return {
    isError: true,
    message,
    responseURL,
    statusCode: statusCode?.toString(),
    title,
  };
};

export { handleApiError };
