/* eslint-disable-next-line */
export const guidRegex = /\w{8}\-\w{4}\-\w{4}\-\w{4}\-\w{12}/;

//MAIN
export const homeRoute = "/";
export const loginRoute = "/";
export const logoutRoute = "/signout";
export const searchRoute = "/search/";
export const settingsRoute = "/settings/";
export const helpRoute = "/help/";

//BUSINESS PARTNERS
export const businessPartnerRoute = "/businesspartner/";
export const businessPartnersRoute = "/businesspartners/";
export const createBusinessPartnerRoute = "/businesspartners/create";
export const dunsSearchRoute = "/businesspartners/duns";
export const esoSearchRoute = "/businesspartners/eso";
export const esodunsSearchRoute = "/businesspartners/esoduns";
export const errorRoute = "/error";

//CONTRACTS
export const createContractRoute = "/contract/create";
export const contractRoute = "/contract/";
export const contractsRoute = "/contracts/";

//CONTACTS
export const createContactRoute = "/contact/create";
export const contactRoute = "/contact/";
export const contactsRoute = "/contacts/";

//ORDERS
export const orderRoute = "/order/";
export const ordersRoute = "/orders/";

//DISCOUNT MATRIX
export const discountMatrixRoute = "/discount-matrix/";

//BUSINESS PARTNER ROLE
export const businessPartnerRole = "/businesspartner-role/";
export const createBusinessPartnerRole = "/businesspartner-role/create";

//TACTICAL CAMPAIGNS
export const tacticalCampaignsRoute = "/tactical-campaigns/";
