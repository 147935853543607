import React from "react";

import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const ConditionalTooltip = ({
  children,
  className,
  component,
  variant,
  title,
  defaultValue = "--",
  value,
  testid,
  label
}) => {

  return title ? (
    <Tooltip title={title}>
      <EllipsisTypography
        className={className}
        component={component}
        variant={variant}
        data-testid={testid}
      >
        {children ? children : value !== null && value !== undefined ? value : title}
      </EllipsisTypography>
    </Tooltip>
  ) : (
    <>
      {defaultValue && (
        <Typography
          className={className}
          component={component}
          variant={variant}
          data-testid={testid}
          aria-label={label}
        >
          {defaultValue}
        </Typography>
      )}
    </>
  );
};

const EllipsisTypography = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "600px",
}))

export default ConditionalTooltip;
