import { apiQuery } from "./apiHandler";
import utils from "@/utils/common";

const getAllOrders = (pageSize, pageNumber = 1, controller = null) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "orders/?PageNumber=" + pageNumber + "&PageSize=" + pageSize;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOrderOptions = (id, pageSize, pageNumber = 1, controller = null) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "orderoptions/?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&id=" +
      id;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOrdersByBP = (bpid, pageSize, pageNumber = 1, controller = null) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "orders/forbp?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&bpId=" +
      bpid;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOrdersByContract = (
  contractId,
  pageSize,
  pageNumber = 1,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "orders/forcontract?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&contractId=" +
      contractId;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOrdersDetail = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "orders/" + id;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOrderTacticalCampaigns = (
  id,
  pageSize,
  pageNumber = 1,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "tacticalcampaigns?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&id=" +
      id;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOrderVerification = (
  id,
  pageSize,
  pageNumber = 1,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    const endpoint =
      "notes/timeline/orderverificationnotes/" +
      id +
      "?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOrderVerificationInstructions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "orders/verification/config";

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchOrders = (
  pageSize,
  pageNumber,
  searchText,
  quickFilter = "",
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "orders/search?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&search=" +
      utils.autoWildcardSearchTerm(searchText) +
      "&quickFilter=" +
      quickFilter;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const updateOrder = (id, data) =>
  new Promise((resolve, reject) => {
    const endpoint = "orders/" + id;

    apiQuery(endpoint, null, "PATCH", resolve, reject, data);
  });

const uploadVerificiation = (id, title, fileName, data) =>
  new Promise((resolve, reject) => {
    const endpoint =
      "orders/verification/" + id + "?title=" + title + "&fileName=" + fileName;

    apiQuery(endpoint, null, "PATCH", resolve, reject, data);
  });

const apiOrdersData = {
  getAllOrders,
  getOrderOptions,
  getOrdersByBP,
  getOrdersByContract,
  getOrdersDetail,
  getOrderTacticalCampaigns,
  getOrderVerification,
  getOrderVerificationInstructions,
  searchOrders,
  updateOrder,
  uploadVerificiation,
};

export default apiOrdersData;
