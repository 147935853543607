import React from "react";

import { styled } from '@mui/material/styles'
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => {

  return (
    <Overlay data-testid="loadingSpinner">
      <LoaderContainer>
        <LoaderActual>
          <Bottom
            variant="determinate"
            size={30}
            thickness={5}
            value={100}
          />
          <Top
            variant="indeterminate"
            disableShrink
            size={30}
            thickness={5}
          />
        </LoaderActual>
      </LoaderContainer>
    </Overlay>
  );
};

const Overlay = styled(Box)(({ theme }) => ({
  background: "rgba(255,255,255, 0.55)",
  height: "100%",
  position: "absolute",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: theme.zIndex.loader,
}));
const LoaderContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  minHeight: 150,
}));
const LoaderActual = styled(Box)(() => ({
  position: "relative",
  height: 30,
}));
const Bottom = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.light,
}));
const Top = styled(CircularProgress)(() => ({
  left: 0,
  position: "absolute",

}))

export default Loader;
