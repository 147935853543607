import React, { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

import BackButton from "@/components/BackButton";

import { AppContext } from "@/context/AppContext";
import { ListItemButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const PagePoints = ({ children, points, backButton = false }) => {
  const { t } = useTranslation();
  const { headerRef, mainRef } = useContext(AppContext);

  const location = useLocation();
  const navRef = useRef(null);

  useEffect(() => {
    if (mainRef?.current?.style) {
      mainRef.current.style.paddingBottom =
        navRef.current.offsetHeight + headerRef.current.offsetHeight + 20 + "px";
    }
  }, []);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  /**
   * @author Rob Cornelius
   * The main Box uses sx due the styled HOC not returning a ReactDOMCompoent which breaks the ref
   */
  return (
    <StyledBox ref={navRef}>
      <Title component="h2" variant="h5">
        {t("pagePoints.mainTitle")}
      </Title>

      <StyledDivider />

      <StyledList>
        {points.map(({ name, url }, index) => (
          <StyledListItem key={index} selected={url === location.hash}>
            <StyledHashLink to={url} scroll={scrollWithOffset} replace>
              <ListItemText>{name}</ListItemText>
            </StyledHashLink>
          </StyledListItem>
        ))}
      </StyledList>

      <Divider />

      {children}

      {backButton && (
        <Box mt={1}>
          <BackButton version="outlined" />
        </Box>
      )}
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  border: "1px solid #dce0e4",
  padding: theme.spacing(3, 2),
  position: "sticky",
  top: "90px",
}));
const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: "23px",
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: "#dce0e4",
  margin: theme.spacing(2, 0),
  "&:first-of-type": {
    marginBottom: theme.spacing(1),
  },
}));
const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1),
  marginBottom: theme.spacing(2),
}));
const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.25, 0),
  "&.Mui-selected": {
    backgroundColor: "transparent",
    boxShadow: "inset 0 -1px 0 0 " + theme.palette.primary.main,
    color: "#0b406c",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  "&.Mui-selected > a": {
    color: theme.palette.primary.main,
  },
  "& > a": {
    color: theme.palette.text.primary,
  },
}));
const StyledHashLink = styled(HashLink)(() => ({
  textDecoration: "none",
}));

export default PagePoints;
