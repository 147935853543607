import React from "react";

import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import utils from "@/utils/common";

const ContractStatus = ({ status }) => {
  if (status === "Active") {
    return <ActiveStatus>{utils.trimAndEllipsis(status, 20)}</ActiveStatus>
  } else if (status === "Contract Expired") {
    return <ExpiredStatus>{utils.trimAndEllipsis(status, 20)}</ExpiredStatus>
  }
  return <OtherStatus>{utils.trimAndEllipsis(status, 20)}</OtherStatus>
};

const ActiveStatus = styled(Box)(({ theme }) => ({
  borderRadius: 3,
  borderStyle: "solid",
  borderWidth: 1,
  display: "inline-block",
  fontSize: theme.typography.caption.fontSize,
  minWidth: 80,
  padding: theme.spacing(0.275, 1.5),
  textAlign: "center",
  color: theme.palette.success.main,
  borderColor: theme.palette.success.main,
  backgroundColor: theme.palette.success.light,
  textWrap: "wrap"
}));
const ExpiredStatus = styled(Box)(({ theme }) => ({
  borderRadius: 3,
  borderStyle: "solid",
  borderWidth: 1,
  display: "inline-block",
  fontSize: theme.typography.caption.fontSize,
  minWidth: 80,
  padding: theme.spacing(0.275, 1.5),
  textAlign: "center",
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  textWrap: "wrap"
}));
const OtherStatus = styled(Box)(({ theme }) => ({
  borderRadius: 3,
  borderStyle: "solid",
  borderWidth: 1,
  display: "inline-block",
  fontSize: theme.typography.caption.fontSize,
  minWidth: 80,
  padding: theme.spacing(0.275, 1.5),
  textAlign: "center",
  color: theme.palette.warning.main,
  borderColor: theme.palette.warning.main,
  backgroundColor: theme.palette.warning.light,
  textWrap: "wrap"
}));
export default ContractStatus;