import React, { useState } from "react";
import Timeline from "@/components/Timeline";
import TimelineDialog from "@/components/BusinessPartnerDetails/TimelineDialog";
import apiBPData from "@/services/apiBusinessPartners";

const BPTimeline = ({
  id,
  setIsSaving,
  setShowAlert,
  setUpdateTimeline,
  updateTimeline,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Timeline
        id={id}
        func={apiBPData.searchBusinessPartnerTimeline}
        createFunctionality={true}
        setOpen={setOpen}
        searchFunctionality={true}
        setUpdateTimeline={setUpdateTimeline}
        updateTimeline={updateTimeline}
      />

      <TimelineDialog
        id={id}
        open={open}
        setOpen={setOpen}
        setIsSaving={setIsSaving}
        setShowAlert={setShowAlert}
        setUpdateTimeline={setUpdateTimeline}
      />
    </>
  );
};

export default BPTimeline;
