import React from "react";

import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

const SectionTitle = ({ title, category = false }) => {
  /**
   * @author Rob Cornelius
   * this styled HOC is moved inside the component to bring the category parameter into its scope.
   */
  const SectionTitleTypography = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: category ? 0 : theme.spacing(2),
  }))

  return (
    <SectionTitleTypography component="h2" variant="h4">
      {title}
    </SectionTitleTypography>
  );
};

export default SectionTitle;
