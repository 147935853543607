import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AppError from "@/AppError";
import Dialog from "@/components/Dialog";
import Dropzone from "@/components/Form/Dropzone";
import { SUCCESS } from "@/constants/constants";
import { AppContext } from "@/context/AppContext";
import { handleApiError } from "@/utils/handleApiError";
import { StyledError } from "@/components/Styles/PagesComponentsCommon";
import { styled } from "@mui/material";
import { useOrderVerificationMutation } from "@/services/mutations";
import { useTranslation } from "react-i18next";

const VerificationDialog = ({
  id,
  setIsLoading,
  open,
  instructions,
  setOpen,
  setShowAlert,
  setUpdateTimeline,
}) => {
  const { setAlertStatus, setAlertMessage } = useContext(AppContext);

  const [base64File, setBase64File] = useState(null);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [apiError, setApiError] = useState("");
  const { t } = useTranslation();
  const acceptedFiles = ["application/pdf"];
  const errorMessage = t("orderDetails.verificationDialog.errorMessage");
  const filesLimit = 1;

  const uploadVerificationMutation = useOrderVerificationMutation();


  useEffect(() => {
    // Clears the error when file is added.
    if (files.length > 0) {
      setErrorFile(false);
    }
  }, [files]);

  useEffect(() => {
    setIsLoading(uploadVerificationMutation.isPending);
    if (uploadVerificationMutation.isError) {
      setApiError(handleApiError(uploadVerificationMutation.error));
    }
    if (uploadVerificationMutation.isSuccess) {
      handleClose();
      setShowAlert(true);
      setAlertStatus(SUCCESS);
      setAlertMessage(t("orderDetails.verificationDialog.successAlert"));
      setUpdateTimeline(true);
    }
  }, [uploadVerificationMutation.status])

  useEffect(() => {
    // Clears the error when title is updated.
    if (title) {
      setErrorTitle(false);
    }
  }, [title]);

  const clearForm = () => {
    setErrorFile(false);
    setErrorTitle(false);
    setTitle("");
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const validateForm = () => {
    if (files.length === 0 && title === "") {
      setErrorFile(true);
      setErrorTitle(true);
    } else if (title === "") {
      setErrorFile(false);
      setErrorTitle(true);
    } else if (files.length === 0) {
      setErrorFile(true);
      setErrorTitle(false);
    } else {
      setErrorFile(false);
      setErrorTitle(false);
    }
  };

  const uploadVerification = async () => {
    validateForm();

    if (files.length > 0 && title) {
      uploadVerificationMutation.mutate([
        id,
        title,
        files.length > 0 ? files[0]?.name : null,
        base64File ? base64File?.substr(base64File.indexOf(",") + 1) : null,
      ]);
    }
  };

  return (
    <>
      <Dialog
        confirmationButtonText={t("orderDetails.verificationDialog.dialog.confirmationButtonText")}
        handleClose={handleClose}
        handler={uploadVerification}
        id="uploadVerification"
        open={open}
        prompt={t("orderDetails.verificationDialog.dialog.prompt")}
        title={t("orderDetails.verificationDialog.dialog.title")}
      >
        <StyledTextField
          label={t("orderDetails.verificationDialog.verificationDialogFields.title")}
          error={!!errorTitle}
          required={true}
          value={title}
          helperText={errorTitle ? errorMessage : null}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />

        <StyledInscructionsBox>
          <ReactMarkdown>{instructions}</ReactMarkdown>
        </StyledInscructionsBox>

        <Dropzone
          acceptedFiles={acceptedFiles}
          files={files}
          filesLimit={filesLimit}
          setBase64File={setBase64File}
          setFiles={setFiles}
        />

        {errorFile ? (
          <StyledError component="p" variant="caption">
            {errorMessage}
          </StyledError>
        ) : null}
      </Dialog>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

const StyledTextField = styled(TextField)(() => ({
  margin: 0,
}));

const StyledInscructionsBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  margin: theme.spacing(3, 0),
}));

export default VerificationDialog;
