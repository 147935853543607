import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import Alert from "@/components/Form/Alert";
import CustomControl from "@/components/Form/CustomControl";
import Section from "@/components/Section";
import SectionTitle from "@/components/SectionTitle";

import {
  StyledPageContainer,
  StyledPageMain,
  StyledPageSide,
} from "@/components/Styles/PagesComponentsCommon";
import { CLEAR, CREATE, GenericStartEndDateErrorMessage, LEVEL3_VALUE, SUCCESS, StartDateSetBeforePresentDay, VIEW } from "@/constants/constants";
import {
  BPROLES_DEFAULT_VALUES, DATE, LOOKUP, TEXT, BusinePartnerRoleEntity
} from "@/constants/forms";
import PagePoints from "@/components/PagePoints";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import PageActions from "@/components/PageActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import AppError from "@/AppError";
import { handleApiError } from "@/utils/handleApiError";
import Backdrop from "@/components/Backdrop";
import { createBPRoleMapper } from "@/mappers/createBPRoleMapper";
import { AppContext } from "@/context/AppContext";
import { businessPartnerRole } from "@/constants/routes";
import { useBPRoleInitialQueries, useFetchNewL2BPs, useGetBPDetails, useGetBPRoleDetailsQuery } from "@/services/queries";
import { useSaveBPRoleMutation } from "@/services/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDebounce } from "@/hooks/useDebounce";
import { useSearchParams } from "@/hooks/useSearchParams";
export const BPRolesDetails = () => {
  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
  } = useContext(AppContext);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const bpName = location?.state?.account?.bpName || "";
  const bpid = searchParams.get("bpId");

  const [showAlert, setShowAlert] = useState(false);
  const [mode, setMode] = useState(VIEW);
  const [role, setRole] = useState({});
  const [typeOptions, setTypeOptions] = useState([]);
  const [apiError, setApiError] = useState({});
  const [errorMessages, setErrorMessages] = useState({
    presentDay: t("genericDateMessages.startDateBeforePresentDay"),
    generic: t("genericDateMessage.startEndDatgeErrorMessage")
  });
  const [parentInputValue, setParentInputValue] = useState(null);
  const [childInputValue, setChildInputValue] = useState(null);
  const [inputReason, setInputReason] = useState(CLEAR);
  const [canSave, setCanSave] = useState(true);
  const debouncedParentValue = useDebounce([parentInputValue], 500);
  const debouncedChildValue = useDebounce([childInputValue], 500);
  const getInitialData = useBPRoleInitialQueries();
  const getBPRoleDetails = useGetBPRoleDetailsQuery(id, mode);
  const saveBPRoleMutation = useSaveBPRoleMutation();

  const parentBpsListQuery = useFetchNewL2BPs(
    LEVEL3_VALUE,
    debouncedParentValue,
    mode,
    inputReason
  );

  const childsBPListQuery = useFetchNewL2BPs(
    LEVEL3_VALUE,
    debouncedChildValue,
    mode,
    inputReason
  );

  const BPDetails = useGetBPDetails(!bpName ? bpid : false);

  const getDefaultBP = () => {
    let defaultBp = {
      id: bpid,
      name: bpName,
      logicalName: "account"
    }
    if (!bpName && BPDetails?.data?.data?.result) {
      defaultBp.name = BPDetails?.data?.data?.result?.name
    }
    return defaultBp;
  };

  useEffect(() => {
    setValue(BusinePartnerRoleEntity.childId, getDefaultBP());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BPDetails.dataUpdatedAt])

  const { control, setError, watch, handleSubmit, clearErrors, setValue } = useForm({
    values: {
      ...BPROLES_DEFAULT_VALUES,
      [BusinePartnerRoleEntity.childId]: getDefaultBP(),
    }
  });

  const isLoading = getBPRoleDetails.isFetching || saveBPRoleMutation.isPending;

  const [startDate, endDate] = watch(['bmw_startdate', 'bmw_enddate']);

  const points = [
    {
      name: "Main Details",
      url: "#details",
    },
  ];

  useEffect(() => {
    if (getInitialData.some(query => query.isError)) {
      let error = getInitialData.find(query => query.isError)?.error
      setApiError(handleApiError(error))
    }
    if (getInitialData.every(query => query.isSuccess)) {
      setErrorMessages({
        ...errorMessages,
        presentDay: getInitialData[0].data?.data?.result?.stringValue || t(StartDateSetBeforePresentDay),
        generic: getInitialData[1].data?.data?.result?.stringValue || t(GenericStartEndDateErrorMessage)
      });
      setTypeOptions(getInitialData[2].data?.data?.result || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInitialData[0].status, getInitialData[1].status, getInitialData[2].status]);

  useEffect(() => {
    if (getBPRoleDetails.isFetching) return;
    if (getBPRoleDetails.isError) {
      setApiError(handleApiError(getBPRoleDetails.error));
    }
    if (getBPRoleDetails.isSuccess) {
      setRole(getBPRoleDetails.data?.data?.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBPRoleDetails.dataUpdatedAt, getBPRoleDetails.errorUpdatedAt]);

  useEffect(() => {
    if (saveBPRoleMutation.isPending) return;
    if (saveBPRoleMutation.isSuccess) {
      createAlert(
        SUCCESS,
        t("bpRolesDetailsPage.success"),
      );
      setMode(VIEW);
      queryClient.refetchQueries({
        queryKey: ["bpRoles", { id: bpid }],
      });
      saveBPRoleMutation.data?.data.result &&
        navigate(businessPartnerRole + saveBPRoleMutation.data?.data?.result, {
          state: {
            account: {
              id: bpid,
            },
          },
          replace: true,
        });
    }
    if (saveBPRoleMutation.isError) {
      setApiError(handleApiError(saveBPRoleMutation.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveBPRoleMutation.status]);

  useEffect(() => {
    validateStartEndDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (id && id !== "create") {
      setMode(VIEW);
    } else {
      setMode(CREATE);
    }
    // getDateErrorConfigsAndRoleTypeOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validateStartEndDates = () => {
    if (startDate && new Date(startDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      setError(
        'bmw_startdate',
        {
          type: "focus",
          message: errorMessages.presentDay,
        },
        { shouldFocus: true },
      );
      setCanSave(false);
    }
    else if (startDate && endDate && new Date(startDate).setHours(0, 0, 0, 0) > new Date(endDate).setHours(0, 0, 0, 0)) {
      setError(
        'bmw_startdate',
        {
          type: "focus",
          message: errorMessages.generic,
        },
        { shouldFocus: true },
      );
      setCanSave(false);
    } else {
      clearErrors(["bmw_startdate"]);
      setCanSave(true);
    }
  }

  const saveBPRole = async (data) => {
    saveBPRoleMutation.mutate(createBPRoleMapper(data));
  };

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  return (
    <Box>
      <StyledPageContainer
        component={mode === VIEW ? "div" : "form"}
        mt={2}
        noValidate
        onSubmit={handleSubmit(saveBPRole)}
      >
        <StyledPageMain>
          <Section id="details">
            <SectionTitle category={true} title={t("bpRolesDetailsPage.detailsTitle")} />
          </Section>
          <Section>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  name={BusinePartnerRoleEntity.name}
                  label={t("bpRolesDetailsPage.detailsRoleLabel")}
                  type={TEXT}
                  mode={mode}
                  view={role?.name}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  name={BusinePartnerRoleEntity.parentId}
                  label={t("bpRolesDetailsPage.detailsParentLabel")}
                  type={LOOKUP}
                  mode={mode}
                  view={role?.parentAccount?.name}
                  options={parentBpsListQuery?.data?.data?.result || []}
                  inputValue={parentInputValue}
                  setInputValue={setParentInputValue}
                  setInputReason={setInputReason}
                  loading={parentBpsListQuery?.isFetching}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  name={BusinePartnerRoleEntity.childId}
                  label={t("bpRolesDetailsPage.detailsChildLabel")}
                  type={LOOKUP}
                  mode={mode}
                  view={role?.childAccount?.name}
                  options={childsBPListQuery?.data?.data?.result || []}
                  inputValue={childInputValue}
                  setInputValue={setChildInputValue}
                  setInputReason={setInputReason}
                  loading={childsBPListQuery?.isFetching}
                  required
                />
              </Grid>
              {mode !== CREATE && (
                <Grid item xs={12} sm={4}>
                  <CustomControl
                    control={control}
                    name={BusinePartnerRoleEntity.status}
                    label={t("bpRolesDetailsPage.detailsNKAMLabel")}
                    type={TEXT}
                    mode={mode}
                    view={role?.statusCode?.name}
                    disabled={true}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  name={BusinePartnerRoleEntity.type}
                  label={t("bpRolesDetailsPage.detailsTypeLabel")}
                  type={LOOKUP}
                  mode={mode}
                  view={role?.type?.name}
                  options={typeOptions}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  name={BusinePartnerRoleEntity.startDate}
                  label={t("bpRolesDetailsPage.detailsStartLabel")}
                  type={DATE}
                  mode={mode}
                  view={role?.startDate}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  name={BusinePartnerRoleEntity.endDate}
                  label={t("bpRolesDetailsPage.detailsEndLabel")}
                  type={DATE}
                  mode={mode}
                  view={role?.endDate}
                />
              </Grid>
            </Grid>
          </Section>
          <MultiElementFloatingActionBar>
            <PageActions
              entityType={t("bpRolesDetailsPage.entityType")}
              mode={mode}
              setMode={setMode}
              isEditable={false}
              canSave={canSave}
            />
          </MultiElementFloatingActionBar>
        </StyledPageMain>
        <StyledPageSide>
          <PagePoints points={points} backButton />
        </StyledPageSide>
      </StyledPageContainer>
      {isLoading && <Backdrop />}
      {apiError.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
      {showAlert && alertMessage && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}
    </Box>
  );
};
