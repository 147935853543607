import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import AppError from "@/AppError";
import Paging from "@/components/Paging";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";
import { handleApiError } from "@/utils/handleApiError";
import { StyledDMorTCSeparator, StyledDMorTCTable, StyledDMorTCTableContainer, StyledDMorTCTableHeading } from "@/components/Styles/PagesComponentsCommon";
import { useGetOrderTacticalCampaignsQuery } from "@/services/queries";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";


const TacticalCampaigns = ({ id }) => {
  const columns = 3;
  const controller = new AbortController();
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});

  const getOrderTacticalCampaigns = useGetOrderTacticalCampaignsQuery(id, page);
  const { t } = useTranslation();


  useEffect(() => {
    if (getOrderTacticalCampaigns.isError) {
      setApiError(handleApiError(getOrderTacticalCampaigns.error));
    }
    if (getOrderTacticalCampaigns.isSuccess) {
      setOptions(getOrderTacticalCampaigns?.data?.data?.result);
      setTotalPages(getOrderTacticalCampaigns?.data?.data?.totalPages);
    }
  }, [getOrderTacticalCampaigns.status]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <StyledDMorTCTableContainer
        id="tacticalCampaigns"
        component={Paper}
      >
        <StyledDMorTCTable aria-label={t("orderDetails.tacticalCampaigns.sectionTitle")}>
          <Typography component="caption">{t("orderDetails.tacticalCampaigns.sectionTitle")}</Typography>

          <TableHead>
            <TableRow>
              <TableCell width={360}>
                <StyledDMorTCTableHeading>{t("orderDetails.tacticalCampaigns.tacticalCampaignsFields.name")}</StyledDMorTCTableHeading>
              </TableCell>

              <TableCell width={150}>
                <StyledDMorTCTableHeading>{t("orderDetails.tacticalCampaigns.tacticalCampaignsFields.campaignCode")}</StyledDMorTCTableHeading>
              </TableCell>

              <TableCell width={260}>
                <StyledDMorTCTableHeading>{t("orderDetails.tacticalCampaigns.tacticalCampaignsFields.endDate")}</StyledDMorTCTableHeading>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledDMorTCSeparator />

            {options.length > 0 ? (
              options?.map(({ name, campaignCode, endDate }, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{campaignCode}</TableCell>
                    <TableCell>
                      {convertISODateToDayMonthYear(endDate)}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={columns}>
                {t("orderDetails.tacticalCampaigns.noResults")}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell colSpan={columns}>
                {totalPages > 1 && (
                  <Paging count={totalPages} page={page} />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledDMorTCTable>
      </StyledDMorTCTableContainer>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default TacticalCampaigns;
