import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Paging from "@/components/Paging";
import { Link, createSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledContainer,
  StyledHeader,
  StyledContents,
  StyledHeading,
  StyledTr,
  StyledTbody,
  StyledTd,
  StyledNoResults,
  StyledPagination,
  StyledAddButton,
} from "@/components/Styles/PagesComponentsCommon";
import {
  businessPartnerRole,
  createBusinessPartnerRole,
} from "@/constants/routes";
import { IconButton } from "@mui/material";
import { LaunchOutlined } from "@mui/icons-material";
import { useGetBPRolesList } from "@/services/queries";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import Loader from "../Loader";
import TableHeaders from "../TableHeaders";

const BusinessPartnerRoles = ({ bpid, isBpDetailsFetched, bpName }) => {
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  let page = searchParams.get("page") || 1;
  const [bpRoles, setBPRoles] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});
  const headings = [
    { heading: t("businessPartnerDetailPage.businessPartnerRolesPanel.tableHeadings.parent"), isAction: false },
    { heading: t("businessPartnerDetailPage.businessPartnerRolesPanel.tableHeadings.child"), isAction: false },
    { heading: t("businessPartnerDetailPage.businessPartnerRolesPanel.tableHeadings.nkamStatusRole"), isAction: false },
    { heading: t("businessPartnerDetailPage.businessPartnerRolesPanel.tableHeadings.type"), isAction: false },
    { heading: t("businessPartnerDetailPage.businessPartnerRolesPanel.tableHeadings.startDate"), isAction: false },
    { heading: t("businessPartnerDetailPage.businessPartnerRolesPanel.tableHeadings.endDate"), isAction: false },
    { heading: t("businessPartnerDetailPage.businessPartnerRolesPanel.tableHeadings.action"), isAction: true },
  ];
  const bpRolesListQuery = useGetBPRolesList(bpid, isBpDetailsFetched);

  useEffect(() => {
    if (bpRolesListQuery.isFetching) return;
    if (bpRolesListQuery.isError) {
      setApiError(handleApiError(bpRolesListQuery.error));
    }
    if (bpRolesListQuery.isSuccess) {
      setBPRoles(bpRolesListQuery.data?.data?.result);
      setTotalPages(bpRolesListQuery.data?.data?.totalPages);
    }
  }, [bpRolesListQuery.dataUpdatedAt, bpRolesListQuery.errorUpdatedAt]);

  useEffect(() => {
    return () => {
      // queryClient.invalidateQueries();
    };
  }, []);
  return (
    <>
      <StyledContainer id="bpRoles">
        <Box>
          <StyledHeader>
            <StyledContents>
              <StyledHeading component="h2" variant="h4">
                {t(
                  "businessPartnerDetailPage.businessPartnerRolesPanel.sectionTitle",
                )}
              </StyledHeading>
            </StyledContents>

            <StyledAddButton
              component={Link}
              state={{
                account: {
                  id: bpid,
                  bpName: bpName,
                },
              }}
              to={`${createBusinessPartnerRole}?${createSearchParams({
                bpId: bpid,
              })}`}
              title={t(
                "businessPartnerDetailPage.businessPartnerRolesPanel.addButtonTitle",
              )}
            >
              <AddIcon />
            </StyledAddButton>
          </StyledHeader>
        </Box>
        <TableHeaders headings={headings}>
          {bpRolesListQuery.isFetching && (
            <StyledTbody>
              {bpRolesListQuery.isFetching && <Loader />}
            </StyledTbody>
          )}
          {bpRoles.length > 0 ? (
            <StyledTbody>
              {bpRoles?.map(
                (
                  {
                    parentAccount,
                    childAccount,
                    startDate,
                    endDate,
                    type,
                    id,
                    statusCode,
                  },
                  index,
                ) => (
                  <StyledTr key={index}>
                    <StyledTd>
                      <ConditionalTooltip
                        title={parentAccount?.name}
                        value={parentAccount?.name}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={childAccount?.name}
                        value={childAccount?.name}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={statusCode?.name}
                        value={statusCode?.name}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={type?.name}
                        value={type?.name}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={
                          startDate && convertISODateToDayMonthYear(startDate)
                        }
                        value={
                          startDate && convertISODateToDayMonthYear(startDate)
                        }
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={endDate && convertISODateToDayMonthYear(endDate)}
                        value={endDate && convertISODateToDayMonthYear(endDate)}
                      />
                    </StyledTd>
                    <StyledTd>
                      <ConditionalTooltip
                        title={`View Business Partner Role ${id}`}
                      >
                        <IconButton
                          color="primary"
                          component={Link}
                          size="small"
                          state={{
                            account: {
                              id: bpid,
                              bpName: bpName,
                            },
                          }}
                          to={`${businessPartnerRole}${id}?${createSearchParams(
                            { bpId: bpid },
                          )}`}
                        >
                          <LaunchOutlined fontSize="inherit" />
                        </IconButton>
                      </ConditionalTooltip>
                    </StyledTd>
                  </StyledTr>
                ),
              )}
            </StyledTbody>
          ) : (
            <StyledNoResults>
              {t(
                "businessPartnerDetailPage.businessPartnerRolesPanel.noResults",
              )}
            </StyledNoResults>
          )}
        </TableHeaders>

        <StyledPagination>
          {totalPages > 1 && <Paging count={totalPages} page={page} />}
        </StyledPagination>
      </StyledContainer>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default BusinessPartnerRoles;
