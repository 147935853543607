import { apiQuery } from "./apiHandler";

const getDiscountMatrix = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "discountmatrix/" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getDiscountLineItems = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "discountmatrixlineitems/" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const apiDiscountMatrixData = {
  getDiscountMatrix,
  getDiscountLineItems,
};

export default apiDiscountMatrixData;
