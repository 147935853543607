import React from "react";
import VatDialog from "@/components/BusinessPartnerDetails/VatDialog";
import { useTranslation } from "react-i18next";

const VatNotApplicableDialog = ({
    open,
    message,
    handleOk,
    handleClose
}) => {
    const { t } = useTranslation();
    return (
        <VatDialog
            open={open}
            confirmationButtonText={t("businessPartnerDetailPage.vatNotApplicableDialog.confirmationButtonText")}
            handleClose={handleClose}
            handler={handleOk}
            id="vatNotApplicableModal"
            title={t("businessPartnerDetailPage.vatNotApplicableDialog.title")}
        >
            {message}
        </VatDialog>
    );
};

export default VatNotApplicableDialog;
