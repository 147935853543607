/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useEffect,
  useState,
} from "react";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import AppError from "@/AppError";
import ActionsBar from "@/components/ActionsBar";
import BPResultsList from "@/components/BusinessPartner/BPResultsList";
import BPNoResults from "@/components/BusinessPartner/NoResults";
import Filters from "@/components/Filters";
import Loader from "@/components/Loader";
import { BusinessPartnersContext } from "@/context/BusinessPartnersContext";
import apiBPData from "@/services/apiBusinessPartners";
import {
  canMarketCreateBPKey,
  PAGE_SIZE,
} from "@/constants/constants";
import { businessPartnersRoute, createBusinessPartnerRoute, dunsSearchRoute, esoSearchRoute } from "@/constants/routes";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import Paging from "@/components/Paging";
import { StyledHeading, StyledPageDescription, StyledPageFab, StyledResultContainer, StyledSubHeading } from "@/components/Styles/PagesComponentsCommon";
import { handleApiError } from "@/utils/handleApiError";
import { AppContext } from "@/context/AppContext";
import { filterBusinessPartnerData } from "@/helperFunctions/businessPartners";
import { useDebounce } from "@/hooks/useDebounce";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "@/hooks/useSearchParams";
import useFirstRender from "@/hooks/useFirstRender";
import { filters } from "@/constants/businessPartnerFilters";
import { useTranslation } from "react-i18next";
export const BusinessPartnersPage = () => {
  const {
    businessPartners,
    setBusinessPartners,
    citySearch,
    setCitySearch,
    postcodeSearch,
    setPostcodeSearch,
    searchTerm,
    setSearchTerm,
    setNameSearch,
    setCompanyCodesSearch,
    isDunsMarket,
    isEsoSearchEnabled,
  } = useContext(BusinessPartnersContext);

  const { userData: { [canMarketCreateBPKey]: canMarketCreateABP } } = useContext(AppContext);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  let isFirstRender = useFirstRender();
  let page = searchParams.get("page") || 1
  let quickFilter = searchParams.get("quickFilter") || "";
  const [isTyping, setIsTyping] = useState(false);
  const [searched, setSearched] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [l2Count, setL2Count] = useState(0);
  const [l3Count, setL3Count] = useState(0);
  const [apiError, setApiError] = useState({});

  const defaultPage = 1;
  const pageSize = PAGE_SIZE;

  const debouncedSearch = useDebounce([searchTerm, citySearch, postcodeSearch]);

  const { isFetching, data, error, dataUpdatedAt, errorUpdatedAt } = useQuery({
    queryKey: ["businessPartners", [...debouncedSearch, page, quickFilter]],
    queryFn: async ({ signal }) => {
      let res = await apiBPData.searchBusinessPartnersData(
        pageSize,
        page || defaultPage,
        citySearch,
        postcodeSearch,
        searchTerm,
        quickFilter,
        signal
      );

      return res;
    },
    enabled: searched,
  }
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setBusinessPartners(data.data?.result);
      setTotalPages(data.data.totalPages);
      setTotalRecords(data.data.totalRecords);
      setSearched(true);
      setIsTyping(false);
      // save reference for DUNS search here based on searchTerm
      // Num saved to companyCodesSearch
      // String saved to nameSearch
      if (isNaN(searchTerm) === false) {
        setCompanyCodesSearch(searchTerm);
        setNameSearch("");
      } else {
        setNameSearch(searchTerm);
        setCompanyCodesSearch("");
      }
    }

    if (error) {
      setApiError(handleApiError(error));
    }

  }, [dataUpdatedAt, errorUpdatedAt]);

  useEffect(() => {
    let didNotHasSearchValue = debouncedSearch.every(value => value === '');
    if (didNotHasSearchValue) {
      setSearched(false);
      setIsTyping(false)
      return;
    } else if (!isFirstRender) {
      setSearched(true);
      navigate({
        pathname: businessPartnersRoute,
        search: `?${createSearchParams({
          page: 1,
          quickFilter: quickFilter
        })}`
      })
    }
  }, [debouncedSearch])

  useEffect(() => {
    if (businessPartners) {
      let count = filterBusinessPartnerData(businessPartners);
      setL2Count(count.l2Count);
      setL3Count(count.l3Count);
    }
  }, [businessPartners]);

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      queryClient.cancelQueries({ queryKey: ["businessPartners"] });
    };
  }, []);

  return (
    <>
      <ActionsBar backButton={true} />

      <Box mt={4}>
        <StyledHeading component="h4" variant="h4">
          {isEsoSearchEnabled || isDunsMarket ? "1. " : ""}
          {t("businessPartnersPage.mainHeading")}
        </StyledHeading>
        <StyledSubHeading component="h5" variant="h5">
          {isEsoSearchEnabled || isDunsMarket
            ? `${t("businessPartnersPage.step")} 1. `
            : ""}
          {t("businessPartnersPage.subHeading")}
        </StyledSubHeading>
        <StyledPageDescription component="p" variant="body2">
          {t("businessPartnersPage.pageDescription")}
        </StyledPageDescription>

        <Box component="form" id="searchForm">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                label={t("businessPartnersPage.searchLabel")}
                placeholder={t("businessPartnersPage.searchPlaceholder")}
                defaultValue={searchTerm}
                onChange={(event) => {
                  setIsTyping(true);
                  setSearchTerm(event.target.value);
                  queryClient.cancelQueries({ queryKey: ["businessPartners"] });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="search"
                data-testid="businessPartnerName"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("businessPartnersPage.cityLabel")}
                placeholder={t("businessPartnersPage.cityPlaceholder")}
                defaultValue={citySearch}
                onChange={(event) => {
                  setIsTyping(true);
                  setCitySearch(event.target.value);
                  queryClient.cancelQueries({ queryKey: ["businessPartners"] });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="search"
                data-testid="city"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("businessPartnersPage.postcodeLabel")}
                placeholder={t("businessPartnersPage.postcodePlaceholder")}
                defaultValue={postcodeSearch}
                onChange={(event) => {
                  setIsTyping(true);
                  setPostcodeSearch(event.target.value);
                  queryClient.cancelQueries({ queryKey: ["businessPartners"] });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="search"
                data-testid="postcode"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        mt={3}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        {(searched || isFetching || businessPartners?.length > 0) && (
          <StyledHeading component="h2" variant="h5">
            {t("businessPartnersPage.resultsHeading")}
          </StyledHeading>
        )}

        {businessPartners?.length > 0 && searched && (
          <Box>
            <Typography
              variant="body2"
              style={{ lineHeight: "1.5", textAlign: "right" }}
              m="auto"
            >
              {t("businessPartnersPage.numberOfResults.part1")}{" "}
              <span data-testid="totalPages">{totalPages}</span>
              {t("businessPartnersPage.numberOfResults.part2")}{" "}
              <span data-testid="totalRecords">{totalRecords}</span>{" "}
              {t("businessPartnersPage.numberOfResults.part3")}
              <br />
              {t("businessPartnersPage.numberOfResults.part4")}{" "}
              <span data-testid="l2count">{l2Count}</span>{" "}
              {t("businessPartnersPage.numberOfResults.part5")}{" "}
              <span data-testid="l3count">{l3Count}</span>{" "}
              {t("businessPartnersPage.numberOfResults.part6")}
            </Typography>
          </Box>
        )}
      </Box>

      {searched && (
        <Box my={4}>
          <Filters
            disabled={isFetching || isTyping}
            filters={filters}
            route={businessPartnersRoute}
          />
        </Box>
      )}

      <StyledResultContainer>
        {isFetching && <Loader />}

        {businessPartners?.length <= 0 ? (
          <>
            {searched && (
              <>
                <BPNoResults message={t("searchBPNoResults.nkamMessage")} />
              </>
            )}
          </>
        ) : (
          <>
            {searched && businessPartners?.length > 0 && (
              <>
                <BPResultsList
                  data={businessPartners}
                  l2Count={l2Count}
                  l3Count={l3Count}
                  page={page}
                />
              </>
            )}
          </>
        )}
      </StyledResultContainer>

      {totalPages > 1 && searched && (
        <Paging
          count={totalPages}
          disabled={isFetching || isTyping}
          page={page}
          route={businessPartnersRoute}
        />
      )}
      {/* show fabs irerespective of results in the table*/}
      {searched && !isFetching && (
        <MultiElementFloatingActionBar>
          {isEsoSearchEnabled && (
            <StyledPageFab
              aria-label={t("searchCTAButtons.searchESO")}
              component={Link}
              to={esoSearchRoute}
            >
              <AddIcon /> {t("searchCTAButtons.searchESO")}
            </StyledPageFab>
          )}
          {!isEsoSearchEnabled && isDunsMarket && (
            <StyledPageFab
              aria-label={t("searchCTAButtons.searchDUNS")}
              component={Link}
              to={dunsSearchRoute}
            >
              <AddIcon /> {t("searchCTAButtons.searchDUNS")}
            </StyledPageFab>
          )}
          {canMarketCreateABP && !isEsoSearchEnabled && (
            <StyledPageFab
              aria-label={t("searchCTAButtons.createBP")}
              component={Link}
              disabled={!canMarketCreateABP}
              to={createBusinessPartnerRoute}
            >
              <AddIcon /> {t("searchCTAButtons.createBP")}
            </StyledPageFab>
          )}
        </MultiElementFloatingActionBar>
      )}
      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
