const statusBoxStyles = {
  statusBox: {
    width: "110px",
    height: "20px",
    borderRadius: "4px",
    border: "1px solid",
    fontSize: "12px",
    textAlign: "center",
    paddingTop: "1px",
  },
  statusBlue: {
    background: "rgba(102, 112, 202, 0.2)",
    borderColor: "#0F50F9",
    color: "#6670CA",
    width: "fit-content; width:-webkit-fit-content; width:-moz-fit-content;",
  },
  statusPurple: {
    background: "rgba(146, 102, 202, 0.2)",
    borderColor: "#7339ED",
    color: "#7339ED",
  },
  statusGreen: {
    background: "rgba(39, 174, 96, 0.09)",
    borderColor: "#27AE60",
    color: "#27AE60",
  },
  statusRed: {
    background: "rgba(176, 32, 49, 0.09)",
    borderColor: "#B02031",
    color: "#B02031",
  },
  statusOrange: {
    background: "rgba(174, 71, 39, 0.09)",
    borderColor: "#F07524",
    color: "#F07524",
  },
};
export default statusBoxStyles;
