import React from "react";
import CheckboxController from "@/components/Form/Checkbox";
import DateController from "@/components/Form/Date";
import LookupController from "@/components/Form/Lookup";
import NumberController from "@/components/Form/Number";
import SelectController from "@/components/Form/Select";
import SwitchController from "@/components/Form/Switch";
import TextController from "@/components/Form/Text";
import ViewMode from "@/components/Form/ViewMode";

import { VIEW } from "@/constants/constants";
import {
  CHECKBOX,
  DATE,
  LOOKUP,
  NUMBER,
  SELECT,
  SWITCH,
} from "@/constants/forms";

const CustomControl = (props) => {
  const isViewMode = props.mode === VIEW;

  const fieldMapper = (type) => {
    switch (type) {
      case CHECKBOX:
        return <CheckboxController {...props} />;
      case DATE:
        return <DateController {...props} />;
      case LOOKUP:
        return <LookupController {...props} />;
      case NUMBER:
        return <NumberController {...props} />;
      case SELECT:
        return <SelectController {...props} />;
      case SWITCH:
        return <SwitchController {...props} />;
      default:
        return <TextController {...props} />;
    }
  };

  const viewMapper = (type) => {
    switch (type) {
      case CHECKBOX:
        return <CheckboxController {...props} />;
      case SWITCH:
        return <SwitchController {...props} />;
      default:
        return <ViewMode {...props} />;
    }
  };

  return isViewMode ? viewMapper(props.type) : fieldMapper(props.type);
};

export default CustomControl;
