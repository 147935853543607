import React from "react";
import LevelTitle from "@/components/BusinessPartner/LevelTitle";
import { LEVEL2_VALUE, LEVEL3_VALUE } from "@/constants/constants";
import DataGridTable from "@/components/DataGridTable/DataGridTable";
import { businessPartnerRoute } from "@/constants/routes";
import { useBusinessPartnersTableColumns } from "@/helperFunctions/businessPartners";

const BPResultsList = ({ data, page }) => {

  const level2Data = data.filter((item) => {
    return item?.companyHierarchy?.value === LEVEL2_VALUE
  });
  const level3Data = data.filter((item) => {
    return item?.companyHierarchy?.value === LEVEL3_VALUE
  });
  level2Data.forEach((item) => {
    item['businessPartnerRoute'] = businessPartnerRoute
  });
  level3Data.forEach((item) => {
    item['businessPartnerRoute'] = businessPartnerRoute;
  });

  const columns = useBusinessPartnersTableColumns();

  return (
    <>
      {level2Data.length > 0 && (
        <>
          <LevelTitle type="2" testid="level2-title" />
          <DataGridTable
            data={level2Data}
            columns={columns}
            page={page}
          />
        </>
      )}
      {level3Data.length > 0 && (
        <>
          <LevelTitle type="3" testid="level3-title" />
          <DataGridTable
            data={level3Data}
            columns={columns}
            page={page}
          />
        </>
      )}
    </>
  );
};

export default BPResultsList;
