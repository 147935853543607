/**
 * @author Rob Cornelius
 * @description initalize app insights and a react plugin for it.
 */
import React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import env from "@beam-australia/react-env";
import { createBrowserHistory } from "history";
import { Button } from "@mui/material";
import { homeRoute } from "@/constants/routes";
import { useTranslation } from "react-i18next";

const browserHistory = createBrowserHistory({ basename: "" });
const connectionString = env("APPINSIGHTS_CONNECTION_STRING");
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    enableAutoRouteTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
export const FallbackComponent = ({ error, resetErrorBoundary }) => {
  const clickHandler = () => {
    resetErrorBoundary();
    window.location.assign(homeRoute);
  };
  const { t } = useTranslation()
  return (
    <div role="alert">
      <h1>{t("appInsights.error")}</h1>
      <h2>{error.message}</h2>
      <pre>{error.stack}</pre>
      <p>
        <Button onClick={clickHandler}>{t("appInsights.continue")}</Button>
      </p>
    </div>
  );
};

export const errorHandler = (error, info) => {
  console.error(error.message);
  console.error(info.componentStack);
  reactPlugin.trackException({ id: "errorBoundaryCaught", error: error });
};
