import React from "react";
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const Search = ({ id, label, placeholder, searchText, setSearchText, setIsTyping }) => {

  return (
    <SearchForm
      id={id}
      onSubmit={(event) => event.preventDefault()}
      data-testid="searchForm"
    >
      <SearchField
        defaultValue={searchText}
        label={label}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          setIsTyping !== null && setIsTyping(true);
          setSearchText(event.target.value)
        }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        type="search"
        data-testid="searchField"
      />
    </SearchForm>
  );
};
const SearchForm = styled('form')(() => ({
  flexBasis: 330,
}));
const SearchField = styled(TextField)(() => ({
  margin: 0,
}))

export default Search;
