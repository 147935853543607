import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CustomControl from "@/components/Form/CustomControl";
import Section from "@/components/Section";
import SectionTitle from "@/components/SectionTitle";
import ViewMode from "@/components/Form/ViewMode";
import { DATE, SWITCH, TEXT } from "@/constants/forms";
import { businessPartnerRoute, contractRoute } from "@/constants/routes";
import { useTranslation } from "react-i18next";

const OrderMainDetails = ({ control, order, mode, watch }) => {
  const [deliveryAgreementDisabled, setDeliveryAgreementDisabled] =
    useState(false);
  const [spotVolumeDisabled, setSpotVolumeDisabled] = useState(false);

  const deliveryAgreementValue = watch("deliveryAgreement");
  const spotVolumeValue = watch("spotVolume");
  const { t } = useTranslation();
  useEffect(() => {
    setSpotVolumeDisabled(false);
    if (deliveryAgreementValue) {
      setSpotVolumeDisabled(true);
    }
  }, [deliveryAgreementValue]);

  useEffect(() => {
    setDeliveryAgreementDisabled(false);
    if (spotVolumeValue) {
      setDeliveryAgreementDisabled(true);
    }
  }, [spotVolumeValue]);

  return (
    <>
      <Section id="details">
        <SectionTitle
          category={true}
          title={t("orderDetails.mainDetails.sectionTitle")}
        />
      </Section>

      <Section>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t(
                "orderDetails.mainDetails.mainDetailsFields.orderNumber",
              )}
              type={TEXT}
              view={order?.orderNumber}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.VINO_10")}
              type={TEXT}
              view={order?.viN010}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.VIN11_17")}
              type={TEXT}
              view={order?.viN1117}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.orderReference")}
              type={TEXT}
              view={order?.orderReference}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.quotationID")}
              type={TEXT}
              view={order?.quotationId}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.orderStatus")}
              type={TEXT}
              view={order?.orderStatus}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.verificationStatus")}
              type={TEXT}
              view={order?.verificationStatus?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.processingType")}
              type={TEXT}
              view={order?.processingType}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.mainDetails.mainDetailsFields.salesChannel")}
              type={TEXT}
              view={order?.salesChannel}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("orderDetails.mainDetails.mainDetailsFields.deliveryAgreement")}
              mode={mode}
              name="deliveryAgreement"
              type={SWITCH}
              disabled={deliveryAgreementDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("orderDetails.mainDetails.mainDetailsFields.spotVolume")}
              mode={mode}
              name="spotVolume"
              type={SWITCH}
              disabled={spotVolumeDisabled}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.retailerDetails.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.retailerDetails.retailerDetailsFields.orderingRetailer")}
              type={TEXT}
              view={order?.orderingRetailer?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.retailerDetails.retailerDetailsFields.deliveryRetailer")}
              type={TEXT}
              view={order?.deliveryRetailer?.name}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.dateDetails.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.dateDetails.datesFields.agreementDate")}
              type={DATE}
              view={order?.agreementDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.dateDetails.datesFields.deliveryDate")}
              type={DATE}
              view={order?.deliveryDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.dateDetails.datesFields.registrationDate")}
              type={DATE}
              view={order?.registrationDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.dateDetails.datesFields.handOverDate")}
              type={DATE}
              view={order?.handOverDate}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.dateDetails.datesFields.retailDate")}
              type={DATE}
              view={order?.retailDate}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.O_K_CP.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.O_K_CP.oKCPFields.owner")}
              type={TEXT}
              url={order?.owner && businessPartnerRoute + order?.owner?.id}
              view={order?.owner?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.O_K_CP.oKCPFields.keeper")}
              type={TEXT}
              url={order?.keeper && businessPartnerRoute + order?.keeper?.id}
              view={order?.keeper?.name}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.O_K_CP.oKCPFields.conditionProvider")}
              type={TEXT}
              url={
                order?.conditionProvider &&
                businessPartnerRoute + order?.conditionProvider?.id
              }
              view={order?.conditionProvider?.name}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.contractDetails.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.contractDetails.contractDetailsFields.contract")}
              type={TEXT}
              url={order?.contract && contractRoute + order?.contract?.id}
              view={order?.contract?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.contractDetails.contractDetailsFields.internationContract")}
              type={TEXT}
              view={order?.internationContract?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.contractDetails.contractDetailsFields.discountCategory")}
              type={TEXT}
              view={order?.discountCategory?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.contractDetails.contractDetailsFields.discountCategoryType")}
              type={TEXT}
              view={order?.discountCategoryType?.name}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.Model.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.Model.modelFields.modelCode")}
              type={TEXT}
              view={order?.modelCode?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.Model.modelFields.eSeriesGroup")}
              type={TEXT}
              view={order?.eSeriesGroup}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.nationalDiscount.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.nationalDiscount.nationalDiscountFields.discountMatrix")}
              type={TEXT}
              view={order?.discountMatrix?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode label={t("orderDetails.nationalDiscount.nationalDiscountFields.valueType")} type={TEXT} view={order?.valueType} />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.nationalDiscount.nationalDiscountFields.nscSupport")}
              type={TEXT}
              view={order?.nscSupport}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.nationalDiscount.nationalDiscountFields.dealerSupport")}
              type={TEXT}
              view={order?.dealerSupport}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.nationalDiscount.nationalDiscountFields.totalDiscount")}
              type={TEXT}
              view={order?.totalDiscount}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.nationalDiscount.nationalDiscountFields.currency")}
              type={TEXT}
              view={order?.currency?.name}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.internationalDiscount.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.internationalDiscount.internationalDiscountFields.internationalValueType")}
              type={TEXT}
              view={order?.internationalValueType}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.internationalDiscount.internationalDiscountFields.internationalDiscount")}
              type={TEXT}
              view={order?.internationalDiscount}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.internationalDiscount.internationalDiscountFields.internationalCurrency")}
              type={TEXT}
              view={order?.internationalCurrency?.name}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("orderDetails.invoicing.sectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.invoicing.invoicingFields.invoiceIndicator")}
              type={TEXT}
              view={order?.invoiceIndicator?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.invoicing.invoicingFields.sentToInvoicingDate")}
              type={DATE}
              view={order?.sentToInvoicingDate}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.invoicing.invoicingFields.invoiceNumber")}
              type={TEXT}
              view={order?.invoiceNumber}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.invoicing.invoicingFields.invoiceDate")}
              type={DATE}
              view={order?.invoiceDate}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ViewMode
              label={t("orderDetails.invoicing.invoicingFields.invoiceAmount")}
              type={TEXT}
              view={order?.invoiceAmount?.value}
            />
          </Grid>
        </Grid>
      </Section>
    </>
  );
};

export default OrderMainDetails;
