import React from "react";
import Drawer from "@mui/material/Drawer";
import NavList from "./NavList";
import { styled } from "@mui/material";
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_OPEN } from "@/constants/constants";

const NavFrame = ({ open, setOpen }) => {

  return (
    <nav>
      <StyledDrawer
        open={open}
        variant="permanent"
        data-testid="page-points-panel"
      >
        <NavList open={open} setOpen={setOpen} />
      </StyledDrawer>
    </nav>
  );
};

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  background: theme.palette.background.default,
  border: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  whiteSpace: "nowrap",
  width: open ? `${DRAWER_WIDTH_OPEN}px` : `${DRAWER_WIDTH_CLOSED}px`,

  "& .MuiDrawer-paper": {
    background: theme.palette.background.default,
    border: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    whiteSpace: "nowrap",
    width: open ? `${DRAWER_WIDTH_OPEN}px` : `${DRAWER_WIDTH_CLOSED}px`,
  },
}));
export default NavFrame;
