import { useMutation } from "@tanstack/react-query";
import { EDIT } from "@/constants/constants";
import apiContactsData from "./apiContacts";
import apiContractsData from "./apiContracts";
import apiUIData from "./apiUI";
import apiBPData from "./apiBusinessPartners";
import apiOrdersData from "./apiOrders";

export function useCreateOrUpdateContactMutation() {
  return useMutation({
    mutationFn: ([data, mode, id]) =>
      mode === EDIT
        ? apiContactsData.updateContact(id, data)
        : apiContactsData.createContact(data),
  });
}

export function useCreateOrUpdateContRactMutation() {
  return useMutation({
    mutationFn: ([data, mode, id]) =>
      mode === EDIT
        ? apiContractsData.updateContract(id, data)
        : apiContractsData.createContract(data),
  });
}

export function useSentForApprovalMutation() {
  return useMutation({
    mutationFn: ([id, entity, approvalSetupId]) => {
      return apiUIData.sendForApproval(id, entity, approvalSetupId);
    },
  });
}

export function useGenerateDocMutation() {
  return useMutation({
    mutationFn: ([id, entity, documentTemplate]) => {
      return apiContractsData.sendDocumentToBeGenerated(
        id,
        entity,
        documentTemplate,
      );
    },
  });
}

export function useCreateDunsBPMutation() {
  return useMutation({
    mutationFn: (data) => apiBPData.createBusinessPartnerFromDuns(data),
  });
}

export function useSearchDUNSMutation() {
  return useMutation({
    mutationFn: ([companyName, city, postcode, vatNumber, companyNumber]) =>
      apiBPData.searchDUNSData(
        companyName,
        city,
        postcode,
        vatNumber,
        companyNumber,
      ),
  });
}

export function useCreateESOMutation() {
  return useMutation({
    mutationFn: (data) => apiBPData.createEsoRecord(data),
  });
}

export function useSearchESOMutation() {
  return useMutation({
    mutationFn: (data) => apiBPData.searchEsoRecords(data),
  });
}

export function useUpdateOrderMutation() {
  return useMutation({
    mutationFn: ([id, data]) => apiOrdersData.updateOrder(id, data),
  });
}

export function useOrderVerificationMutation() {
  return useMutation({
    mutationFn: ([id, title, files, base64File]) =>
      apiOrdersData.uploadVerificiation(id, title, files, base64File),
  });
}

export function useSaveBPRoleMutation() {
  return useMutation({
    mutationFn: (data) => apiBPData.createBusinessPartnerRole(data),
  });
}
