import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Paging from "@/components/Paging";
import { PANEL_PAGE_SIZE } from "@/constants/constants";
import { orderRoute } from "@/constants/routes";
import apiOrdersData from "@/services/apiOrders";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledContainer,
  StyledContents,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledPagination,
  StyledTbody,
  StyledTd,
  StyledTr,
} from "@/components/Styles/PagesComponentsCommon";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const Orders = ({ bpid, isBpDetailsFetched }) => {
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});
  const { t } = useTranslation();

  const headings = [
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.orderNumber"), isAction: false },
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.orderStatus"), isAction: false },
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.orderVerificationStatus"), isAction: false },
    { heading: t("businessPartnerDetailPage.ordersPanel.tableHeadings.action"), isAction: true },
  ];

  const ordersDataQuery = useQuery({
    queryKey: ["orders", [bpid, page]],
    queryFn: async ({ signal }) => {
      return apiOrdersData.getOrdersByBP(bpid, PANEL_PAGE_SIZE, page, signal);
    },
    enabled: Boolean(bpid) && Boolean(isBpDetailsFetched),
  });

  useEffect(() => {
    if (ordersDataQuery.isError) {
      setApiError(handleApiError(ordersDataQuery.error));
    }
    if (ordersDataQuery.isSuccess) {
      setOrders(ordersDataQuery?.data?.data?.result);
      setTotalPages(ordersDataQuery?.data?.data?.totalPages);
    }
  }, [ordersDataQuery.dataUpdatedAt, ordersDataQuery.errorUpdatedAt]);

  useEffect(() => {
    return () => {
      // queryClient.invalidateQueries();
    };
  }, []);

  return (
    <>
      <StyledContainer id="orders">
        <Box>
          <StyledHeader>
            <StyledContents>
              <StyledHeading component="h2" variant="h4">
                {t("businessPartnerDetailPage.ordersPanel.sectionTitle")}
              </StyledHeading>
            </StyledContents>
          </StyledHeader>
        </Box>
        <TableHeaders headings={headings}>
          {orders.length > 0 ? (
            <StyledTbody>
              {orders?.map(
                (
                  { id, orderNumber, orderStatus, verificationStatus },
                  index,
                ) => (
                  <StyledTr key={index}>
                    <StyledTd>
                      <ConditionalTooltip
                        title={orderNumber}
                        value={orderNumber}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={orderStatus}
                        value={orderStatus}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={verificationStatus?.name}
                        value={verificationStatus?.name}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={t(
                          "businessPartnerDetailPage.ordersPanel.viewOrderTooltip",
                          { orderNumber: orderNumber },
                        )}
                      >
                        <IconButton
                          color="primary"
                          component={Link}
                          size="small"
                          to={orderRoute + id}
                        >
                          <LaunchIcon fontSize="inherit" />
                        </IconButton>
                      </ConditionalTooltip>
                    </StyledTd>
                  </StyledTr>
                ),
              )}
            </StyledTbody>
          ) : (
            <StyledNoResults>
              {t("businessPartnerDetailPage.ordersPanel.noResults")}
            </StyledNoResults>
          )}
        </TableHeaders>

        <StyledPagination>
          {totalPages > 1 && <Paging count={totalPages} page={page} />}
        </StyledPagination>
      </StyledContainer>
      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default Orders;
