import env from "@beam-australia/react-env";
let apiVersion = env("API_VERSION");
let apiBaseUrl = `${env("API_URL")}v${apiVersion}/`;

const getTokenDecodeResponse = async (idToken, accessToken, tokenRealm) => {
  let endpoint = "user/idtokenexpiryanddata";
  try {
    const response = await fetch(`${apiBaseUrl}${endpoint}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        idtoken: idToken,
        accesstoken: accessToken,
        tokenrealm: tokenRealm,
      },
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      const error = new Error(response.status);
      error.statusCode = response.status;
      throw error;
    }
  } catch (err) {
    return err;
  }
};

const apiAuthData = {
  getTokenDecodeResponse,
};

export default apiAuthData;
