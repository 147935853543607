const createContractMapper = (
    account,
  {
    bmw_saleschannel,
    bmw_contractcategoryid,
    bmw_documenttemplate,
    bmw_contractstatusglobaloptionset,
    bmw_exoticcontract,
    bmw_startdate,
    bmw_endofcontract,
    bmw_duration,
    bmw_contracttemplateid,
    bmw_terms_and_conditions,
    bmw_datesigned,
    bmw_datetosubmitclaimdocuments,
    bmw_signedbynsc,
    bmw_lastorderdate,
    bmw_lastagreementdate,
    bmw_totalcompletedorders,
    bmw_bulkapprovalteam,
    bmw_leasedvehicleorders,
  },
) => ({
  account: {
        id: account?.id,
        logicalName: account?.logicalName,
        name: account?.name,
  },
  salesChannel: { value: bmw_saleschannel ? bmw_saleschannel : null },
  contractCategory: bmw_contractcategoryid ? {
    id: bmw_contractcategoryid?.id,
    logicalName: bmw_contractcategoryid?.logicalName,
    name: bmw_contractcategoryid?.name,
  } : null,
  template: bmw_documenttemplate ? bmw_documenttemplate : null,
  contractStatus: { value: bmw_contractstatusglobaloptionset ? bmw_contractstatusglobaloptionset : null },
  customised: bmw_exoticcontract === 20,
  startDate :bmw_startdate,
  endDate :bmw_endofcontract,
  days: bmw_duration ? bmw_duration : null,
  upfrontDiscount: bmw_contracttemplateid
    ? {
        id: bmw_contracttemplateid?.id,
        logicalName: bmw_contracttemplateid?.logicalName,
        name: bmw_contracttemplateid?.name,
      }
    : null,
  termsConditions: bmw_terms_and_conditions ? bmw_terms_and_conditions : null,
  dateSigned : bmw_datesigned,
  lastDateSubmitClaim : bmw_datetosubmitclaimdocuments,
  signedByNSC: bmw_signedbynsc === 20,
  lastCompletedOrder : bmw_lastorderdate,
  lastAgreementDate : bmw_lastagreementdate,
  totalCompletedOrders : bmw_totalcompletedorders,
  ownVehicleOrders : bmw_bulkapprovalteam,
  leasedVehicleOrders : bmw_leasedvehicleorders
});

export { createContractMapper };

const PopulateBusinessPartner = ({data})=>({
name : data.name,
bmw_legalname:data.legalName,
bmw_vatnumber:data.vatNumber,
bmw_representedbyx:data.representedBy,
bmw_companyhierarchylevel:data.companyHierarchy,
websiteurl:data.url,
parentaccountid:data.l2ParentCompany,
bmw_address1buildingnumber:data.buildingNumber,
address1_line1:data.address1Line1,
address1_line2:data.address1Line2,
address1_line3:data.address1Line3,
address1_postalcode:data.address1PostCode,
address1_postofficebox:data.postbox,
address1_city:data.address1City,
address1_county:data.county,
bmw_address1_countyid:data.country,
bmw_salesregionid:data.salesRegion,
bmw_hasbillingaddress:data.hasBillingAddress,
bmw_address2buildingnumber:data.address2BuildingNumber,
address2_line1:data.address2Line1,
address2_line2:data.address2Line2,
address2_line3:data.address2Line3,
address2_postalcode:data.address2PostCode,
address2_postofficebox:data.address2PostBox,
address2_city:data.address2City,
address2_county:data.address2County,
address2_country:data.address2Country,
bmw_address2salesregionid:data.address2SalesRegion,
bmw_accounttypeid:data.type,
bmw_accountsubtypeid:data.subType,
/* :data.legalEntityType,
:data.owningRetailer,
:data.owningRetailerContact,
:data.owningKAM,
:data.termOfCredit,
:data.invoiceLanguage, */
})
export {PopulateBusinessPartner};