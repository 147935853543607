import React from "react";
import { StyledLevelTitle, StyledLevelTitleDivider } from "@/components/Styles/PagesComponentsCommon";
import { useTranslation } from "react-i18next";

const LevelTitle = ({ type, testid }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledLevelTitle variant="body2" data-testid={testid}>
        {t("businessPartnersPage.level")} {type}
      </StyledLevelTitle>
      <StyledLevelTitleDivider />
    </>
  );
};

export default LevelTitle;
