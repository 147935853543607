import React from "react";

import Grid from "@mui/material/Grid";
import Section from "@/components/Section";
import SectionTitle from "@/components/SectionTitle";
import ViewMode from "@/components/Form/ViewMode";
import { useTranslation } from "react-i18next";

import { TEXT } from "@/constants/forms";

const UpfrontDiscountMatrix = ({
    name,
    termsType,
    salesModel,
    type,
    valueType,
    unspecifiedOptionTerms,
    currency,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Section id="upfrontDiscountMatrix">
                <SectionTitle category={true} title={t("discountMatrixPage.title")} />
            </Section>

            <Section>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <ViewMode
                            label={t("discountMatrixPage.upFrontDiscountMatrix.nameLabel")}
                            type={TEXT}
                            view={name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ViewMode
                            label={t("discountMatrixPage.upFrontDiscountMatrix.termTypeLabel")}
                            type={TEXT}
                            view={termsType?.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ViewMode
                            label={t("discountMatrixPage.upFrontDiscountMatrix.salesModelLabel")}
                            type={TEXT}
                            view={salesModel?.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ViewMode
                            label={t("discountMatrixPage.upFrontDiscountMatrix.typeLabel")}
                            type={TEXT}
                            view={type}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ViewMode
                            label={t("discountMatrixPage.upFrontDiscountMatrix.valueTypeLabel")}
                            type={TEXT}
                            view={valueType}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ViewMode
                            label={t("discountMatrixPage.upFrontDiscountMatrix.unspecifiedOptionsTermsLabel")}
                            type={TEXT}
                            view={unspecifiedOptionTerms}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ViewMode
                            label={t("discountMatrixPage.upFrontDiscountMatrix.currencyLabel")}
                            type={TEXT}
                            view={currency?.name}
                        />
                    </Grid>
                </Grid>
            </Section>
        </>
    )
};

export default UpfrontDiscountMatrix;
