import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Modal from "@/components/Modal";
import theme from "@/styles/bmwTheme";
import utils from "@/utils/common";
import useToken from "@/utils/useToken";

const AuthError = ({
  idToken,
  apiError: { message, responseURL, statusCode, title },
}) => {
  const redirectToSGate = () => {
    if (idToken) {
      utils.redirectToSGateForLogout(idToken);
    } else if (utils.isTokenValid()) {
      idToken = useToken.getIdToken();
      utils.redirectToSGateForLogout(idToken);
    } else {
      utils.redirectToSGateForLogin();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Modal
        buttonText="Return to login"
        handler={() => redirectToSGate()}
        message={message}
        open={true}
        responseURL={responseURL}
        title={title}
        statusCode={statusCode}
      />
    </ThemeProvider>
  );
};

export default AuthError;
