import React from "react";

import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const PageTitle = ({ subtitle, title }) => {
  return (
    <PageTitleBox>
      <MainTitle component="h2" variant="h4" data-testid="pageTitle">
        {title}
      </MainTitle>

      {subtitle && (
        <SubTitle component="p" variant="body2" data-testid="pageSubtitle">
          {subtitle}
        </SubTitle>
      )}
    </PageTitleBox>
  );
};

const PageTitleBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));
const MainTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));
const SubTitle = styled(Typography)(({ theme }) => ({
  color: "#676a6b",
  marginTop: theme.spacing(0.25),
}))

export default PageTitle;
