import React, { useContext, useEffect, useState } from "react";
import AppError from "@/AppError";
import PageTitle from "@/components/PageTitle";
import PagingCount from "@/components/PagingCount";
import Paging from "@/components/Paging";
import Search from "@/components/Search";
import DataGridTable from "@/components/DataGridTable/DataGridTable";
import { useTacticalCampaignsColumns } from "@/components/TacticalCampaigns/Table";
import { TacticalCampaignsContext } from "@/context/TacticalCampaignsContext";
import { handleApiError } from "@/utils/handleApiError";
import { StyledPageHeader } from "@/components/Styles/PagesComponentsCommon";
import { useDebounce } from "@/hooks/useDebounce";
import { useFetchTacticalCampaigns } from "@/services/queries";
import { tacticalCampaignsRoute } from "@/constants/routes";
import { useSearchParams } from "@/hooks/useSearchParams";
import { createSearchParams, useNavigate } from "react-router-dom";
import useFirstRender from "@/hooks/useFirstRender";
import { useTranslation } from "react-i18next";

export const TacticalCampaignsPage = () => {
  const controller = new AbortController();
  const { t } = useTranslation();
  const {
    tacticalCampaigns,
    setTacticalCampaigns,
    searchText,
    setSearchText,
    pageSize,
    totalPages,
    setTotalPages,
  } = useContext(TacticalCampaignsContext);
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const isFirstRender = useFirstRender();
  const columns = useTacticalCampaignsColumns();
  const [apiError, setApiError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  let page = searchParams.get("page") || 1;
  const debouncedSearch = useDebounce([searchText]);

  const searchTacticalCampaignsQuery = useFetchTacticalCampaigns(
    searchText,
    page,
    debouncedSearch,
    pageSize
  );

  useEffect(() => {
    setIsLoading(searchTacticalCampaignsQuery.isFetching);
    if (searchTacticalCampaignsQuery.isError) {
      setApiError(handleApiError(searchTacticalCampaignsQuery.error))
    }
    if (searchTacticalCampaignsQuery.isSuccess) {
      setSearched(true);
      setTacticalCampaigns(searchTacticalCampaignsQuery?.data?.data?.result);
      setTotalPages(searchTacticalCampaignsQuery?.data?.data?.totalPages);
    }
  }, [searchTacticalCampaignsQuery.dataUpdatedAt, searchTacticalCampaignsQuery.errorUpdatedAt]);

  useEffect(() => {
    if (!isFirstRender) {
      navigate({
        pathname: tacticalCampaignsRoute,
        search: `?${createSearchParams({
          page: 1,
        })}`,
        replace: true
      })
    }
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  /*
    name,
    salesModel,
    campaignCode,
    campaignStatus,
    discountType,
    startDate,
    endDate,
    discountMatrix,
    overridingContractTerm,
*/

  return (
    <>
      <StyledPageHeader>
        <PageTitle title={t("tacticalCampaignsPage.pageTitle")} />

        <Search
          id="tacticalCampaignsSearch"
          label={t("tacticalCampaignsPage.searchLabel")}
          placeholder={t("tacticalCampaignsPage.searchPlaceholder")}
          searchText={searchText}
          setSearchText={setSearchText}
          setIsTyping={null}
        />
      </StyledPageHeader>

      {totalPages > 0 && <PagingCount count={totalPages} page={page} />}

      <DataGridTable
        data={tacticalCampaigns}
        columns={columns}
        isLoading={isLoading}
        searched={searched}
      />

      {totalPages > 1 && (
        <Paging count={totalPages} page={page} route={tacticalCampaignsRoute} />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
