import React, { createContext, useState } from "react";

const ContractDetailsContext = createContext({});

const ContractDetailsProvider = ({ children }) => {
  // Controls
  const [approvalTypes, setApprovalTypes] = useState([]);
  const [contractStatuses, setContractStatuses] = useState([]);
  const [contractCategories, setContractCategories] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [upfrontDiscountMatrixes, setUpfrontDiscountMatrixes] = useState([]);
  const [discountMatrixFilteredData, setdiscountMatrixFilteredData] = useState([]);


  const customisedOptions = [
    { value: 10, name: "No" },
    { value: 20, name: "Yes" },
  ];

  const signedByNSCOptions = [
    { value: 10, name: "No" },
    { value: 20, name: "Yes" },
  ];

  return (
    <ContractDetailsContext.Provider
      value={{
        approvalTypes,
        setApprovalTypes,
        contractStatuses,
        setContractStatuses,
        contractCategories,
        setContractCategories,
        customisedOptions,
        salesChannels,
        setSalesChannels,
        signedByNSCOptions,
        upfrontDiscountMatrixes,
        setUpfrontDiscountMatrixes,
        discountMatrixFilteredData,
        setdiscountMatrixFilteredData,
      }}
    >
      {children}
    </ContractDetailsContext.Provider>
  );
};

export { ContractDetailsContext, ContractDetailsProvider };
