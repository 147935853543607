import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Divider,
  TextField,
  IconButton,
  Tooltip,
  Autocomplete,
  Fab,
} from "@mui/material";
import Image from "mui-image";
import { DropzoneArea } from "mui-file-dropzone";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import ConditionalLink from "@/components/ConditionalLink";

export const StyledContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #dce0e4",
  borderRadius: 3,
  marginTop: theme.spacing(3),
}));
export const StyledHeader = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  height: 70,
  padding: theme.spacing(1, 3),
}));
export const StyledContents = styled(Box)(() => ({
  flex: "0 0 auto",
}));
export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));
export const StyledSubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(2,0),
}));
export const StyledTableContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3, 3),
}));
export const StyledTable = styled(Box)(() => ({
  display: "table",
  tableLayout: "fixed",
  width: "100%",
}));
export const StyledThead = styled(Box)(() => ({
  display: "table-header-group",
}));
export const StyledTr = styled(Box)(() => ({
  display: "table-row",
}));
export const StyledTh = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #dce0e4",
  borderTop: "1px solid #dce0e4",
  display: "table-cell",
  fontWeight: 700,
  height: 40,
  padding: theme.spacing(0.5, 1),
  position: "relative",
  verticalAlign: "middle",
  whiteSpace: "pre-wrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:after": {
    display: "block",
    background: "#8a8c8f",
    content: "''",
    height: 20,
    width: 1,
    position: "absolute",
    right: 0.5,
    top: "calc(50% - 10px)",
  },
}));
export const StyledActionTh = styled(StyledTh)(() => ({
  width: 100,
  whiteSpace: "pre-wrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
export const StyledTbody = styled(Box)(({ theme }) => ({
  display: "table-row-group",
  position: "relative",
  "& .timeLine-tableTr:nth-child(odd)": {
    backgroundColor: theme.palette.secondary.main,
  },
}));
export const StyledTd = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #8a8c8f",
  display: "table-cell",
  height: 40,
  padding: theme.spacing(0.5, 1),
  verticalAlign: "middle",
}));
export const StyledNoResults = styled(Box)(({ theme }) => ({
  captionSide: "bottom",
  display: "table-caption",
  paddingTop: theme.spacing(3),
  textAlign: "center",
}));
export const StyledPagination = styled(Box)(({ theme }) => ({
  margin: theme.spacing(-3, 0, 0),
  padding: theme.spacing(0, 3, 3),
}));

export const StyledPanelPaper = styled(Box)(({ theme }) => ({
  padding: 0,
  border: "1px solid #dce0e4",
  textAlign: "left",
  color: theme.palette.text.secondary,
  marginBottom: 2,
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: "#797979",
  display: "block",
  marginTop: theme.spacing(0.5),
}));

export const StyledAddButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  height: 30,
  minWidth: 30,
  padding: theme.spacing(0),
}));

export const StyledPanelDataTable = styled(TableContainer)(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 0),
}));

export const StyledPanelTable = styled(Table)(() => ({
  margin: 0,
  borderCollapse: "separate",
  borderSpacing: "0px 6px",
}));

export const StyledPanelThead = styled(TableHead)(() => ({
  padding: 0,
  marginBottom: 5,
  paddingBottom: 5,
}));

export const StyledPanelTh = styled(TableCell)(() => ({
  borderRightStyle: "solid",
  borderRightColor: "#ccc",
  borderRightWidth: "1px",
  "&:last-child": {
    borderRightStyle: "none",
  },
  borderTop: "1px solid #dce0e4",
}));

export const StyledPanelTableBody = styled(TableBody)(({ theme }) => ({
  padding: theme.spacing(20, 10),
}));

export const StyledPanelTableTitleRow = styled(TableRow)(() => ({
  height: 30,
}));

export const StyledPanelMessageRow = styled(TableCell)(() => ({
  borderBottom: "none",
  paddingTop: 1,
  paddingBottom: 1,
  textAlign: "center",
}));

export const StyledPanelTableRow = styled(TableRow)(() => ({
  backgroundColor: "#f1f6fa",
  borderBottomStyle: "solid",
  borderBottomColor: "#8a8c8f",
  borderBottomWidth: "3px",
}));

export const StyledTooltipWrapper = styled(Box)(() => ({
  position: "absolute",
  zIndex: 1,
  top: "50%",
  right: 0,
  transform: "translate(100%, -50%)",
}));
export const StyledNotes = styled(Box)(({ theme }) => ({
  border: "1px solid #dce0e4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: theme.spacing(0, 3, 2),
  minHeight: 70,
  padding: theme.spacing(1),
}));
export const StyledNote = styled(Box)(({ theme }) => ({
  alignItems: "baseline",
  display: "flex",
  "& + $note": {
    marginTop: theme.spacing(1),
  },
}));
export const StyledNoteTitle = styled(Typography)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 160,
  fontWeight: theme.typography.fontWeightBold,
  position: "relative",
  "&::after": {
    display: "block",
    background: "#8a8c8f",
    content: "''",
    height: 20,
    width: 1,
    position: "absolute",
    right: -0.5,
    top: "calc(50% - 10px)",
  },
}));
export const StyledNoteInfo = styled(Typography)(({ theme }) => ({
  wordBreak: "break-word",
  marginLeft: theme.spacing(1),
}));

export const StyledSummary = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(-254.58257074100553deg, #f1efff 0%, #f1f6fa 47.318037614688656%, #f1f6fa 100%)",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  borderRadius: 3,
  borderWidth: 1,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(3.5, 3, 4.5),
  minHeight: 220,
}));
export const StyledSummaryContainer = styled(Box)(() => ({
  alignSelf: "center",
  flexBasis: "17.477572816%",
}));
export const StyledSummaryImageContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.common.white,
  border: "1px solid #797979",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  height: 80,
  margin: theme.spacing(0, "auto", 1),
  width: 80,
}));
export const StyledSummaryImage = styled(Image)(() => ({
  height: "auto !important",
}));
export const StyledSummaryTime = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.hint,
  textAlign: "center",
}));
export const StyledSummaryContents = styled(Typography)(() => ({
  display: "flex",
  flexBasis: "47.57281553%",
  flexDirection: "column",
  justifyContent: "space-between",
}));
export const StyledSummaryCountry = styled(Typography)(({ theme }) => ({
  color: "#0b406c",
  marginBottom: theme.spacing(1),
  maxWidth: 350,
}));
export const StyledSummaryName = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  maxWidth: 380,
}));
export const StyledSummaryList = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginLeft: -theme.spacing(2),
  marginTop: theme.spacing(2),
  maxWidth: 450,
}));
export const StyledSummaryItem = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
export const StyledSummaryBullet = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  marginLeft: -theme.spacing(2),
  width: 20,
  "&::before": {
    background: "#c92323",
    borderRadius: "50%",
    content: "''",
    display: "block",
    height: 3,
    width: 3,
  },
}));
export const StyledSummaryTitle = styled(Typography)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(0.5),
  position: "relative",
}));
export const StyledSummaryType = styled(Box)(({ theme }) => ({
  color: theme.palette.success.main,
}));
export const StyledSummaryStatus = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
}));
export const StyledSummaryIcon = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  marginLeft: -theme.spacing(2),
  width: 20,
}));
export const StyledLevelTitle = styled(Typography)(() => ({
  alignItems: "center",
  border: "1px solid #676a6b",
  borderRadius: 5,
  display: "flex",
  flexBasis: 80,
  height: 30,
  justifyContent: "center",
  maxWidth: "80px",
}));
export const StyledLevelTitleDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.divider,
  marginLeft: theme.spacing(10),
  flex: 1,
  marginTop: "-15px",
}));

export const StyledNoResultsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));
export const StyledNoResultsContents = styled(Box)(() => ({
  maxWidth: 225,
  textAlign: "center",
}));
export const StyledNoResultsPrompt = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.hint,
  marginTop: theme.spacing(1),
}));
export const StyledNoResultsDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 1),
}));
export const StyledNoResultsButton = styled(Button)(() => ({
  minWidth: 220,
}));
export const StyledField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
export const StyledTextarea = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
export const StyledError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: "4px",
  marginLeft: "14px",
  marginRight: "14px",
}));
export const StyledDMorTCTableContainer = styled(TableContainer)(
  ({ theme }) => ({
    boxShadow: theme.shadows[2],
    marginTop: theme.spacing(3),
  }),
);
export const StyledDMorTCTable = styled(Table)(({ theme }) => ({
  "& caption": {
    boxShadow: theme.shadows[2],
    captionSide: "top",
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(2, 3),
  },
  "& th, & td": {
    height: 40,
    padding: theme.spacing(1, 1),
    "&:first-child": {
      paddingLeft: theme.spacing(3),
    },
    "&:last-child": {
      paddingRight: theme.spacing(4),
    },
  },
}));
export const StyledDMorTCTableHeading = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  fontWeight: theme.typography.fontWeightBold,
  justifyContent: "space-between",

  "&::after": {
    background: "#8a8c8f",
    content: '""',
    height: 15,
    marginLeft: theme.spacing(1),
    marginRight: -theme.spacing(1),
    width: 1,
  },
}));
export const StyledDMorTCSeparator = styled(TableRow)(() => ({
  height: 20,
  "&:last-of-type": {
    height: 40,
  },
}));
export const StyledDunsButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  background: "transparent",
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.accent,
    background: "transparent",
  },
}));
export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  maxWidth: "120px",
  textAlign: "center",
  padding: theme.spacing(1, 0.5, 1, 0.5),
}));
export const StyledTooltipArrow = styled(StyledTooltip)(({ theme }) => ({
  color: theme.palette.common.black,
}));
export const StyledDropzone = styled(DropzoneArea)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.primary.main,
  borderRadius: 3,
  borderWidth: 1,
  minHeight: 80,
  margin: 0,
  padding: theme.spacing(3),
  "& .MuiDropzoneArea-textContainer": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    padding: 0,
  },
  "& .MuiDropzoneArea-icon": {
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
  },
}));
export const StyledDropzoneText = styled(DropzoneArea)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.body2.fontSize,
  margin: theme.spacing(1, 0, 0, 0),
}));
export const StyledPreviewChip = styled(DropzoneArea)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  height: 30,
  padding: 0,
}));
export const StyledDeleteIcon = styled(DropzoneArea)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.light,
  },
}));
export const StyledAutoComplete = styled(Autocomplete)(() => ({
  right: "14px !important",
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)",
  },
}));
export const StyledViewModeContainer = styled(Box)(() => ({
  marginTop: 8,
  marginBottom: 4,
  position: "relative",
}));
export const StyledViewModeLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: 1,
  position: "absolute",
  transform: "translate(14px, -6px) scale(0.75)",
  transformOrigin: "top left",
}));
export const StyledConditionalTooltip = styled(ConditionalTooltip)(
  ({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    lineHeight: "1.1876em",
    letterSpacing: "normal",
    padding: "10.5px 14px",
  }),
);
export const StyledConditionalLink = styled(ConditionalLink)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: "1.1876em",
  letterSpacing: "normal",
  padding: "10.5px 14px",
}));
export const StyledResultContainer = styled(Box)(() => ({
  position: "relative",
  minHeight: 150,
}));
export const StyledPageDescription = styled(Typography)(({ theme }) => ({
  color: "#8a8c8f",
  marginTop: theme.spacing(0.5),
}));
export const StyledOrBox = styled(Box)(() => ({
  fontSize: 18,
  color: "#212121",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:before, &:after": {
    content: "''",
    flexGrow: 1,
    height: "1px",
    background: "#00000044",
    width: "100%",
  },
  ".fields-separator": {
    width: "100px",
    wordWrap: "break-word",
    padding: "0 10px",
  },
}));
export const StyledPagedivider = styled(Divider)(({ theme }) => ({
  backgroundColor: "#9da0a0",
  margin: theme.spacing(3, 0, 3, 0),
}));
export const StyledPageBanner = styled(Box)(({ theme }) => ({
  flex: "100%",
  marginBottom: theme.spacing(2),
}));
export const StyledPageContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
}));
export const StyledPageMain = styled(Box)(() => ({
  flex: 1,
}));
export const StyledPageSide = styled(Box)(({ theme }) => ({
  flexBasis: 240,
  marginLeft: theme.spacing(2),
}));
export const StyledPageFab = styled(Fab)(({ theme }) => ({
  right: "auto",
  position: "relative",
  bottom: 0,
  backgroundColor: theme.palette.common.orange,
  gap: theme.spacing(1),
  ":hover": {
    backgroundColor: theme.palette.common.orangeLight,
  }
}));
export const StyledPageHeader = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
}));
export const StyledContractDetailsSummary = styled(Box)(({ theme }) => ({
  alignItems: "center",
  background:
    "linear-gradient(-254.58257074100553deg, #f1efff 0%, #f1f6fa 47.318037614688656%, #f1f6fa 100%)",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  borderRadius: 3,
  borderWidth: 1,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(6, 8, 6, 5),
  minHeight: 220,
}));
export const StyledContractDetailsSummaryName = styled(Typography)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: 350,
  }),
);
export const StyledContractDetailsSummaryList = styled(Box)(() => ({
  display: "flex",
}));
export const StyledContractDetailsSummaryDivider = styled(Divider)(
  ({ theme }) => ({
    background: "#797979",

    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  }),
);
export const StyledContractDetailsSummaryItem = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: 60,
  justifyContent: "center",
  width: 160,

  "&:first-of-type": {
    width: 140,
  },

  "&:last-of-type": {
    width: 110,
  },
}));
export const StyledContractDetailsSummaryContents = styled(Box)(
  ({ theme }) => ({
    padding: theme.spacing(0, 1),
  }),
);
export const StyledContractDetailsSummaryTitle = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.text.hint,
    marginTop: theme.spacing(0.5),
  }),
);
export const StyledContractDetailsSummaryValue = styled(Box)(() => ({
  display: "flex",
}));
