import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const PagingCount = ({ count, page }) => {
  const { t } = useTranslation();
  return (
    <PagingCountContainer>
      <PagingCountDivider />

      <PagingCountText variant="body2">
        {t("pageCount.showing")} {page} {t("pageCount.of")} {count} {t("pageCount.pages")}
      </PagingCountText>
    </PagingCountContainer>
  );
};
const PagingCountContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  marginTop: theme.spacing(2),
}));
const PagingCountText = styled(Typography)(() => ({
  alignItems: "center",
  borderRadius: 5,
  display: "flex",
  justifyContent: "center",
}));
const PagingCountDivider = styled(Divider)(({ theme }) => ({
  background: "#9da0a0",
  marginRight: theme.spacing(4),
  flex: 1,
}));

export default PagingCount;
