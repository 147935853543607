import React from "react";
import { Link } from "react-router-dom";

import MuiLink from "@mui/material/Link";

import ConditionalTooltip from "@/components/ConditionalTooltip";

const ConditionalLink = ({ className, title, url, variant, testid, label }) => {
  const isExternalLink = (url) => {
    const tmp = document.createElement("a");
    tmp.href = url;

    return tmp.host !== window.location.host;
  };

  return (
    <ConditionalTooltip className={className} title={title}>
      {isExternalLink(url) ? (
        <MuiLink component={"a"} href={url} target="_blank" variant={variant} data-testid={testid} aria-label={label}>
          {title}
        </MuiLink>
      ) : (
        <MuiLink component={Link} to={url} variant={variant} data-testid={testid} aria-label={title}>
          {title}
        </MuiLink>
      )}
    </ConditionalTooltip>
  );
};

export default ConditionalLink;
