import React from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import NavItem from "@/components/Nav/NavItem";
import { useTranslation } from "react-i18next";
import DashboardIcon from "@/assets/images/icons/dashboard.svg?react";
import BusinessPartnersIcon from "@/assets/images/icons/business-partners.svg?react";
import ContractsIcon from "@/assets/images/icons/contracts.svg?react";
import OrdersIcon from "@/assets/images/icons/orders.svg?react";
import CampaignsIcon from "@/assets/images/icons/campaigns.svg?react";
import HelpIcon from "@/assets/images/icons/help.svg?react";
import SettingsIcon from "@/assets/images/icons/settings.svg?react";
import SignOutIcon from "@/assets/images/icons/sign-out.svg?react";
import Logo from "@/assets/images/bmw-group-logo.svg?react"

import {
  businessPartnersRoute,
  contractsRoute,
  homeRoute,
  ordersRoute,
  tacticalCampaignsRoute,
  helpRoute,
  settingsRoute,
  logoutRoute,
} from "@/constants/routes";
import { ListItem, styled } from "@mui/material";


const NavList = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const menuItemsTop = [
    { name: t("menu.dashboard"), path: homeRoute, icon: <DashboardIcon /> },
    {
      name: t("menu.businessPartners"),
      path: businessPartnersRoute,
      icon: <BusinessPartnersIcon />,
    },
    {
      name: t("menu.contracts"),
      path: contractsRoute,
      icon: <ContractsIcon />,
    },
    {
      name: t("menu.orders"),
      path: ordersRoute,
      icon: <OrdersIcon />,
    },
    {
      name: t("menu.tacticalCampaigns"),
      path: tacticalCampaignsRoute,
      icon: <CampaignsIcon />,
    },
  ];

  const menuItemsBottom = [
    { name: t("menu.help"), path: helpRoute, icon: <HelpIcon /> },
    {
      name: t("menu.settings"),
      path: settingsRoute,
      icon: <SettingsIcon />,
      disabled: true,
    },
    {
      name: t("menu.signOut"),
      path: logoutRoute,
      icon: <SignOutIcon />,
    },
  ];

  return (
    <StyledList onMouseLeave={() => setOpen(false)}>
      <StyledListItem>
        <StyledLogoBox open={open}>
          <Logo
            alt="BMW Group logo"
            style={{ height: open ? '32px' : '20px', width: open ? '60px' : '38px' }}
          />
        </StyledLogoBox>
      </StyledListItem>

      <StyledListItem>
        <StyledDivider open={open} />
      </StyledListItem>

      {menuItemsTop.map((item, index) => (
        <NavItem
          {...item}
          setOpen={setOpen}
          key={"upperMenu" + index}
        />
      ))}

      <StyledListItem>
        <StyledDivider open={open} />
      </StyledListItem>

      {menuItemsBottom.map((item, index) => {
        if (item.disabled) return null;
        return (
          <NavItem
            {...item}
            setOpen={setOpen}
            key={"lowerMenu" + index}
          />
        );

      })}

      <StyledListItem>
        <StyledDivider open={open} />
      </StyledListItem>
    </StyledList>
  );
};

const StyledList = styled(List)(({ theme }) => ({
  margin: theme.spacing(4, 1, 1),
  padding: 0,
}));

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
}));

const StyledLogoBox = styled(Box)(({ open, theme }) => ({
  height: 30,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: open ? theme.spacing(1) : 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
}));
const StyledDivider = styled(Divider)(({ theme, open }) => ({
  background: theme.palette.divider,
  margin: theme.spacing(2, "auto"),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  width: open ? "89.47368421%" : "100%",
}));

export default NavList;
