import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { dealerNameKey, masterRetailerNumberKey, businessPartnerIdKey } from "@/constants/constants";
import { AppContext } from '@/context/AppContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const {
    userData: {
      [businessPartnerIdKey]: bpId,
      [masterRetailerNumberKey]: masterRetailerNumber,
      [dealerNameKey]: dealerName
    },
  } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <FooterTypography component="h1" variant="body2">
      {t("footer.details", { bpid: bpId, masterRetailerNumber: masterRetailerNumber, retailerName: dealerName })}
    </FooterTypography>
  );
};

const FooterTypography = styled(Typography)(() => ({
  fontWeight: 10,
  position: "fixed",
  right: 50,
  bottom: 5,
}))

export default Footer;
