export const filters = [
  {
    name: "businessPartnersPage.filters.filter1",
    key: "My Business Partners",
  },
  {
    name: "businessPartnersPage.filters.filter2",
    key: "Prospect",
  },
  {
    name: "businessPartnersPage.filters.filter3",
    key: "Lead",
  },
  {
    name: "businessPartnersPage.filters.filter4",
    key: "Customer",
  },
  {
    name: "businessPartnersPage.filters.filter5",
    key: "Lapsed",
  },
  {
    name: "businessPartnersPage.filters.filter6",
    key: "ROLE EXPIRY IN SIX MONTHS",
  },
  {
    name: "businessPartnersPage.filters.filter7",
    key: "ROLE EXPIRY IN LAST SIX MONTHS",
  },
];
