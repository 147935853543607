import React from "react";
import { styled } from '@mui/material/styles';

import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSearchParams } from "@/hooks/useSearchParams";

const Paging = ({ count, disabled, page, route }) => {

  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const handleChange = (e, value) => {
    navigate({
      pathname: route,
      search: `?${createSearchParams({
        page: value,
        quickFilter: searchParams.get("quickFilter")
      })}`,
      replace: true
    })
  };

  return (
    <PaginationBox data-testid="paginationBox">
      <Pagination
        count={count}
        disabled={disabled}
        page={page !== null && page !== undefined && !isNaN(Number(page)) ? Number(page) : 1}
        onChange={handleChange}
        size="small"
      />
    </PaginationBox>
  );
};

const PaginationBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(3, 0),
}))

export default Paging;
