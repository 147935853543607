import React from "react";
import { Controller } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import TooltipConditionalWrapper from "@/components/TooltipConditionalWrapper";
import { useTranslation } from "react-i18next";

import {
  EMAIL,
  EMAIL_VALIDATION_REGEX,
  TEL,
  TELEPHONE_VALIDATION_REGEX,
  NAME,
  NAME_VALIDATION_REGEX,
  TEXT,
} from "@/constants/forms";


const TextController = ({
  control,
  disabled,
  helperText = null,
  label,
  maxLength,
  minRows,
  multiline,
  name,
  required,
  type = TEXT,
  title,
  testid
}) => {

  const { t } = useTranslation();
  const getValidationPattern = (type) => {
    switch (type) {
      case EMAIL:
        return {
          value: EMAIL_VALIDATION_REGEX,
          message: t("forms.text.emailErrorMessage")
        }
      case TEL:
        return {
          value: TELEPHONE_VALIDATION_REGEX,
          message: t("form.text.phoneNumberErrorMessage")
        }
      case NAME:
        return {
          value: NAME_VALIDATION_REGEX,
          message: t("form.text.nameErrorMessage")
        }
      default:
        return null;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
        <TooltipConditionalWrapper
          condition={title}
          wrapper={children => <Tooltip title={title}>{children}</Tooltip>}>
          <TextField
            disabled={disabled}
            label={label}
            minRows={minRows}
            maxLength={maxLength}
            multiline={multiline}
            value={value}
            onChange={onChange}
            error={!!error}
            required={required}
            data-testid={testid}
            helperText={error ? error.message : helperText}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: disabled && <LockOutlinedIcon />,
            }}
            inputProps={{
              maxLength: maxLength,
            }}
            inputRef={ref}
            type={type}
          />
        </TooltipConditionalWrapper>
      )}
      rules={{
        required: required && t("form.text.required"),
        pattern: getValidationPattern(type),
        maxLength: maxLength && {
          value: maxLength,
          message: t("form.text.maxLength", { maxLength: maxLength })
        },
      }}
    />
  );
};

export default TextController;
