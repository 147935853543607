import React, { useContext } from "react";

import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { AppContext } from "@/context/AppContext";

const Main = ({ children }) => {

  const { mainRef } = useContext(AppContext);
  /**
   * @author Rob Cornelius
   * sx property for main componenet is used as the styled HOC does not return a ReactDOMNode and this would stop the mainRef working
   */
  return (
    <Box component="main" sx={{ flexGrow: 1, padding: '60px 0' }} ref={mainRef}>
      <Container maxWidth="xl">
        <MainContent>{children}</MainContent>
      </Container>
    </Box>
  );
};

const MainContent = styled(Box)(({ theme }) => ({
  minHeight: 400,
  borderRadius: 20,
  background: theme.palette.common.white,
  padding: theme.spacing(3, 3),
  margin: theme.spacing(3, 0),
}));

export default Main;
