import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .root`]: {
    backgroundColor: theme.palette.common.white,
  }
}))

export default StyledTableRow;
