import { apiQuery } from "./apiHandler";
import utils from "@/utils/common";

const searchTacticalCampaigns = (
  pageSize,
  pageNumber,
  searchText,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "tacticalcampaigns/search?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&search=" +
      utils.autoWildcardSearchTerm(searchText);

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const apiTacticalCampaignsData = {
  searchTacticalCampaigns,
};

export default apiTacticalCampaignsData;
