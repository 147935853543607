import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "@/components/Form/Alert";
import AppError from "@/AppError";
import Approval from "@/components/Approval";
import Backdrop from "@/components/Backdrop";
import ContractOrders from "@/components/ContractDetails/Orders";
import DocumentButton from "@/components/ContractDetails/DocumentButton";
import MainDetails from "@/components/ContractDetails/MainDetails";
import PageActions from "@/components/PageActions";
import PagePoints from "@/components/PagePoints";
import Timeline from "@/components/ContractDetails/Timeline";
import { AppContext } from "@/context/AppContext";
import { ContractDetailsContext } from "@/context/ContractDetailsContext";
import { createContractMapper } from "@/mappers/createContractMapper";
import { updateContractMapper } from "@/mappers/updateContractMapper";
import { CONTRACT_DEFAULT_VALUES, ContractEntity } from "@/constants/forms";
import {
  BMW_CONTRACT,
  CONTRACT_SALESMODEL,
  CREATE,
  EDIT,
  SUCCESS,
  VIEW,
  canMarketEditContractKey,
} from "@/constants/constants";
import { contractRoute } from "@/constants/routes";
import { handleApiError } from "@/utils/handleApiError";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import { InfoPanelContract } from "@/components/InfoPanels/InfoPanelContract";
import { StyledPageBanner, StyledPageContainer, StyledPageMain, StyledPageSide } from "@/components/Styles/PagesComponentsCommon";
import { filterContractStatuses, setContRactPermissions } from "@/helperFunctions/contRacts";
import { useContractDetailsQueries, useContractInitialDataQueries } from "@/services/queries";
import { useCreateOrUpdateContRactMutation } from "@/services/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const ContractDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { t } = useTranslation();
  const { setBreadcrumbLevel3Text } = useContext(AppContext);
  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
    userData: { [canMarketEditContractKey]: canMarketEditAContract },
  } = useContext(AppContext);

  const {
    contractStatuses,
    setContractStatuses,
    setContractCategories,
    setSalesChannels,
    setUpfrontDiscountMatrixes,
  } = useContext(ContractDetailsContext);

  const [filteredStatuses, setFilteredStatuses] = useState(...contractStatuses);
  const [contract, setContract] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [mode, setMode] = useState(VIEW);
  const [sentForApproval, setSentForApproval] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [updateTimeline, setUpdateTimeline] = useState(false);
  const [disableTimelineNoteCreation, setdisableTimelineNoteCreation] =
    useState(false);
  const [allowedStatuses, setAllowedStatuses] = useState([]);

  const [apiError, setApiError] = useState({});

  const points =
    mode === VIEW
      ? [
        {
          name: t("contractDetailsPage.pagePoints.details"),
          url: "#details",
        },
        {
          name: t("contractDetailsPage.pagePoints.timeline"),
          url: "#timeline",
        },
      ]
      : [
        {
          name: t("contractDetailsPage.pagePoints.details"),
          url: "#details",
        },
      ];

  const contractInitialData = useContractInitialDataQueries();
  const contractDetailsQueries = useContractDetailsQueries(id, sentForApproval);
  const createOrUpdateMutation = useCreateOrUpdateContRactMutation();
  const isInitialDataFetched = contractInitialData.every(query => query.isFetched);
  const isUpdateOrCreateContRactPending = createOrUpdateMutation.isPending;
  const isContactDetailsFetching = contractDetailsQueries.some(query => query.isFetching);

  useEffect(() => {
    setIsLoading(contractInitialData.some(query => query.isFetching));
    if (contractInitialData.some(query => query.isError)) {
      let error = contractInitialData.some(query => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (isInitialDataFetched) {
      setContractCategories(contractInitialData[0].isSuccess ? contractInitialData[0].data?.data?.result : []);
      setContractStatuses(contractInitialData[1].isSuccess ? contractInitialData[1].data?.data?.result : []);
      setSalesChannels(contractInitialData[2].isSuccess ? contractInitialData[2].data?.data?.result : []);
      setUpfrontDiscountMatrixes(contractInitialData[3].isSuccess ? contractInitialData[3].data?.data?.result : []);

      let categoryArray = contractInitialData[0].data?.data?.result?.filter(
        (x) => x.name === CONTRACT_SALESMODEL,
      );
      if (categoryArray.length > 0) {
        let salesModel = categoryArray[0];
        setValue(ContractEntity.contractCategory, salesModel);
      }
      if (contractInitialData[4].isSuccess) {
        let statusesArray = contractInitialData[4].data?.data?.result?.stringValue
        statusesArray = statusesArray && JSON.parse(statusesArray).Status || [];
        setAllowedStatuses(statusesArray);
      }
    }
  }, [isInitialDataFetched]);

  useEffect(() => {
    setIsLoading(isContactDetailsFetching);
    if (isContactDetailsFetching) return;
    if (contractDetailsQueries.some(query => query.isError)) {
      let error = contractDetailsQueries.some(query => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (contractDetailsQueries.every(query => query.isSuccess)) {
      setContract(contractDetailsQueries[0].data?.data?.result);
      setBreadcrumbLevel3Text(contractDetailsQueries[0]?.data?.result?.contractName);
      let permissionToEdit = contractDetailsQueries[1].data;
      if (permissionToEdit.data.result) {
        const contract = contractDetailsQueries[0]?.data?.data?.result;
        const { isDisabled, ifContractIsEditable, disableTimelineNoteCreation } = setContRactPermissions(contract?.contractStatus?.name);
        setDisabled(isDisabled);
        ifContractIsEditable && canMarketEditAContract ? setIsEditable(true) : setIsEditable(false);
        setdisableTimelineNoteCreation(disableTimelineNoteCreation);
      } else {
        setIsEditable(false)
      }
    }
  }, [contractDetailsQueries[0].dataUpdatedAt, contractDetailsQueries[1].dataUpdatedAt], isContactDetailsFetching);

  useEffect(() => {
    setIsSaving(createOrUpdateMutation.isPending);
    if (createOrUpdateMutation.isError) {
      if (createOrUpdateMutation.isError) {
        let error = createOrUpdateMutation.error;
        setApiError(handleApiError(error));
      }
    }
    if (createOrUpdateMutation.isSuccess) {
      if (mode === EDIT) {
        createAlert(SUCCESS, t("contractDetailsPage.updateContractSuccessMessage"));
        queryClient.invalidateQueries({
          queryKey: [
            "contractDetails",
            { id: createOrUpdateMutation.variables[2] },
          ],
        });
        queryClient.invalidateQueries({
          queryKey: [
            "permissionToEditContract",
            { id: createOrUpdateMutation.variables[2] },
          ],
        });
        setMode(VIEW);
      } else {
        createAlert(SUCCESS, t("contractDetailsPage.createContractSuccessMessage"));
        queryClient.invalidateQueries({
          queryKey: [
            "bpData",
            { id: id },
          ],
        });
        // Redirects to the newly created contract and replaces the history state
        navigate(contractRoute + createOrUpdateMutation?.data?.data?.result?.id, {
          replace: true,
        });
        setMode(VIEW);
      }
    }
  }, [isUpdateOrCreateContRactPending])

  useEffect(() => {
    if (id) {
      // getContractDetail(id);
    } else {
      setMode(CREATE);
    }
  }, [id, mode, sentForApproval]);

  useEffect(() => {
    let filteredStatuses = filterContractStatuses(contract?.contractStatus?.name, allowedStatuses, contractStatuses);
    setFilteredStatuses(filteredStatuses);
  }, [contract, allowedStatuses, contractStatuses]);

  // eslint-disable-next-line no-unused-vars
  const { control, handleSubmit, resetField, setValue, watch, formState: { dirtyFields } } = useForm({
    values: contract ? {
      // ...contract,
      bmw_saleschannel: contract.salesChannel?.value,
      bmw_contractstatusglobaloptionset: contract.contractStatus?.value,
      bmw_exoticcontract: contract?.customised ? 20 : 10,
      bmw_signedbynsc: contract?.signedByNSC ? 20 : 10,
      bmw_accountid: contract.account?.name,
      ownerid: contract?.owner?.name,
      bmw_startdate: contract.startDate,
      bmw_endofcontract: contract.endDate,
      bmw_duration: contract.duration,
      bmw_contracttemplateid: contract.upfrontDiscount,
      bmw_terms_and_conditions: contract.contractAnnotation,
      bmw_datesigned: contract.dateSigned,
      bmw_datetosubmitclaimdocuments: contract.lastDateSubmitClaim,
      bmw_contractcategoryid: contract.contractCategory,
      bmw_lastorderdate: contract.lastCompletedOrder,
      bmw_lastagreementdate: contract.lastAgreementDate,
      bmw_totalcompletedorders: contract.totalCompletedOrders,
      bmw_bulkapprovalteam: contract.ownVehicleOrders,
      bmw_leasedvehicleorders: contract.leasedVehicleOrders,
      [ContractEntity.template]: contract.template || {},

    }
      : location.state ? {
        ...CONTRACT_DEFAULT_VALUES,
        bmw_accountid: location?.state?.account?.name,
      } : {
        ...CONTRACT_DEFAULT_VALUES
      },
  });

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  const saveUpdateContract = async (data) => {
    const mappedData =
      mode === EDIT
        ? updateContractMapper(
          id,
          contract?.contractName,
          contract?.contractId,
          contract?.marketCode,
          contract?.contractStatus?.value,
          contract?.account,
          data,
        )
        : createContractMapper(location?.state?.account, data);

    createOrUpdateMutation.mutate([mappedData, mode, id]);
  };

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      // controller.abort();
    };
  }, []);

  return (
    <>
      {contract && mode === VIEW && (
        <StyledPageBanner mt={2}>
          <InfoPanelContract {...contract} />
        </StyledPageBanner>
      )}

      <StyledPageContainer
        component={mode === VIEW ? "div" : "form"}
        mt={2}
        noValidate
        onSubmit={handleSubmit(saveUpdateContract)}
      >
        <StyledPageMain>
          {(contract || mode !== VIEW) && (
            <MainDetails
              contract={contract}
              control={control}
              disabled={disabled}
              filteredStatuses={filteredStatuses}
              mode={mode}
              resetField={resetField}
              watch={watch}
              setShowAlert={setShowAlert}
              setCanSave={setCanSave}
              setLoader={setIsLoading}
            />
          )}

          {mode === VIEW && (
            <>
              <ContractOrders contractId={id} />

              <Timeline
                id={id}
                setIsSaving={setIsSaving}
                setShowAlert={setShowAlert}
                updateTimeline={updateTimeline}
                setUpdateTimeline={setUpdateTimeline}
                disableCreateNote={disableTimelineNoteCreation}
              />
            </>
          )}
          <MultiElementFloatingActionBar>
            <PageActions
              entityType="contract"
              isEditable={isEditable}
              canSave={canSave}
              mode={mode}
              setMode={setMode}
            />
            {contract && mode === VIEW && (
              <DocumentButton
                contractStatus={contract?.contractStatus}
                documentTemplate={contract?.template}
                entity={BMW_CONTRACT}
                id={id}
                upfrontDiscount={contract?.upfrontDiscount}
                setShowAlert={setShowAlert}
                setIsLoading={setIsLoading}
                setUpdateTimeline={setUpdateTimeline}
              />
            )}
            {id && mode === VIEW && (
              <Approval
                id={id}
                entity={BMW_CONTRACT}
                sentForApproval={sentForApproval}
                setSentForApproval={setSentForApproval}
                setIsLoading={setIsLoading}
                setShowAlert={setShowAlert}
                updateTimeline={updateTimeline}
              />
            )}
          </MultiElementFloatingActionBar>
        </StyledPageMain>

        <StyledPageSide>
          <PagePoints points={points} backButton></PagePoints>
        </StyledPageSide>
      </StyledPageContainer>

      {(isLoading || isSaving) && <Backdrop text={isSaving && t("backdrop.saving")} />}

      {showAlert && alertStatus && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};