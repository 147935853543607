import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AppError from "@/AppError";
import { CONTRACT_PREPARATION, SUCCESS } from "@/constants/constants";
import { AppContext } from "@/context/AppContext";
import { handleApiError } from "@/utils/handleApiError";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useGenerateDocMutation } from "@/services/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const DocumentButton = ({
  contractStatus,
  documentTemplate,
  entity,
  id,
  upfrontDiscount,
  setShowAlert,
  setIsLoading,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setAlertStatus, setAlertMessage } = useContext(AppContext);
  const [disabled, setDisabled] = useState(true);
  const [apiError, setApiError] = useState({});

  const generateDocMutation = useGenerateDocMutation();

  useEffect(() => {
    // Sets whether the 'Generate Document' button is disabled or enabled for the user
    const setGenerateDocumentButtonVisibility = () => {
      if (
        contractStatus?.name === CONTRACT_PREPARATION &&
        documentTemplate &&
        upfrontDiscount
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

    setGenerateDocumentButtonVisibility();
  }, [contractStatus, documentTemplate, upfrontDiscount]);

  useEffect(() => {
    setIsLoading(generateDocMutation.isPending);
    if (generateDocMutation.isPending) return;
    if (generateDocMutation.isError) {
      setApiError(handleApiError(generateDocMutation.error));
    }
    if (generateDocMutation.isSuccess) {
      setShowAlert(true);
      setAlertStatus(SUCCESS);
      setAlertMessage(t('contractDetailsPage.documentGenerateSuccess'));
      queryClient.refetchQueries({ queryKey: ["timelineList", { id: id }] });
      queryClient.refetchQueries({ queryKey: ["getApprovalButtonVisibility", { id: id }] });
      queryClient.refetchQueries({ queryKey: ["getApprovalTypes", { id: id }] });
    }
  }, [generateDocMutation.status, generateDocMutation.isPending]);

  const sendDocumentToBeGenerated = async () => {
    generateDocMutation.mutate([id, entity, documentTemplate]);
  };

  return (
    <>
      <Button
        disabled={disabled}
        type="button"
        variant="outlined"
        endIcon={<InsertDriveFileOutlinedIcon />}
        onClick={sendDocumentToBeGenerated}
      >
        {t('formActions.generateDocument')}
      </Button>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default DocumentButton;
