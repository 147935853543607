import React from "react";
import TableRow from "@mui/material/TableRow";
import StyledHeaderCell from "@/components/BusinessPartner/StyledHeaderCell";

export const TableHeader = ({ data }) => {
  return (
    <TableRow>
      {data && (
        <>
          {data.map((header, index) => (
            <StyledHeaderCell key={index}>
              {header?.displayName}
            </StyledHeaderCell>
          ))}
        </>
      )}
    </TableRow>
  );
};
