import React, { useEffect, useState } from "react";
import { PANEL_PAGE_SIZE } from "@/constants/constants";
import apiBPData from "@/services/apiBusinessPartners";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Loader from "@/components/Loader";
import Paging from "@/components/Paging";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledContainer,
  StyledContents,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledPagination,
  StyledTbody,
  StyledTd,
  StyledTr,
} from "@/components/Styles/PagesComponentsCommon";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const DiscountCategories = ({ bpid, setPoints, isBpDetailsFetched }) => {
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [discountCats, setDiscountCats] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});

  const discountCategoriesDataQuery = useQuery({
    queryKey: ["discountcategories", [bpid, page]],
    queryFn: async ({ signal }) =>
      apiBPData.getBpDiscountCategories(bpid, PANEL_PAGE_SIZE, page, signal),
    enabled: Boolean(bpid) && Boolean(isBpDetailsFetched),
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (discountCategoriesDataQuery.isError) {
      setApiError(handleApiError(discountCategoriesDataQuery.error));
    }
    if (discountCategoriesDataQuery.isSuccess) {
      setDiscountCats(discountCategoriesDataQuery.data?.data?.result);
      setTotalPages(discountCategoriesDataQuery.data?.data?.totalPages);
      if (
        discountCategoriesDataQuery.data?.data?.result &&
        discountCategoriesDataQuery.data?.data?.result !== false
      ) {
        setPoints((current) =>
          current.filter((item) => {
            // remove Discount Categories link
            return item.name !== "Discount Categories";
          }),
        );
      }
    }
  }, [
    discountCategoriesDataQuery.dataUpdatedAt,
    discountCategoriesDataQuery.errorUpdatedAt,
  ]);

  useEffect(() => {
    return () => {
      // // queryClient.invalidateQueries();
    };
  }, []);

  const headings = [
    { heading: t("businessPartnerDetailPage.discountCategoriesPanel.tableHeadings.discountCategory"), isAction: false },
    { heading: t("businessPartnerDetailPage.discountCategoriesPanel.tableHeadings.discountCategoryType"), isAction: false },
    { heading: t("businessPartnerDetailPage.discountCategoriesPanel.tableHeadings.discountMatrix"), isAction: false },
  ];

  return (
    <>
      <StyledContainer id="discountcategories">
        <StyledHeader>
          <StyledContents>
            <StyledHeading component="h2" variant="h4">
              {t(
                "businessPartnerDetailPage.discountCategoriesPanel.sectionTitle",
              )}
            </StyledHeading>
          </StyledContents>
        </StyledHeader>
        <TableHeaders headings={headings}>
          {discountCategoriesDataQuery.isFetching && (
            <StyledTbody>
              {discountCategoriesDataQuery.isFetching && <Loader />}
            </StyledTbody>
          )}

          {discountCats.length > 0 ? (
            <StyledTbody>
              {discountCats?.map(
                (
                  { name, discountCategoryTypeName, discountMatrixName },
                  index,
                ) => (
                  <StyledTr key={index}>
                    <StyledTd>
                      <ConditionalTooltip title={name} value={name} />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={discountCategoryTypeName}
                        value={discountCategoryTypeName}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip
                        title={discountMatrixName}
                        value={discountMatrixName}
                      />
                    </StyledTd>
                  </StyledTr>
                ),
              )}
            </StyledTbody>
          ) : (
            <StyledNoResults>
              {t("businessPartnerDetailPage.discountCategoriesPanel.noResults")}
            </StyledNoResults>
          )}
        </TableHeaders>
        <StyledPagination>
          {totalPages > 1 && <Paging count={totalPages} page={page} />}
        </StyledPagination>
      </StyledContainer>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default DiscountCategories;
