import { BMW_ACCOUNT, CONTACT } from "@/constants/constants";
import apiUIData from "@/services/apiUI";
import { useTranslation } from "react-i18next";

export const getCountyList = async (countRyId) => {
  try {
    let response = await apiUIData.getCountyList(countRyId);
    return Promise.resolve(response?.data?.result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const validateCoinCheckContact = (
  contact,
  sellingNotAllowedMsg,
) => {
  let errorMessage = "";
  let showFields = true;

  if (
    contact?.coinCheckPerformed &&
    contact?.coinCheckResult?.value === 174640001
  ) {
    errorMessage = sellingNotAllowedMsg?.data?.result?.stringValue;
  }

  return { showFields, errorMessage };
};

export const isTypeRestrictedInPortal = (bpAccType, typeOptions) => {
  let selectedType = typeOptions?.find((type) => type.id === bpAccType?.id);
  return selectedType?.restrictedDealerPortal
}
export const isFleetContractAllowed = (bpAccType, typeOptions) => {
  let selectedType = typeOptions?.find((type) => type.id === bpAccType?.id);
  return selectedType?.fleetContractAllowed
}
export const permissionToEditContact = (retailerGroupIsOwningRetailer, canMarketEditAContact, typeOptions, type) => {
  let typeRestricted = isTypeRestrictedInPortal(type, typeOptions);
  let isEditable = false;
  if (canMarketEditAContact) {
    if (retailerGroupIsOwningRetailer && !typeRestricted) {
      isEditable = true;
    }
  }

  return isEditable;
};

export const isBPFetched = (businessPartner, id) => {
  return id ? Boolean(businessPartner && Object.keys(businessPartner)?.length) : true;
};

export const useCalenderStrings = () => {
  const { t } = useTranslation();
  return {
    lastDay: `[${t("businessPartnerDetailPage.infoPanel.calendarStrings.lastDay")}] HH:mm`,
    sameDay: `[${t("businessPartnerDetailPage.infoPanel.calendarStrings.sameDay")}] HH:mm`,
    lastWeek: `dddd [${t("businessPartnerDetailPage.infoPanel.calendarStrings.lastWeek")}] HH:mm`,
    sameElse: "DD/MM/YYYY",
  };
}

// Helper function for finding showCoinSection in typeOptions and returing it's value
export const getShowCoinFromTypes = (type, typeOptions) => {
  if (type && type.hasOwnProperty('showCoinSection')) return type.showCoinSection;
  return typeOptions.find(item => item.id === type.id)?.showCoinSection
}

// Helper function to check if coin section is to be shown in business partner and contact details page.
export const showCoin = (entity, type, typeOptions, coinRelevantFields, contactRoles, marketCode) => {
  let showCoinBool = type && typeOptions ? getShowCoinFromTypes(type, typeOptions) : false;
  if(entity === BMW_ACCOUNT) return showCoinBool

  if(entity === CONTACT) {
    let requiredRoles = coinRelevantFields?.marketContactRoles?.find(item => item.marketRoles?.markets?.includes(marketCode))?.marketRoles?.roles;
    if(!requiredRoles) return false;

    if(requiredRoles.some(role => contactRoles[role?.role?.field])) return showCoinBool;

    return false
  }
}
export const getShowGrayMarketFromTypes = (type, typeOptions) => {
  if (type && type.hasOwnProperty('showGreyMarketSection')) return type.showGreyMarketSection;
  return typeOptions.find(item => item.id === type.id)?.showGreyMarketSection
}
export const showGreySection =(typeOptions,type,manageGreyMarketConfig,marketCode) => {
  let showGreySection = type && typeOptions ? getShowGrayMarketFromTypes(type, typeOptions) : false;
  let isGreyMarket = manageGreyMarketConfig?.Markets?.includes(marketCode);
    if(!isGreyMarket) return false;
  return showGreySection;
}