import React from "react";
import Timeline from "@/components/Timeline";
import apiOrdersData from "@/services/apiOrders";
import { useTranslation } from "react-i18next";

const VerificationTimeline = ({
  id,
  orderRejectedComment,
  setUpdateTimeline,
  updateTimeline,
}) => {
  const { t } = useTranslation()
  return (
    < Timeline
      id={id}
      func={apiOrdersData.getOrderVerification}
      title={t("orderDetails.verficationTimeline.title")}
      orderRejectedComment={orderRejectedComment}
      setUpdateTimeline={setUpdateTimeline}
      updateTimeline={updateTimeline}
    />
  );
};

export default VerificationTimeline;
