const filters = [
  {
    name: "ordersPage.filters.noQuota",
    key: "No Quota",
  },
  {
    name: "ordersPage.filters.completed",
    key: "Completed",
  },
  {
    name: "ordersPage.filters.awaitingUpload",
    key: "Awaiting upload",
  },
  {
    name: "ordersPage.filters.withDealership",
    key: "With Dealership - Awaiting Upload",
  },
  {
    name: "ordersPage.filters.reUploads",
    key: "Re-uploads",
  },
  {
    name: "ordersPage.filters.approval",
    key: "Approval",
  },
  {
    name: "ordersPage.filters.declined",
    key: "Declined",
  },
];
export default filters;
