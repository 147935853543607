import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Layout from "@/components/Layout";
import theme from "@/styles/bmwTheme";

import { BusinessPartnersPage } from "@/pages/BusinessPartnersPage";
import { BusinessPartnerDetailsPage } from "@/pages/BusinessPartnerDetailsPage";
import { ContactDetailsPage } from "@/pages/ContactDetailsPage";
import { ContractDetailsPage } from "@/pages/ContractDetailsPage";
import { ContractsPage } from "@/pages/ContractsPage";
import { DashboardPage } from "@/pages/DashboardPage";
import { HelpPage } from "@/pages/HelpPage";
import { OrdersPage } from "@/pages/OrdersPage";
import { OrderDetailsPage } from "@/pages/OrderDetailsPage";
import { DiscountMatrixPage } from "@/pages/DiscountMatrixPage";
import { TacticalCampaignsPage } from "@/pages/TacticalCampaignsPage";
import { LogoutPage } from "@/pages/LogoutPage";
import { DunsSearchPage } from "@/pages/DunsSearchPage";

import { AppProvider } from "@/context/AppContext";
import { BusinessPartnersProvider } from "@/context/BusinessPartnersContext";
import { BusinessPartnerDetailsProvider } from "@/context/BusinessPartnerDetailsContext";
import { ContactDetailsProvider } from "@/context/ContactDetailsContext";
import { ContractsProvider } from "@/context/ContractsContext";
import { ContractDetailsProvider } from "@/context/ContractDetailsContext";
import { OrdersProvider } from "@/context/OrdersContext";
import { TacticalCampaignsProvider } from "@/context/TacticalCampaignsContext";

import {
  businessPartnerRoute,
  businessPartnersRoute,
  contactRoute,
  contractRoute,
  contractsRoute,
  createBusinessPartnerRoute,
  createContactRoute,
  createContractRoute,
  dunsSearchRoute,
  homeRoute,
  logoutRoute,
  orderRoute,
  ordersRoute,
  discountMatrixRoute,
  helpRoute,
  tacticalCampaignsRoute,
  esodunsSearchRoute,
  esoSearchRoute,
  businessPartnerRole,
} from "@/constants/routes";
import { EsoDunsSearchPage } from "@/pages/EsoDunsSearchPage";
import { EsoSearchPage } from "@/pages/EsoSearchPage";
import { BPRolesDetails } from "@/pages/BPRolesDetailsPage";

function AppMounted({ appData }) {
  return (
    <AppProvider>
      <BusinessPartnersProvider>
        <BusinessPartnerDetailsProvider>
          <ContractsProvider>
            <ContractDetailsProvider>
              <OrdersProvider>
                <ContactDetailsProvider>
                  <TacticalCampaignsProvider>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Routes>
                        <Route
                          path={homeRoute}
                          element={<Layout appData={appData} />}
                        >
                          <Route index element={<DashboardPage />} />
                          <Route
                            path={businessPartnersRoute}
                            element={<BusinessPartnersPage />}
                          />
                          <Route
                            path={`${businessPartnerRoute}:id`}
                            element={<BusinessPartnerDetailsPage />}
                          />
                          <Route
                            path={createBusinessPartnerRoute}
                            element={<BusinessPartnerDetailsPage />}
                          />
                          <Route
                            path={dunsSearchRoute}
                            element={<DunsSearchPage />}
                          />
                          <Route
                            path={esoSearchRoute}
                            element={<EsoSearchPage />}
                          />
                          <Route
                            path={esodunsSearchRoute}
                            element={<EsoDunsSearchPage />}
                          />
                          <Route
                            path={contractsRoute}
                            element={<ContractsPage />}
                          />
                          <Route
                            path={`${contractsRoute}:id`}
                            element={<ContractsPage />}
                          />
                          <Route
                            path={createContractRoute}
                            element={<ContractDetailsPage />}
                          />
                          <Route
                            path={`${contractRoute}:id`}
                            element={<ContractDetailsPage />}
                          />
                          <Route path={ordersRoute} element={<OrdersPage />} />
                          <Route
                            path={createContactRoute}
                            element={<ContactDetailsPage />}
                          />
                          <Route
                            path={`${contactRoute}:id`}
                            element={<ContactDetailsPage />}
                          />
                          <Route
                            path={`${orderRoute}:id`}
                            element={<OrderDetailsPage />}
                          />
                          <Route
                            path={`${discountMatrixRoute}:id`}
                            element={<DiscountMatrixPage />}
                          />
                          <Route
                            path={`${businessPartnerRole}:id`}
                            element={<BPRolesDetails />}
                          />
                          <Route
                            path={businessPartnerRole}
                            element={<BPRolesDetails />}
                          />
                          <Route
                            path={tacticalCampaignsRoute}
                            element={<TacticalCampaignsPage />}
                          />
                          <Route path={helpRoute} element={<HelpPage />} />
                          <Route path={logoutRoute} element={<LogoutPage />} />
                        </Route>
                      </Routes>
                    </ThemeProvider>
                  </TacticalCampaignsProvider>
                </ContactDetailsProvider>
              </OrdersProvider>
            </ContractDetailsProvider>
          </ContractsProvider>
        </BusinessPartnerDetailsProvider>
      </BusinessPartnersProvider>
    </AppProvider>
  );
}

export default AppMounted;
