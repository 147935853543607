import React, { useContext, useEffect, useState } from "react";
import AppError from "@/AppError";
import Filters from "@/components/Filters";
import PageTitle from "@/components/PageTitle";
import Paging from "@/components/Paging";
import Search from "@/components/Search";
import { filters } from "@/constants/contractFilters";
import { useContractTable } from "@/components/Contracts/Table";
import { ContractsContext } from "@/context/ContractsContext";
import { handleApiError } from "@/utils/handleApiError";
import DataGridTable from "@/components/DataGridTable/DataGridTable";
import { businessPartnerRoute, contractRoute, contractsRoute } from "@/constants/routes";
import { StyledPageHeader } from "@/components/Styles/PagesComponentsCommon";
import { useFetchContRacts } from "@/services/queries";
import { useDebounce } from "@/hooks/useDebounce";
import { useSearchParams } from "@/hooks/useSearchParams";
import { createSearchParams, useNavigate } from "react-router-dom";
import useFirstRender from "@/hooks/useFirstRender";
import { useTranslation } from "react-i18next";

export const ContractsPage = () => {

  const controller = new AbortController();

  const {
    contracts,
    setContracts,
    searchText,
    setSearchText,
    pageSize,
    totalPages,
    setTotalPages,
  } = useContext(ContractsContext);
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const contractColumns = useContractTable();
  const isFirstRender = useFirstRender();
  const [apiError, setApiError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [searched, setSearched] = useState(false);
  const debouncedSearch = useDebounce([searchText]);
  let page = searchParams.get("page") || 1;
  let quickFilter = searchParams.get("quickFilter")

  const searchContractsQuery = useFetchContRacts(searchText, quickFilter, page, debouncedSearch, pageSize);

  useEffect(() => {
    setIsLoading(searchContractsQuery.isFetching);
    if (searchContractsQuery.isError) {
      setApiError(handleApiError(searchContractsQuery.error))
    }
    if (searchContractsQuery.isSuccess) {
      let response = searchContractsQuery?.data?.data?.result || []
      response.forEach(row => {
        row["contractRoute"] = contractRoute;
        row["businessPartnerRoute"] = businessPartnerRoute;
      });
      setContracts(response || []);
      setTotalPages(searchContractsQuery?.data?.data?.totalPages);
      setSearched(true);
      setIsTyping(false);
    }
  }, [searchContractsQuery.dataUpdatedAt, searchContractsQuery.errorUpdatedAt]);

  useEffect(() => {
    if (!isFirstRender) {
      navigate({
        pathname: contractsRoute,
        search: `?${createSearchParams({
          page: 1,
          quickFilter: quickFilter
        })}`,
        replace: true
      })
    }
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  return (
    <>
      <StyledPageHeader>
        <PageTitle title={t("contractsPage.mainTitle")} />

        <Search
          id="contracts-search"
          label={t("contractsPage.searchLabel")}
          placeholder={t("contractsPage.searchPlaceholder")}
          searchText={searchText}
          setSearchText={setSearchText}
          setIsTyping={setIsTyping}
        />
      </StyledPageHeader>

      <Filters
        disabled={isLoading || isTyping}
        filters={filters}
        route={contractsRoute}
        title={t("contractsPage.quickFilters")}
      />
      <DataGridTable
        data={contracts}
        columns={contractColumns}
        isLoading={isLoading}
        searched={searched}
        page={0}
        paginationMode="client"
      />
      {totalPages > 1 && (
        <Paging count={totalPages} page={page} route={contractsRoute} />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
