import React from "react";
import { Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import TooltipConditionalWrapper from "@/components/TooltipConditionalWrapper";
import { createFilterOptions } from "@mui/material/Autocomplete";

const LookupController = ({
  control,
  disabled,
  label,
  options,
  name,
  required,
  title,
  disableClearable = false,
  testid,
  onLookUpChange,
  setInputValue,
  inputValue,
  setInputReason,
  loading = false,
  noOptionsText = "No options",
  shouldFilterManually = true
}) => {
  // Is options is a large data set? Filter manually.
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 20,
  });
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
        <TooltipConditionalWrapper
          condition={title}
          wrapper={(children) => <Tooltip title={title}>{children}</Tooltip>}
        >
          <Autocomplete
            disabled={disabled}
            classes={{
              endAdornment: {
                right: "14px !important",

                "&.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.38)",
                },
              },
            }}
            options={options || []}
            filterOptions={shouldFilterManually ? filterOptions : (x) => x}
            getOptionLabel={(option) =>
              option.name ? option.name : inputValue ? inputValue : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                data-testid={testid}
                label={label}
                error={!!error}
                required={required}
                helperText={error ? error.message : null}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={ref}
              />
            )}
            onChange={(event, values) => {
              onLookUpChange && onLookUpChange(values);
              onChange(values);
            }}
            value={value && Object.keys(value)?.length ? { ...value } : {}}
            disableClearable={disableClearable}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?.id}>
                  {option.retailerNumber && option.name
                    ? `${option.name} - ${option.retailerNumber}`
                    : option.name}
                </li>
              );
            }}
            noOptionsText={noOptionsText}
            onInputChange={(event, newInputValue, reason) => {
              setInputValue && setInputValue(newInputValue);
              setInputReason && setInputReason(reason);
            }}
            loadingText={"Loading..."}
            loading={loading}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </TooltipConditionalWrapper>
      )}
      rules={{
        required: required && "This field is required",
      }}
    />
  );
};

export default LookupController;
