import React from "react";
import ConditionalLink from "@/components/ConditionalLink";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";
import { useTranslation } from "react-i18next";

const sortDates = (v1, v2) => {
  if (v1 < v2) {
    return -1;
  } else if (v1 > v2) {
    return 1;
  } else if (v1 === "Invalid Date" && v2 !== "Invalid Date") {
    return -1;
  } else if (v1 !== "Invalid Date" && v2 === "Invalid Date") {
    return 1;
  } else {
    return 0;
  }
};
const useOrdersTable = () => {
  const { t } = useTranslation();
  return [
    {
      field: "orderNumber",
      headerName: t("ordersPage.dataTableHeaders.orderNumber"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 250,
      valueGetter: (params) => {
        if (!params.row.orderNumber) {
          return null;
        }
        return params.row.orderNumber;
      },
      renderCell: (params) => {
        if (!params.row.orderNumber) {
          return "--";
        }
        return (
          <ConditionalLink
            title={params.row.orderNumber}
            url={params.row.orderRoute + params.row.id}
          />
        );
      },
    },
    {
      field: "vin0-10",
      headerName: t("ordersPage.dataTableHeaders.vin0-10"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 150,
      valueGetter: (params) => {
        if (!params.row.viN010) {
          return null;
        }
        return params.row.viN010;
      },
      renderCell: (params) => {
        if (!params.row.viN010) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.viN010} />;
      },
    },
    {
      field: "vin11-17",
      headerName: t("ordersPage.dataTableHeaders.vin11-17"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 150,
      valueGetter: (params) => {
        if (!params.row.viN1117) {
          return null;
        }
        return params.row.viN1117;
      },
      renderCell: (params) => {
        if (!params.row.viN1117) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.viN1117} />;
      },
    },
    {
      field: "orderVerificationStatus",
      headerName: t("ordersPage.dataTableHeaders.orderVerificationStatus"),
      type: "string",
      headerClassName: "custom-header",
      flex: 2,
      maxWidth: 200,
      valueGetter: (params) => {
        if (!params.row.verificationStatus.name) {
          return null;
        }
        return params.row.verificationStatus.name;
      },
      renderCell: (params) => {
        if (!params.row.verificationStatus.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.verificationStatus.name} />;
      },
    },
    {
      field: "orderStatus",
      headerName: t("ordersPage.dataTableHeaders.orderStatus"),
      type: "number",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 150,
      valueGetter: (params) => {
        if (!params.row.orderStatus) {
          return null;
        }
        return params.row.orderStatus;
      },

      renderCell: (params) => {
        if (!params.row.orderStatus) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.orderStatus} />;
      },
    },
    {
      field: "orderingRetailer",
      headerName: t("ordersPage.dataTableHeaders.orderingRetailer"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 150,
      valueGetter: (params) => {
        if (!params.row.orderingRetailer.name) {
          return null;
        }
        return params.row.orderingRetailer.name;
      },
      renderCell: (params) => {
        if (!params.row.orderingRetailer.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.orderingRetailer.name} />;
      },
    },
    {
      field: "keeper",
      headerName: t("ordersPage.dataTableHeaders.keeper"),
      type: "string",
      headerClassName: "custom-header",
      flex: 1.5,
      maxWidth: 150,
      valueGetter: (params) => {
        if (!params.row.keeper?.name) {
          return null;
        }
        return params.row.keeper?.name;
      },
      renderCell: (params) => {
        if (!params.row.keeper?.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.keeper?.name} />;
      },
    },
    {
      field: "modelCode",
      headerName: t("ordersPage.dataTableHeaders.modelCode"),
      type: "string",
      headerClassName: "custom-header",
      flex: 2.5,
      maxWidth: 250,
      valueGetter: (params) => {
        if (!params.row.modelCode.name) {
          return null;
        }
        return params.row.modelCode?.name;
      },
      renderCell: (params) => {
        if (!params.row.modelCode.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.modelCode?.name} />;
      },
    },
    {
      field: "agreementDate",
      headerName: t("ordersPage.dataTableHeaders.agreementDate"),
      type: "date",
      headerClassName: "custom-header",
      flex: 2.5,
      maxWidth: 150,
      sortComparator: sortDates,
      valueGetter: (params) => {
        if (!params.row.agreementDate) {
          return null;
        }
        return new Date(params.row.agreementDate);
      },
      renderCell: (params) => {
        if (!params.row.agreementDate) {
          return "--";
        }
        return (
          <ConditionalTooltip
            title={
              params.row.agreementDate &&
              convertISODateToDayMonthYear(params.row.agreementDate)
            }
          />
        );
      },
    },
    {
      field: "deliveryDate",
      headerName: t("ordersPage.dataTableHeaders.deliveryDate"),
      type: "date",
      headerClassName: "custom-header",
      flex: 2.5,
      maxWidth: 150,
      sortComparator: sortDates,
      valueGetter: (params) => {
        if (!params.row.deliveryDate) {
          return null;
        }
        return new Date(params.row.deliveryDate);
      },
      renderCell: (params) => {
        if (!params.row.deliveryDate) {
          return "--";
        }
        return (
          <ConditionalTooltip
            title={
              params.row.deliveryDate &&
              convertISODateToDayMonthYear(params.row.deliveryDate)
            }
          />
        );
      },
    },
    {
      field: "invoiceDate",
      headerName: t("ordersPage.dataTableHeaders.invoiceDate"),
      type: "date",
      headerClassName: "custom-header",
      flex: 2.5,
      maxWidth: 150,
      sortComparator: sortDates,
      valueGetter: (params) => {
        if (!params.row.invoiceDate) {
          return null;
        }
        return new Date(params.row.invoiceDate);
      },
      renderCell: (params) => {
        if (!params.row.invoiceDate) {
          return "--";
        }
        return (
          <ConditionalTooltip
            title={
              params.row.invoiceDate &&
              convertISODateToDayMonthYear(params.row.invoiceDate)
            }
          />
        );
      },
    },
  ];
};

export default useOrdersTable
