import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Loader from "@/components/Loader";
import Paging from "@/components/Paging";
import { PANEL_PAGE_SIZE } from "@/constants/constants";
import apiConnectionsData from "@/services/apiConnections";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledContainer,
  StyledHeader,
  StyledContents,
  StyledHeading,
  StyledTr,
  StyledTbody,
  StyledTd,
  StyledNoResults,
  StyledPagination,
} from "@/components/Styles/PagesComponentsCommon";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const Connections = ({ bpid, isBpDetailsFetched }) => {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1
  const [connections, setConnections] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});
  const headings = [
    { heading: t("businessPartnerDetailPage.connectionsPanel.tableHeadings.connectedFrom"), isAction: false },
    { heading: t("businessPartnerDetailPage.connectionsPanel.tableHeadings.connectedTo"), isAction: false },
    { heading: t("businessPartnerDetailPage.connectionsPanel.tableHeadings.role"), isAction: false },
    { heading: t("businessPartnerDetailPage.connectionsPanel.tableHeadings.startDate"), isAction: false },
    { heading: t("businessPartnerDetailPage.connectionsPanel.tableHeadings.endDate"), isAction: false },
  ];
  const connectionsListQuery = useQuery({
    queryKey: ["connections", [bpid, page]],
    queryFn: async ({ signal }) =>
      apiConnectionsData.searchConnectionsByBP(
        bpid,
        PANEL_PAGE_SIZE,
        page,
        signal,
      ),
    enabled: Boolean(bpid) && isBpDetailsFetched,
  });

  useEffect(() => {
    if (connectionsListQuery.isError) {
      setApiError(handleApiError(connectionsListQuery.error));
    }
    if (connectionsListQuery.isSuccess) {
      setConnections(connectionsListQuery.data?.data?.result);
      setTotalPages(connectionsListQuery.data?.data?.totalPages);
    }
  }, [connectionsListQuery.dataUpdatedAt, connectionsListQuery.errorUpdatedAt]);

  useEffect(() => {
    return () => {
      // queryClient.invalidateQueries();
    };
  }, []);
  return (
    <>
      <StyledContainer id="connections">
        <Box>
          <StyledHeader>
            <StyledContents>
              <StyledHeading component="h2" variant="h4">
                {t("businessPartnerDetailPage.connectionsPanel.sectionTitle")}
              </StyledHeading>
            </StyledContents>
          </StyledHeader>
        </Box>
        <TableHeaders headings={headings}>
        {connectionsListQuery.isFetching && (
              <StyledTbody>
                {connectionsListQuery.isFetching && <Loader />}
              </StyledTbody>
            )}

            {connections.length > 0 ? (
              <StyledTbody>
                {connections?.map(
                  (
                    { connectedTo, connectedFrom, role, startDate, endDate },
                    index,
                  ) => (
                    <StyledTr key={index}>
                      <StyledTd>
                        <ConditionalTooltip
                          title={connectedTo?.name}
                          value={connectedTo?.name}
                        />
                      </StyledTd>

                      <StyledTd>
                        <ConditionalTooltip
                          title={connectedFrom?.name}
                          value={connectedFrom?.name}
                        />
                      </StyledTd>

                      <StyledTd>
                        <ConditionalTooltip
                          title={role?.name}
                          value={role?.name}
                        />
                      </StyledTd>

                      <StyledTd>
                        <ConditionalTooltip
                          title={
                            startDate && convertISODateToDayMonthYear(startDate)
                          }
                          value={
                            startDate && convertISODateToDayMonthYear(startDate)
                          }
                        />
                      </StyledTd>

                      <StyledTd>
                        <ConditionalTooltip
                          title={
                            endDate && convertISODateToDayMonthYear(endDate)
                          }
                          value={
                            endDate && convertISODateToDayMonthYear(endDate)
                          }
                        />
                      </StyledTd>
                    </StyledTr>
                  ),
                )}
              </StyledTbody>
            ) : (
              <StyledNoResults>
                {t("businessPartnerDetailPage.connectionsPanel.noResults")}
              </StyledNoResults>
            )}
        </TableHeaders>

        <StyledPagination>
          {totalPages > 1 && (
            <Paging count={totalPages} page={page} />
          )}
        </StyledPagination>
      </StyledContainer>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default Connections;
