import React from "react";
import { Link } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const BackButton = (props) => {
  const { version } = props;
  const { t } = useTranslation();

  if (version === "outlined") {
    return (
      <OutlinedButton
        to={-1}
        component={Link}
        fullWidth={true}
        startIcon={null}
        variant="outlined"
      >
        {t("backButton.back")}
      </OutlinedButton>
    );
  } else {
    return (
      <Button
        to={-1}
        component={Link}
        fullWidth={false}
        startIcon={<ArrowBackOutlinedIcon />}
        variant="text"
      >
        {t("backButton.back")}
      </Button>
    );
  }
};

const OutlinedButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.dark,
  color: theme.palette.common.white,
  "&:hover": {
    background: theme.palette.common.dark,
    border: 0,
  },
}));

export default BackButton;
