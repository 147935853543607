const dataURLtoFile = (dataURI, filename, mimeType) => {
  // @TODO - Added conditional check for missing data:application attribute from
  // Contracts and Business Partner timelines, might need some backend updates.
  if (!dataURI.split(",")[1]) {
    dataURI = `data:@file/${mimeType};base64,` + dataURI;
  }

  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  return new File([blob], filename);
};

export default dataURLtoFile;
