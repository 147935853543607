import React from "react";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { StyledDMorTCTableContainer, StyledDMorTCSeparator, StyledDMorTCTable, StyledDMorTCTableHeading } from "@/components/Styles/PagesComponentsCommon";
import { useTranslation } from "react-i18next";

const DiscountMatrixLineItems = ({ items }) => {
  const valueType = items?.ValueType;
  const { t } = useTranslation();
  return (
    <StyledDMorTCTableContainer
      id="discountMatrixLineItems"
      component={Paper}
    >
      <StyledDMorTCTable aria-label="Discount matrix line items">
        <Typography component="caption">
          {t("discountMatrixPage.discountMatrixLineItems.sectionTitle")}
        </Typography>

        <TableHead>
          <TableRow>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.displayOrder")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.name")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.group")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.dealerSupport")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.nscSupport")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.totalDiscount")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.model")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.fuelType")}
              </StyledDMorTCTableHeading>
            </TableCell>
            <TableCell>
              <StyledDMorTCTableHeading>
                {t("discountMatrixPage.discountMatrixLineItems.tableHeadings.optionsCount")}
              </StyledDMorTCTableHeading>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items?.data?.length > 0 ? (
            items?.data?.map(
              (
                {
                  displayOrder,
                  name,
                  group,
                  dealerSupport,
                  nscSupport,
                  totalDiscount,
                  model,
                  fuelType,
                  optionsCount,

                },
                index,
              ) => (
                <TableRow key={index}>
                  <TableCell>{displayOrder}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{group}</TableCell>
                  <TableCell>{valueType === 'Percentage' ? dealerSupport.toFixed(2) + '%' : dealerSupport.toFixed(2)}</TableCell>
                  <TableCell>{valueType === 'Percentage' ? nscSupport.toFixed(2) + '%' : nscSupport.toFixed(2)}</TableCell>
                  <TableCell>{valueType === 'Percentage' ? totalDiscount.toFixed(2) + '%' : totalDiscount.toFixed(2)}</TableCell>
                  <TableCell>{model?.name}</TableCell>
                  <TableCell>{fuelType?.name}</TableCell>
                  <TableCell>{optionsCount}</TableCell>
                </TableRow>
              ),
            )
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={9}>
                {t("discountMatrixPage.discountMatrixLineItems.noResults")}
              </TableCell>
            </TableRow>
          )}
          <StyledDMorTCSeparator />
        </TableBody>
      </StyledDMorTCTable>
    </StyledDMorTCTableContainer>
  );
};

export default DiscountMatrixLineItems;
