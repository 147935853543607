import React, { createContext, useState } from "react";

const BusinessPartnersContext = createContext({});

const BusinessPartnersProvider = ({ children }) => {
  const [businessPartners, setBusinessPartners] = useState([]);
  const [businessPartner, setBusinessPartner] = useState({});
  const [nameSearch, setNameSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [postcodeSearch, setPostcodeSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [companyCodesSearch, setCompanyCodesSearch] = useState("");
  const [isDunsMarket, setIsDunsMarket] = useState(false);
  const [canCreateBusinessPartner, setCanCreateBusinessPartner] =
    useState(false);
  const [isEsoSearchEnabled, setIsEsoSearchEnabled] = useState(false);
  const [esoSearchFormFields, setEsoSearchFormFields] = useState([]);
  const [esoSearchColumns, setEsoSearchColumns] = useState([]);
  const [vatNumberSearch, setVatNumberSearch] = useState("");
  const [esoCountryOptions, setEsoCountryOptions] = useState([]);

  return (
    <BusinessPartnersContext.Provider
      value={{
        businessPartner,
        setBusinessPartner,
        businessPartners,
        setBusinessPartners,
        nameSearch,
        setNameSearch,
        citySearch,
        setCitySearch,
        postcodeSearch,
        setPostcodeSearch,
        companyCodesSearch,
        setCompanyCodesSearch,
        searchTerm,
        setSearchTerm,
        isDunsMarket,
        setIsDunsMarket,
        canCreateBusinessPartner,
        setCanCreateBusinessPartner,
        isEsoSearchEnabled,
        setIsEsoSearchEnabled,
        esoSearchFormFields,
        setEsoSearchFormFields,
        esoSearchColumns,
        setEsoSearchColumns,
        vatNumberSearch,
        setVatNumberSearch,
        esoCountryOptions,
        setEsoCountryOptions,
      }}
    >
      {children}
    </BusinessPartnersContext.Provider>
  );
};

export { BusinessPartnersContext, BusinessPartnersProvider };
