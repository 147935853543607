import React from "react";
import TableRow from "@mui/material/TableRow";
import StyledHeaderCell from "@/components/BusinessPartner/StyledHeaderCell";
import { useTranslation } from "react-i18next";

const DUNSTableHeader = () => {
  const nameColumnWidth = 260;
  const { t } = useTranslation();
  return (
    <TableRow>
       <StyledHeaderCell width={nameColumnWidth}>{t("dunsSearchPage.datagridColumns.column1")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column2")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column3")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column4")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column5")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column6")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column7")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column8")}</StyledHeaderCell>
      <StyledHeaderCell>{t("dunsSearchPage.datagridColumns.column9")}</StyledHeaderCell>
    </TableRow>
  );
};

export default DUNSTableHeader;
