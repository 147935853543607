import React, { useContext, useEffect, useState } from "react";
import AppError from "@/AppError";
import Dialog from "@/components/Dialog";
import Dropzone from "@/components/Form/Dropzone";
import {
  BMW_CONTRACT,
  SUCCESS,
  userNameKey,
  userQnumberKey,
} from "@/constants/constants";
import { AppContext } from "@/context/AppContext";
import utils from "@/utils/common";
import apiContractsData from "@/services/apiContracts";
import { handleApiError } from "@/utils/handleApiError";
import { StyledField, StyledTextarea } from "@/components/Styles/PagesComponentsCommon";
import { useTranslation } from "react-i18next";

const TimelineDialog = ({
  id,
  open,
  setOpen,
  setIsSaving,
  setShowAlert,
  setUpdateTimeline,
}) => {
  // const classes = useStyles();

  const {
    setAlertStatus,
    setAlertMessage,
    userData: { [userQnumberKey]: userQNumber },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [base64File, setBase64File] = useState(null);
  const [comment, setComment] = useState("");
  const [errorTitle, setErrorTitle] = useState(false);
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [apiError, setApiError] = useState({});

  const acceptedFiles = ["application/pdf"];
  const errorMessage = t("timelineDialog.errorMessage");
  const filesLimit = 1;

  useEffect(() => {
    // Clears the error when title is updated.
    if (title) {
      setErrorTitle(false);
    }
  }, [title]);

  const clearForm = () => {
    setBase64File(null);
    setComment("");
    setErrorTitle(false);
    setFiles([]);
    setTitle("");
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const validateForm = () => {
    if (title) {
      setErrorTitle(false);
    } else {
      setErrorTitle(true);
    }
  };

  const createContractTimelineNote = async () => {
    validateForm();

    // Append username and C number to end of comment
    const appendedComment =t("timelineDialog.appendedComment", { comment: comment, userName: utils.secureGetFromLocalStorage(userNameKey), cNumber: userQNumber })

    if (title) {
      setIsSaving(true);

      const data = {
        title: title,
        attachmentOrComments: appendedComment,
        documentBody: base64File
          ? base64File?.substr(base64File.indexOf(",") + 1)
          : null,
        fileName: files.length > 0 ? files[0]?.name : null,
        mimeType: files.length > 0 ? files[0]?.type : null,
        regarding: {
          id: id,
          logicalName: BMW_CONTRACT,
        },
      };

      await apiContractsData
        .createContractTimelineNote(data)
        .then(() => {
          setIsSaving(false);
          setApiError({});
          handleClose();
          setShowAlert(true);
          setAlertStatus(SUCCESS);
          setAlertMessage(t("timelineDialog.alertMessage"));
          setUpdateTimeline(true);
        })
        .catch((err) => {
          setIsSaving(false);
          setApiError(handleApiError(err));
        });
    }
  };

  return (
    <>
      <Dialog
        confirmationButtonText={t("formActions.create")}
        handleClose={handleClose}
        handler={createContractTimelineNote}
        id="createContractNote"
        open={open}
        prompt={t("timelineDialog.prompt")}
        title={t("timelineDialog.contractTitle")}
      >
        <StyledField
          label={t("timelineDialog.dialogLabel")}
          error={!!errorTitle}
          required
          value={title}
          helperText={errorTitle ? errorMessage : null}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />

        <StyledTextarea
          label={t("timelineDialog.textAreaLabel")}
          value={comment}
          onChange={(event) => {
            setComment(event.target.value);
          }}
          multiline
          rows={6}
        />

        <Dropzone
          acceptedFiles={acceptedFiles}
          files={files}
          filesLimit={filesLimit}
          setBase64File={setBase64File}
          setFiles={setFiles}
        />
      </Dialog>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default TimelineDialog;
