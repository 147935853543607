import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import DUNSTableHeader from "@/components/DUNS/DUNSTableHeader";
import DUNSResultsListRow from "@/components/DUNS/DUNSResultsListRow";

const DUNSResultsList = ({
  data,
  createBusinessPartnerFromDuns,
  canMarketCreateBPViaDuns,
}) => (
  <TableContainer>
    <Table>
      <TableBody>
        {data && (
          <>
            <DUNSTableHeader />

            {data.map((item) => (
              <DUNSResultsListRow
                key={`item-${item.id}`}
                item={item}
                createBusinessPartnerFromDuns={createBusinessPartnerFromDuns}
                canMarketCreateBPViaDuns={canMarketCreateBPViaDuns}
              />
            ))}
          </>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default DUNSResultsList;
