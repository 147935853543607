import React, { useContext } from "react";
import { styled } from '@mui/material/styles';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AppContext } from "@/context/AppContext";
import Breadcrumb from "./Breadcrumb";
import { DRAWER_WIDTH_OPEN, DRAWER_WIDTH_CLOSED } from "@/constants/constants";
import RRSVG from "@/assets/images/rolls-royce-logo.svg";
import MiniSVG from "@/assets/images/mini-logo.svg"
import BMWSVG from "@/assets/images/bmw-logo.svg"
import AvatarSVG from "@/assets/images/avatar.svg";
import { useTranslation } from "react-i18next";

const Header = ({ open }) => {
  const { t } = useTranslation();
  const { headerRef } = useContext(AppContext);

  return (
    <StyledAppBar
      elevation={0}
      position="fixed"
      ref={headerRef}
      open={open}
    >
      <StyledToolBar>
        <MainPageTitle component="h1" variant="h3">
          NKAM {t("header.mainHeading")}
        </MainPageTitle>

        <Actions>
          <StyledAvatar alt="Default avatar" name="Default avatar"><img src={AvatarSVG} alt="avatar" /></StyledAvatar>
          <BmwLogo width={28} height={28}><img src={BMWSVG} alt="BMW Logo"
            style={{
              objectFit: 'cover',
              transitionProperty: 'opacity',
              transitionDuration: '1500ms',
              transitionTimingFunction: 'cubic-bezier(0.7, 0, 0.6, 1)',
              opacity: 1,
              animation: '3000ms cubic-bezier(0.7, 0, 0.6, 1) 0s 1 normal none running materialize',
            }}
          /></BmwLogo>
          <MiniLogo><img src={MiniSVG} alt="Mini logo"
            style={{
              objectFit: 'cover',
              transitionProperty: 'opacity',
              transitionDuration: '1500ms',
              transitionTimingFunction: 'cubic-bezier(0.7, 0, 0.6, 1)',
              opacity: 1,
              animation: '3000ms cubic-bezier(0.7, 0, 0.6, 1) 0s 1 normal none running materialize',
            }}
          /></MiniLogo>
          <RRLogo width={77} height={20} ><img src={RRSVG} alt="Rolls Royce logo"
            style={{
              objectFit: 'cover',
              transitionProperty: 'opacity',
              transitionDuration: '1500ms',
              transitionTimingFunction: 'cubic-bezier(0.7, 0, 0.6, 1)',
              opacity: 1,
              animation: '3000ms cubic-bezier(0.7, 0, 0.6, 1) 0s 1 normal none running materialize',
            }}
          /></RRLogo>
        </Actions>
      </StyledToolBar>
      <Breadcrumb />
    </StyledAppBar>
  );
};
const StyledAppBar = styled(AppBar)(({ theme, open }) => ({
  marginLeft: open ? `${DRAWER_WIDTH_OPEN}px` : `${DRAWER_WIDTH_CLOSED}px`,
  width: open ? `calc(100% - ${DRAWER_WIDTH_OPEN}px)` : `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: "#f1f6fa",
  color: "#333333",
}))
const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  justifyContent: "space-between",
}));
const MainPageTitle = styled(Typography)(() => ({
  fontWeight: 700,
}));
const StyledAvatar = styled(Box)(() => ({
  height: 30,
  width: 30,
  marginRight: 5,
  display: 'inline-block',
  "& img": {
    width: "100%",
    height: "100%"
  }
}));
const BmwLogo = styled(Box)(() => ({
  height: 28,
  width: 28,
  marginRight: 5,
  display: 'inline-block',
  '& img': {
    width: '100%',
    height: '100%',
  }
}))
const MiniLogo = styled(Box)(() => ({
  width: 44,
  height: 19,
  display: 'inline-block',
  marginRight: 5,
  '& img': {
    width: '100%',
    height: '100%',
  }
}));
const RRLogo = styled(Box)(() => ({
  width: 77,
  height: 19,
  display: 'inline-block',
  '& img': {
    width: '100%',
    height: '100%',
  }
}))
const Actions = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}))

export default Header;
