import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Loader from "@/components/Loader";
import Paging from "@/components/Paging";
import { PANEL_PAGE_SIZE } from "@/constants/constants";
import apiRetailersData from "@/services/apiRetailers";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledContainer,
  StyledContents,
  StyledDescription,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledNote,
  StyledNoteInfo,
  StyledNoteTitle,
  StyledNotes,
  StyledPagination,
  StyledTbody,
  StyledTd,
  StyledTr,
} from "@/components/Styles/PagesComponentsCommon";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const DesignatedRetailers = ({
  bpid,
  notes,
  permissions,
  isBpDetailsFetched,
}) => {
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [designatedRetailers, setDesignatedRetailers] = useState([]);
  const [showDesignatedRetailers, setShowDesignatedRetailers] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});

  const designatedRetailersDataQuery = useQuery({
    queryKey: ["designatedRetailers", [bpid, page]],
    queryFn: async ({ signal }) =>
      apiRetailersData.searchRetailersByBP(bpid, PANEL_PAGE_SIZE, page, signal),
    enabled: Boolean(bpid) && Boolean(isBpDetailsFetched),
  });
  const { t } = useTranslation();
  useEffect(() => {
    if (designatedRetailersDataQuery.isError) {
      setApiError(handleApiError(designatedRetailersDataQuery.error));
    }
    if (designatedRetailersDataQuery.isSuccess) {
      setDesignatedRetailers(designatedRetailersDataQuery.data?.data?.result);
      setTotalPages(designatedRetailersDataQuery.data?.data?.totalPages);
    }
  }, [
    designatedRetailersDataQuery.dataUpdatedAt,
    designatedRetailersDataQuery.errorUpdatedAt,
  ]);

  useEffect(() => {
    if (permissions) {
      visibilityOfDesignatedRetailers();
    }
  }, [permissions]);

  const visibilityOfDesignatedRetailers = () => {
    const {
      agencyModelRestricted,
      designatedRetailer,
      owningRetailer,
      retailerGroupIsDesignatedRetailer,
      retailerGroupIsOwningRetailer,
    } = permissions;

    if (
      !agencyModelRestricted ||
      (agencyModelRestricted &&
        (owningRetailer ||
          retailerGroupIsOwningRetailer ||
          designatedRetailer ||
          retailerGroupIsDesignatedRetailer))
    ) {
      setShowDesignatedRetailers(true);
    } else {
      setShowDesignatedRetailers(false);
    }
  };

  useEffect(() => {
    return () => {
      // queryClient.invalidateQueries();
    };
  }, []);

  const headings = [
    { heading: t("businessPartnerDetailPage.designatedRetailersPanel.tableHeadings.name"), isAction: false },
    { heading: t("businessPartnerDetailPage.designatedRetailersPanel.tableHeadings.town"), isAction: false },
    { heading: t("businessPartnerDetailPage.designatedRetailersPanel.tableHeadings.retailerNumber"), isAction: false },
    { heading: t("businessPartnerDetailPage.designatedRetailersPanel.tableHeadings.legalEntityNo"), isAction: false },
    { heading: t("businessPartnerDetailPage.designatedRetailersPanel.tableHeadings.legalEntityName"), isAction: false },
  ];

  return (
    <>
      <StyledContainer id="designatedRetailers">
        <Box>
          <StyledHeader>
            <StyledContents>
              <StyledHeading component="h2" variant="h4">
                {t(
                  "businessPartnerDetailPage.designatedRetailersPanel.sectionTitle",
                )}
              </StyledHeading>

              <StyledDescription component="span" variant="body2">
                {t(
                  "businessPartnerDetailPage.designatedRetailersPanel.description",
                )}
              </StyledDescription>
            </StyledContents>
          </StyledHeader>

          <StyledNotes>
            <StyledNote>
              <StyledNoteTitle component="p" variant="body2">
                {t(
                  "businessPartnerDetailPage.designatedRetailersPanel.offeringNotes",
                )}
              </StyledNoteTitle>

              <StyledNoteInfo component="span" variant="body2">
                {notes ? notes : "--"}
              </StyledNoteInfo>
            </StyledNote>
          </StyledNotes>
        </Box>

        {showDesignatedRetailers && (
          <>
            <TableHeaders headings={headings}>
              {designatedRetailers.length > 0 ? (
                <StyledTbody>
                  {designatedRetailersDataQuery.isFetching && <Loader />}

                  {designatedRetailers?.map(
                    (
                      {
                        name,
                        town,
                        retailerNumber,
                        legalEntityNumber,
                        legalEntityName,
                      },
                      index,
                    ) => (
                      <StyledTr key={index}>
                        <StyledTd>
                          <ConditionalTooltip title={name} value={name} />
                        </StyledTd>

                        <StyledTd>
                          <ConditionalTooltip title={town} value={town} />
                        </StyledTd>

                        <StyledTd>
                          <ConditionalTooltip
                            title={retailerNumber}
                            value={retailerNumber}
                          />
                        </StyledTd>

                        <StyledTd>
                          <ConditionalTooltip
                            title={legalEntityNumber}
                            value={legalEntityNumber}
                          />
                        </StyledTd>

                        <StyledTd>
                          <ConditionalTooltip
                            title={legalEntityName}
                            value={legalEntityName}
                          />
                        </StyledTd>
                      </StyledTr>
                    ),
                  )}
                </StyledTbody>
              ) : (
                <StyledNoResults>
                  {t(
                    "businessPartnerDetailPage.designatedRetailersPanel.noResults",
                  )}
                </StyledNoResults>
              )}
            </TableHeaders>

            <StyledPagination>
              {totalPages > 1 && <Paging count={totalPages} page={page} />}
            </StyledPagination>
          </>
        )}
      </StyledContainer>
      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default DesignatedRetailers;
