import React from "react";

import Moment from "react-moment";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { StyledInfoPanel, StyledInfoPanelGrid, StyledInfoPanelSubTitle, StyledInfoPanelTitle, StyledInfoPanelValue, StyledInfoPanelValueTitle } from "./panelStyles";
import { useTranslation } from "react-i18next";

export const InfoPanelContact = ({
  firstName,
  lastName,
  modifiedOn,
  jobTitle,
  jobFunction,
  parentCustomer,
}) => {
  const { t } = useTranslation();
  const calendarStrings = {
    lastDay: t("contactDetailsPage.infoPanel.calendarStrings.lastDay"),
    sameDay: t("contactDetailsPage.infoPanel.calendarStrings.sameDay"),
    lastWeek: t("contactDetailsPage.infoPanel.calendarStrings.lastWeek"),
    sameElse: t("contactDetailsPage.infoPanel.calendarStrings.sameElse"),
  };
  return (
    <StyledInfoPanel py={4} px={2}>
      <Grid container spacing={2}>
        <StyledInfoPanelGrid item lg >
          <Box sx={{
            width: "218px",
            padding: "0 26px 0 0 !important",
          }}>
            <StyledInfoPanelTitle component="h1" variant="h1">
              {firstName} {lastName}
            </StyledInfoPanelTitle>
            <StyledInfoPanelSubTitle
              component="h2"
              variant="h2"
            >
              {t("contactDetailsPage.infoPanel.lastUpdated")}:{" "}
              <Moment calendar={calendarStrings}>{modifiedOn}</Moment>
            </StyledInfoPanelSubTitle>
          </Box>
        </StyledInfoPanelGrid>
        <StyledInfoPanelGrid item md >
          <StyledInfoPanelValueTitle
            component="h3"
            variant="h3"
          >
            {t("contactDetailsPage.infoPanel.jobTitle")}
          </StyledInfoPanelValueTitle>
          <StyledInfoPanelValue component="p">
            {jobTitle}
          </StyledInfoPanelValue>
        </StyledInfoPanelGrid>
        <StyledInfoPanelGrid item md >
          <StyledInfoPanelValueTitle
            component="h3"
            variant="h3"
          >
            {t("contactDetailsPage.infoPanel.jobFunction")}
          </StyledInfoPanelValueTitle>
          <StyledInfoPanelValue component="p">
            {jobFunction?.name}
          </StyledInfoPanelValue>
        </StyledInfoPanelGrid>
        <Grid item md sx={{
          padding: "0 0 0 26px !important",
        }}>
          <StyledInfoPanelValueTitle
            component="h3"
            variant="h3"
          >
            {t("contactDetailsPage.infoPanel.accountName")}
          </StyledInfoPanelValueTitle>
          <StyledInfoPanelValue component="p">
            {parentCustomer?.name}
          </StyledInfoPanelValue>
        </Grid>
      </Grid>
    </StyledInfoPanel>
  );
};
