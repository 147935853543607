const createContactMapper = ({
  salutation,
  firstname,
  lastname,
  middlename,
  bmw_secondlastname,
  bmw_department,
  suffix,
  jobtitle,
  bmw_position2,
  bmw_position,
  parentcustomerid,
  bmw_contactdetailssource,
  emailaddress1,
  bmw_emailbounceback,
  bmw_primarylanguageid,
  telephone1,
  mobilephone,
  otherPhone,
  bmw_contractsignatory,
  bmw_contractrecipient,
  bmw_influencer,
  bmw_decisionmaker,
  bmw_fleetmanager,
  bmw_authorisedrepresentative,
  bmw_ultimatebeneficialowner,
  bmw_address1buildingnumber,
  address1_line1,
  address1_line2,
  address1_line3,
  address1_city,
  bmw_address1_county,
  address1_postalcode,
  address1_postofficebox,
  bmw_country,
  bmw_billingcontact,
  bmw_partnership,
  bmw_salescontact,
  bmw_agreementcontact,
  bmw_keycontact,
  marketingonly,
  bmw_politicallyexposedperson,
}) => ({
  title: salutation,
  firstName: firstname,
  lastName: lastname,
  middleName: middlename,
  secondLastName: bmw_secondlastname,
  department: {
    value: bmw_department ? bmw_department : null,
  },
  suffix,
  jobTitle: jobtitle,
  jobFunction: {
    value: bmw_position2 ? bmw_position2 : null,
  },
  position: {
    value: bmw_position ? bmw_position : null,
  },
  parentCustomer: parentcustomerid
    ? {
        id: parentcustomerid?.id,
        logicalName: parentcustomerid?.logicalName,
        name: parentcustomerid?.name,
      }
    : null,
  dataSource: bmw_contactdetailssource,
  email: emailaddress1,
  emailBounceBack: {
    value: bmw_emailbounceback ? bmw_emailbounceback : null,
  },
  primaryLanguage: bmw_primarylanguageid
    ? {
        id: bmw_primarylanguageid?.id,
        logicalName: bmw_primarylanguageid?.logicalName,
        name: bmw_primarylanguageid?.name,
      }
    : null,
  businessPhone: telephone1,
  mobilePhone: mobilephone,
  otherPhone,
  signatory: bmw_contractsignatory,
  recipient: bmw_contractrecipient,
  influencer: bmw_influencer,
  decisionMaker: bmw_decisionmaker,
  fleetManager: bmw_fleetmanager,
  authorisedRepresentative: bmw_authorisedrepresentative,
  ultimateBeneficialOwner: bmw_ultimatebeneficialowner,
  buildingNumber: bmw_address1buildingnumber,
  street1: address1_line1,
  street2: address1_line2,
  street3: address1_line3,
  city: address1_city,
  county: bmw_address1_county
    ? {
        id: bmw_address1_county?.id,
        logicalName: bmw_address1_county?.logicalName,
        name: bmw_address1_county?.name,
      }
    : null,
  postalCode: address1_postalcode,
  poBox: address1_postofficebox,
  country: {
    value: bmw_country ? bmw_country : null,
  },
  billing: bmw_billingcontact,
  partnership: bmw_partnership,
  sales: bmw_salescontact,
  agreement: bmw_agreementcontact,
  keyContact: bmw_keycontact,
  marketing: marketingonly,
  politicallyExposedPerson: bmw_politicallyexposedperson,
});

export { createContactMapper };
