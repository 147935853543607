import React, { useState } from "react";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import VerificationDialog from "@/components/OrderDetails/VerificationDialog";
import { useTranslation } from "react-i18next";

const Verification = ({
  id,
  instructions,
  setIsLoading,
  setShowAlert,
  setUpdateTimeline,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        type="button"
        variant="outlined"
        endIcon={<FileUploadOutlinedIcon />}
      >{t("formActions.uploadVerification")}
      </Button>

      <VerificationDialog
        id={id}
        instructions={instructions}
        open={open}
        setOpen={setOpen}
        setIsLoading={setIsLoading}
        setShowAlert={setShowAlert}
        setUpdateTimeline={setUpdateTimeline}
      />
    </>
  );
};

export default Verification;
