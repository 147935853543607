import React from "react";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  businessPartnerRoute,
  businessPartnersRoute,
  contactRoute,
  contactsRoute,
  contractRoute,
  contractsRoute,
  createBusinessPartnerRoute,
  createContactRoute,
  createContractRoute,
  discountMatrixRoute,
  dunsSearchRoute,
  esoSearchRoute,
  esodunsSearchRoute,
  guidRegex,
  helpRoute,
  homeRoute,
  orderRoute,
  ordersRoute,
  settingsRoute,
  tacticalCampaignsRoute,
} from "@/constants/routes";
import { AppContext } from "@/context/AppContext";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
/**
 * @author Rob Cornelius
 * @since 2/2/2023
 * @description NKAM-9801 Add breadcrumb element
 * @returns <Breadcrumb />
 */
const Breadcrumb = () => {
  const { t } = useTranslation();
  const { breadcrumbLevel3Text, setBreadcrumbLevel3Text } =
    useContext(AppContext);
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  // Map for paths -> level 2 text
  const titleMapper = new Map([
    [businessPartnersRoute, t("breadcrumb.businessPartners")],
    [businessPartnerRoute, t("breadcrumb.businessPartner")],
    [createBusinessPartnerRoute, t("breadcrumb.businessPartner")],
    [`${contractsRoute}1`, t("breadcrumb.contracts")],
    [contractsRoute, t("breadcrumb.contracts")],
    [contractRoute, t("breadcrumb.contract")],
    [createContractRoute, t("breadcrumb.contract")],
    [helpRoute, t("breadcrumb.help")],
    [ordersRoute, t("breadcrumb.orders")],
    [orderRoute, t("breadcrumb.order")],
    [settingsRoute, t("breadcrumb.settings")],
    [discountMatrixRoute, t("breadcrumb.discountMatrix")],
    [tacticalCampaignsRoute, t("breadcrumb.tacticalCampaigns")],
    [dunsSearchRoute, t("breadcrumb.searchDUNS")],
    [contactRoute, t("breadcrumb.contact")],
    [createContactRoute, t("breadcrumb.contacts")],
    [contactsRoute, t("breadcrumb.contacts")],
    [homeRoute, t("breadcrumb.dashboard")],
    [esoSearchRoute, t("breadcrumb.searchESO")],
    [esodunsSearchRoute, t("breadcrumb.searchESODUNS")],
  ]);
  /**
   * Use Effect
   * listens to changes in location.pathname
   * if pathname has a guid at the end strip it out
   * the switch allows for odd paths so the user is taken to where they would expect
   * clear breadcrumbLevel3Text if there is no guid to prevent previous level 3 text appearing on a Level 2 page
   */
  useEffect(() => {
    if (guidRegex.test(location.pathname)) {
      let newPath = window.location.pathname.replace(guidRegex, "");
      switch (newPath) {
        case businessPartnerRoute:
          newPath = businessPartnersRoute;
          break;
        case orderRoute:
          newPath = ordersRoute;
          break;
        case contactRoute:
          newPath = contactsRoute;
          break;
        case contractRoute:
          newPath = contractRoute;
          break;
        default:
          console.error(newPath, "does not match any paths");
      }
      setPath(newPath);
    } else {
      setPath(window.location.pathname);
      setBreadcrumbLevel3Text("");
    }
  }, [location.pathname, setBreadcrumbLevel3Text]);
  // sub component for brevity
  const Level1 = () => {
    return (
      <div data-testid="breadcrumb-level1">
        <Description
          title={titleMapper.get(homeRoute)}
          href={homeRoute}
          underline="hover"
        >
          {titleMapper.get(homeRoute)}
        </Description>
      </div>
    );
  };
  // sub component for brevity
  const Level2 = () => {
    return (
      <>
        <LevelSeparator>&gt;</LevelSeparator>
        <div data-testid="breadcrumb-level2">
          {path === contactRoute ? (
            <Level2Link
              title={titleMapper.get(path)}
              href={path}
              underline="hover"
            >
              {titleMapper.get(path)}
            </Level2Link>
          ) : (
            <Level2Link to={-1} component={Link} underline="hover">
              {titleMapper.get(path)}
            </Level2Link>
          )}
        </div>
      </>
    );
  };
  // sub component for brevity
  const Level3 = () => {
    return (
      <>
        <LevelSeparator>&gt;</LevelSeparator>
        <Level3Container component="p" variant="body">
          {breadcrumbLevel3Text}
        </Level3Container>
      </>
    );
  };
  // this is the components return statment
  return (
    <BreadcrumbContainer data-testid="breadcrumb-container">
      <Level1 />
      {path !== homeRoute && <Level2 />}
      {breadcrumbLevel3Text !== "" && <Level3 />}
    </BreadcrumbContainer>
  );
};
const BreadcrumbContainer = styled("div")(() => ({
  display: "flex",
  marginLeft: "30px",
}));
const Description = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.common.dark,
}));
const LevelSeparator = styled("div")(() => ({
  width: "30px",
  textAlign: "center",
}));
const Level3Container = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: theme.typography.fontWeightBold,
}));

const Level2Link = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.primary.darkMain
}));

export default Breadcrumb;
