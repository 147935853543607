import { Box, Grid, Typography, styled } from "@mui/material";
import infoPanelBackground from "@/assets/images/info-panel-background.svg?react";
import statusBoxStyles from "./statusBoxStyles";
import {
  ACCOUNT_LOST,
  CUSTOMER,
  DOWNGRADED,
  LAPSED,
  LEAD,
  OTHER,
  PROSPECT,
  SUSPECT,
} from "@/constants/constants";

export const StyledInfoPanel = styled(Box)(({ theme }) => ({
  background: `linear-gradient(89.98deg, #F1EFFF 2.7%, rgba(241, 246, 250, 0.466416) 52.48%, rgba(241, 246, 250, 0) 98.28%), url(${infoPanelBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "calc(50% - 368.49px/2 - 93.77px)",
  borderRadius: "6px",
  border: `1px solid ${theme.palette.primary.main}`,
}));

export const StyledInfoPanelTitle = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: "25px",
  lineHeight: "34px",
  textAlign: "left",
  fontWeight: `${theme.typography.fontWeightBold}`,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: "250px",
}));
export const StyledInfoPanelSubTitle = styled(Typography)(() => ({
  color: "#343A40",
  textAlign: "left",
  fontSize: "16px",
  lineHeight: "120%",
  fontWeight: "400",
}));
export const StyledInfoPanelValueTitle = styled(Typography)(() => ({
  color: "#000",
  fontSize: "16px",
  lineHeight: "120%",
  marginBottom: "18px",
}));
export const StyledInfoPanelValue = styled(Typography)(() => ({
  fontSize: "16px",
  color: "#000",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: "200px",
}));
export const StyledInfoPanelGrid = styled(Grid)(() => ({
  padding: "0 0 0 26px !important",
  borderRight: "1px solid #000",
}));

export const StyledInfoPanelStatusBox = styled("span")(({ status }) => ({
  ...statusBoxStyles.statusBox,
  ...getStatusStyles(status),
}));

const getStatusStyles = (status) => {
  switch (status) {
    case PROSPECT:
      return statusBoxStyles.statusBlue;
    case LEAD:
      return statusBoxStyles.statusPurple;
    case CUSTOMER:
      return statusBoxStyles.statusGreen;
    case LAPSED:
      return statusBoxStyles.statusRed;
    case ACCOUNT_LOST:
      return statusBoxStyles.statusRed;
    case DOWNGRADED:
      return statusBoxStyles.statusRed;
    case SUSPECT:
      return statusBoxStyles.statusRed;
    case OTHER:
      return statusBoxStyles.statusBlue;
    default:
      return statusBoxStyles.statusBlue;
  }
};
