import { apiQuery } from "./apiHandler";

const createContact = (data) =>
  new Promise((resolve, reject) => {
    const endpoint = "contacts";

    apiQuery(endpoint, null, "POST", resolve, reject, data);
  });

const getContactDetail = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "contacts/" + id;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchContactsData = (
  name = "",
  bpId = "0",
  pageSize = 5,
  pageNumber = 1,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    let endpoint =
      "contacts/search?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&name=" +
      name +
      "&bpId=" +
      bpId;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const updateContact = (id, data) =>
  new Promise((resolve, reject) => {
    const endpoint = "contacts/" + id;

    apiQuery(endpoint, null, "PATCH", resolve, reject, data);
  });

const apiContactsData = {
  createContact,
  getContactDetail,
  searchContactsData,
  updateContact,
};

export default apiContactsData;
