import React from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

import { CREATE, EDIT, VIEW } from "@/constants/constants";
import { useTranslation } from "react-i18next";

const PageActions = ({
  isEditable,
  canSave,
  mode,
  setMode,
}) => {

  const { t } = useTranslation();
  return (
    <>
      {mode === CREATE && (
        <>
          <Button
            endIcon={<EditIcon />}
            type="submit"
            disabled={!canSave}
          >
            {t('formActions.save')}
          </Button>

          <Button
            color="secondary"
            component={Link}
            endIcon={<EditIcon />}
            to={-1}
            type="button"
          >
            {t('formActions.cancel')}
          </Button>
        </>
      )}

      {mode === EDIT && (
        <>
          <Button
            type="submit"
            disabled={!canSave}
          >
            {t('formActions.save')}
          </Button>

          <Button
            color="secondary"
            endIcon={<EditIcon />}
            onClick={(event) => {
              event.preventDefault();
              setMode(VIEW);
            }}
            type="button"
          >
            {t('formActions.cancel')}
          </Button>
        </>
      )}

      {mode === VIEW && (
        <>
          <Button
            disabled={!isEditable}
            onClick={(event) => {
              event.preventDefault();
              setMode(EDIT);
            }}
            endIcon={<EditIcon />}
          >
            {t('formActions.edit')}
          </Button>
        </>
      )}
    </>
  );
};

export default PageActions;
