// STATUS CODES
export const BAD_REQUEST_400 = 400;
export const UNAUTHORISED_401 = 401;
export const FORBIDDEN_403 = 403;
export const NOT_FOUND_404 = 404;
export const INTERNAL_SERVER_ERROR_500 = 500;

// ERROR TITLES
export const BAD_REQUEST_400_TITLE = "Bad Request";
export const UNAUTHORISED_401_TITLE = "Unauthorised";
export const FORBIDDEN_403_TITLE = "Forbidden";
export const NOT_FOUND_404_TITLE = "Not Found";
export const INTERNAL_SERVER_ERROR_500_TITLE = "Internal Server Error";
