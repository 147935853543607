export const filters = [
  {
    name: "contractsPage.filters.filter1",
    key: "Open Contracts",
  },
  {
    name: "contractsPage.filters.filter2",
    key: "My Contracts",
  },
  {
    name: "contractsPage.filters.filter3",
    key: "Due for Renewal",
  },
  {
    name: "contractsPage.filters.filter4",
    key: "In Preparation",
  },
  {
    name: "contractsPage.filters.filter5",
    key: "Approved",
  },
  {
    name: "contractsPage.filters.filter6",
    key: "Active",
  },
];
