import React, { createContext, useState } from "react";
import { PAGE_SIZE } from "@/constants/constants";

const ContractsContext = createContext({});

const ContractsProvider = ({ children }) => {
  const [contracts, setContracts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = PAGE_SIZE;

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        setContracts,
        page,
        setPage,
        pageSize,
        searchText,
        setSearchText,
        totalPages,
        setTotalPages,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export { ContractsContext, ContractsProvider };
