import React from "react";
import { styled } from '@mui/material/styles';
import CircularProgress from "@mui/material/CircularProgress";
import MuiBackdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";


const Backdrop = ({ text }) => {
  const { t } = useTranslation();

  return (
    <StyledBackdrop open={true}>
      <CircularProgress color="primary" />

      <StyledTypography variant="body1" data-testid="loading-spinner">
        {text ? text : t("backdrop.loading")}
      </StyledTypography>
    </StyledBackdrop>
  );
};

const StyledBackdrop = styled(MuiBackdrop)(() => ({
  alignItems: "center",
  background: "rgba(0,0,0, 0.15)",
  display: "flex",
  flexDirection: "column",
  zIndex: 1350,
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

export default Backdrop;
