import React from "react";
import { Controller } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import TooltipConditionalWrapper from "@/components/TooltipConditionalWrapper";
import { useTranslation } from "react-i18next";

const NumberController = ({
  control,
  disabled,
  label,
  name,
  required,
  type,
  inputProps,
  title,
  testid
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
        <TooltipConditionalWrapper
          condition={title}
          wrapper={(children) => <Tooltip title={title}>{children}</Tooltip>}
        >
          <TextField
            data-testid={testid}
            disabled={disabled}
            label={label}
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value))}
            error={!!error}
            required={required}
            helperText={
              error
                ? error.type === "expectedVolume"
                  ? t("form.number.expectedVolume")
                  : error.type === "fleetsize" ? t("form.number.fleetSize")
                    : error.message
                      ? error.message
                      : null
                : null
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: disabled && <LockOutlinedIcon />,
              //this will pass native html props, so let the client decide what to pass.
              inputProps: { ...inputProps }
            }}
            inputRef={ref}
            type={type}
          />
        </TooltipConditionalWrapper>
      )}
      rules={{
        required: required && t("form.number.required")
      }}
    />
  );
};

export default NumberController;
