import { styled } from "@mui/material/styles"
import TableCell from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`& .root`]: {
    padding: "10px 10px 10px 0px",
    "&:hover": {
      backgroundColor: theme.palette.main,
    },
    borderBottom: "none",
    // minWidth: 120,
  },
  [`& .head`]: {
    color: theme.palette.common.black,
    fontWeight: 700,
    borderBottom: "1px solid #dedede",
  },
  [`& .body`]: {
    fontSize: 12,
  },
}));

export default StyledTableCell;
