import { useState, useEffect } from "react";

export const useDebounce = (inputs, time=1000) => {
    const [debouncedValue, setDebouncedValue] = useState(inputs);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputs);
        }, time);
        return () => {
            clearTimeout(handler);
        };
    }, [...inputs]);
    return debouncedValue;
};