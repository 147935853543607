import React, { useState } from "react";
import Modal from "@/components/Modal";
import utils from "@/utils/common";

const AppError = ({
  apiError: { message, responseURL, statusCode, title },
  setApiError,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setApiError({});

    if (statusCode === "401") {
      utils.redirectToSGateForLogin();
    }
  };

  return (
    <Modal
      handler={handleClose}
      message={message}
      open={open}
      responseURL={responseURL}
      title={title}
      statusCode={statusCode}
      data-testid="error-modal"
    />
  );
};

export default AppError;
