import { accessTokenKey, idTokenKey } from "@/constants/constants";

const getIdToken = () => {
  const tokenString = localStorage.getItem(idTokenKey);
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const getAccessToken = () => {
  const accessToken = localStorage.getItem(accessTokenKey);
  return accessToken;
};

const saveIdToken = (userToken) => {
  localStorage.setItem(idTokenKey, JSON.stringify(userToken));
};

const saveAccessToken = (accessToken) => {
  localStorage.setItem(accessTokenKey, accessToken);
};

const useToken = {
  getIdToken,
  saveIdToken,
  getAccessToken,
  saveAccessToken,
};

export default useToken;
