import { apiQuery } from "./apiHandler";
import utils from "@/utils/common";
import { PANEL_PAGE_SIZE } from "@/constants/constants";

const createBusinessPartner = (data) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners";

    apiQuery(endpoint, null, "POST", resolve, reject, data);
  });

const createBusinessPartnerFromDuns = (data) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/createduns";

    apiQuery(endpoint, null, "POST", resolve, reject, data);
  });

const createBusinessPartnerTimelineNote = (data) =>
  new Promise((resolve, reject) => {
    const endpoint = "notes/timeline/note";

    apiQuery(endpoint, null, "POST", resolve, reject, data);
  });

const getBusinessPartnerDetail = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/" + id;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getBusinessPartnerPermissions = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/permission/" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getBusinessPartnerTimelineConfig = (controller = null) =>
  new Promise((resolve, reject) => {
    let endpoint = "user/bptimelinevisibility";

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getPermissionToCreate = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "user/owningretailerbppermission?id=" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchBusinessPartnersData = (
  pageSize,
  pageNumber = 1,
  city = "",
  postcode = "",
  searchTerm = "",
  quickFilter = "",
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    let endpoint =
      "businesspartners/search?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&city=" +
      utils.autoWildcardSearchTerm(city) +
      "&postcode=" +
      utils.autoWildcardSearchTerm(postcode) +
      "&search=" +
      utils.autoWildcardSearchTerm(encodeURIComponent(searchTerm)) +
      "&quickFilter=" +
      quickFilter;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchBusinessPartnerTimeline = (
  id,
  query,
  pageNumber,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    const pageSize = PANEL_PAGE_SIZE;
    const endpoint =
      "notes/timeline/search/businesspartner/" +
      id +
      "?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&search=" +
      utils.autoWildcardSearchTerm(query);

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchDUNSData = (
  name,
  city,
  postCode,
  companyNumber,
  vatNumber = "",
  controller = null,
) =>
  new Promise((resolve, reject) => {
    let endpoint =
      "businesspartners/duns?Name=" +
      encodeURIComponent(name) +
      "&City=" +
      city +
      "&postCode=" +
      postCode +
      "&vatNumber=" +
      vatNumber +
      "&companyNumber=" +
      companyNumber;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });
const searchtermsofcreditBytype = (accounttype, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/accounttype/termofcredit/" + accounttype;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchtermsofcreditBybusinesspartnerid = (
  businesspartnerid,
  controller,
) =>
  new Promise((resolve, reject) => {
    if (businesspartnerid) {
      const endpoint = "businesspartners/termofcredit/" + businesspartnerid;
      apiQuery(endpoint, controller, "GET", resolve, reject);
    }
  });
const updateBusinessPartner = (id, data) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/" + id;
    apiQuery(endpoint, null, "PATCH", resolve, reject, data);
  });

const getPermissionToViewBPContacts = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "user/bpcontactsvisibility";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getBpContactsHasSignatory = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "user/bpcontactshasrecipient?id=" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getTimelineNoteFileDetails = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = `notes/timeline/document/${id}`;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getBpDiscountCategories = (
  bpId,
  pageSize,
  pageNumber,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "discountcategories/forbp?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&bpid=" +
      bpId;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOwningKamServiceUsers = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "user/serviceusers";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchEsoRecords = (data, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/eso";
    apiQuery(endpoint, controller, "POST", resolve, reject, data);
  });

const createEsoRecord = (record) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/createeso";

    apiQuery(endpoint, null, "POST", resolve, reject, record);
  });

const getBusinessPartnerRoles = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = `businesspartners/businessPartnerRoles/${id}`;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getBusinessPartnerRoleDetail = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = `businesspartners/bprole/${id}`;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const createBusinessPartnerRole = (data) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/createbprole";

    apiQuery(endpoint, null, "POST", resolve, reject, data);
  });

const getBusinessPartnerRequiredFields = (nkamStatus, accountType, legalEntityType, hasBillingAddress, controller=null) => {
    return new Promise((resolve, reject) => {
        let query = `?hasBillingAddress=${hasBillingAddress}&status=${nkamStatus}`;
        if (accountType) {
            query = query + `&accounttypeid=${accountType}`
        }
        if (legalEntityType) {
            query = query + `&legalentitytypeid=${legalEntityType}`
        }
        const endpoint = `businesspartners/getRequiredFields${query}`;
        apiQuery(endpoint, controller, "GET", resolve, reject);
    });
};


  const hasDiscountCategoriesDiffer = (currentAccountType, newAccountType, controller = null) =>
    new Promise((resolve, reject) => {
      const endpoint = `discountcategories/forAccountTypeChange?currentAccountTypeId=${currentAccountType}&newAccountTypeId=${newAccountType}`;
      apiQuery(endpoint, controller, "GET", resolve, reject);
    });

const apiBPData = {
  createBusinessPartner,
  createBusinessPartnerFromDuns,
  createBusinessPartnerTimelineNote,
  getBusinessPartnerDetail,
  getBusinessPartnerPermissions,
  getBusinessPartnerTimelineConfig,
  getPermissionToCreate,
  searchBusinessPartnersData,
  searchBusinessPartnerTimeline,
  searchDUNSData,
  searchtermsofcreditBybusinesspartnerid,
  searchtermsofcreditBytype,
  updateBusinessPartner,
  getPermissionToViewBPContacts,
  getBpContactsHasSignatory,
  getTimelineNoteFileDetails,
  getBpDiscountCategories,
  getOwningKamServiceUsers,
  searchEsoRecords,
  createEsoRecord,
  getBusinessPartnerRoleDetail,
  createBusinessPartnerRole,
  getBusinessPartnerRequiredFields,
  getBusinessPartnerRoles,
  hasDiscountCategoriesDiffer
};

export default apiBPData;
