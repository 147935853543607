import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Send from "@mui/icons-material/Send";
import ApprovalDialog from "@/components/ApprovalDialog";
import AppError from "@/AppError";
import { handleApiError } from "@/utils/handleApiError";
import { useContractApprovalQueries } from "@/services/queries";
import { useTranslation } from "react-i18next";

const Approval = ({
  entity,
  id,
  sentForApproval,
  setIsLoading,
  setShowAlert,
  updateTimeline,
}) => {
  const { t } = useTranslation();
  const [approvalTypes, setApprovalTypes] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiError, setApiError] = useState({});

  const getApprovalVisibilityAndOptions = useContractApprovalQueries(entity, id, sentForApproval, updateTimeline);
  const isApprovalsFetching = getApprovalVisibilityAndOptions.some(query => query.isFetching);
 
  useEffect(() => {
    setIsLoading(isApprovalsFetching);
    if(isApprovalsFetching) return;
    if (getApprovalVisibilityAndOptions.some(query => query.isError)) {
      let error = getApprovalVisibilityAndOptions.find(query => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (getApprovalVisibilityAndOptions.some(query => query.isSuccess)) {
      if(getApprovalVisibilityAndOptions[0]?.data?.data?.result !== undefined) {
        setDisabled(getApprovalVisibilityAndOptions[0]?.data?.data?.result);
      }
      setApprovalTypes(getApprovalVisibilityAndOptions[1].data?.data?.result);
    }
  }, [getApprovalVisibilityAndOptions[0].dataUpdatedAt, getApprovalVisibilityAndOptions[1].dataUpdatedAt, isApprovalsFetching]);

  useEffect(() => {
    return () => {
      // controller.abort();
    };
  }, []);

  return (
    <>
      <Button
        disabled={!disabled}
        endIcon={<Send />}
        onClick={() => setOpen(true)}
        data-testid="approvals-button"
      >
        {t("formActions.sendForApproval")}
      </Button>

      <ApprovalDialog
        entity={entity}
        id={id}
        open={open}
        options={approvalTypes}
        setOpen={setOpen}
        setIsLoading={setIsLoading}
        setShowAlert={setShowAlert}
      />

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default Approval;
