import { apiQuery } from "./apiHandler";
import utils from "@/utils/common";

const createContract = (data) =>
  new Promise((resolve, reject) => {
    const endpoint = "contracts/";

    apiQuery(endpoint, null, "POST", resolve, reject, data);
  });

const createContractTimelineNote = (data) =>
  new Promise((resolve, reject) => {
    const endpoint = "notes/timeline/note";

    apiQuery(endpoint, null, "POST", resolve, reject, data);
  });

const getContractsDetail = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "contracts/" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const permissionToEditContract = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "user/owningRetailerPermission?id=" + id;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchContractTimeline = (id, query, pageNumber, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint =
      "notes/timeline/search/" +
      id +
      "?search=" +
      utils.autoWildcardSearchTerm(query);
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchContracts = (
  pageSize,
  pageNumber,
  searchText,
  quickFilter = "",
  controller = null,
) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "contracts/search?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&search=" +
      utils.autoWildcardSearchTerm(searchText) +
      "&quickFilter=" +
      quickFilter;

    apiQuery(endpoint, controller, "GET", resolve, reject);
  });
const searchtermsofcreditBytype = (accounttype, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/accounttype/termofcredit/" + accounttype;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchtermsofcreditBybusinesspartnerid = (
  businesspartnerid,
  controller,
) =>
  new Promise((resolve, reject) => {
    const endpoint = "businesspartners/termofcredit/" + businesspartnerid;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const searchContractsByBP = (bpId, pageSize, pageNumber, controller = null) =>
  new Promise((resolve, reject) => {
    if (pageNumber === 0) pageNumber = 1;

    const endpoint =
      "contracts/forbp?PageNumber=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&bpid=" +
      bpId;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const sendDocumentToBeGenerated = (id, entity, data) =>
  new Promise((resolve, reject) => {
    const endpoint =
      "contracts/generatedocument/" + id + "?entityName=" + entity;

    apiQuery(endpoint, null, "PATCH", resolve, reject, data);
  });

const updateContract = (id, data) =>
  new Promise((resolve, reject) => {
    const endpoint = "contracts/" + id;

    apiQuery(endpoint, null, "PATCH", resolve, reject, data);
  });

const apiContractsData = {
  createContract,
  createContractTimelineNote,
  getContractsDetail,
  permissionToEditContract,
  searchContractTimeline,
  searchContracts,
  searchContractsByBP,
  sendDocumentToBeGenerated,
  updateContract,
  searchtermsofcreditBytype,
  searchtermsofcreditBybusinesspartnerid,
};

export default apiContractsData;
