import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ActionsBar from "@/components/ActionsBar";
import PageTitle from "@/components/PageTitle";
import { useTranslation } from "react-i18next";
import { AppContext } from "@/context/AppContext";
import { marketCodeKey } from "@/constants/constants";

export const HelpPage = () => {
  const { t } = useTranslation();
  const {
    userData: { [marketCodeKey]: marketCode},
  } = useContext(AppContext);

  // Initialize file paths
  const directoryPath = '../documents';
  const fileName = `help_${marketCode?.toLowerCase()}.pdf`;
  const [filePath, setFilePath] = useState(`/${directoryPath}/help.pdf`);
  
  useEffect(() => {
    // Check if file exists. If exist update file path else update with default
    const checkFile = async () => {
      try {
        const response = await fetch(`/${directoryPath}/${fileName}`);
        let contenttype = response.headers.get('content-type');
        if (response.ok && contenttype === 'application/pdf') {
          setFilePath(`/${directoryPath}/${fileName}`);
        } else {
          setFilePath(`/${directoryPath}/help.pdf`);
        }
      } catch (error) {
        setFilePath(`/${directoryPath}/help.pdf`);
      }
    };
 
    checkFile();
  });

  return (
    <>
      <ActionsBar backButton={true} />

      <Box mt={3}>
        <PageTitle title={t("helpPage.title")} />
      </Box>

      <Box mt={1}>
        <Link href={filePath} rel="noopener" target="_blank">
          {t("helpPage.download")}
        </Link>
      </Box>
    </>
  );
};
