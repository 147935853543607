import React, { createContext, useState } from "react";

const ContactDetailsContext = createContext({});

const ContactDetailsProvider = ({ children }) => {
  // Controls
  const [emailBounceBackOptions, setEmailBounceBackOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [jobFunctionOptions, setJobFunctionOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [primaryLanguageOptionsFromConfig, setPrimaryLanguageOptionsFromConfig] = useState([]);
  const [primaryLanguageDefaultOptionFromConfig, setPrimaryLanguageDefaultOptionFromConfig] = useState("");

  return (
    <ContactDetailsContext.Provider
      value={{
        departmentOptions,
        setDepartmentOptions,
        emailBounceBackOptions,
        setEmailBounceBackOptions,
        jobFunctionOptions,
        setJobFunctionOptions,
        positionOptions,
        setPositionOptions,
        primaryLanguageOptionsFromConfig,
        setPrimaryLanguageOptionsFromConfig,
        primaryLanguageDefaultOptionFromConfig,
        setPrimaryLanguageDefaultOptionFromConfig,
      }}
    >
      {children}
    </ContactDetailsContext.Provider>
  );
};

export { ContactDetailsContext, ContactDetailsProvider };
