import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import BackButton from "@/components/BackButton";

const ActionsBar = ({ backButton = true, children }) => {

  return (
    <Actions>
      <Box>{backButton && <BackButton />}</Box>
      {children}
      <StyledDivider />
    </Actions>
  );
};

const Actions = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
}))
const StyledDivider = styled(Divider)(() => ({
  backgroundColor: "#8a8c8f",
  width: "100%",
}));


export default ActionsBar;
