import React, { createContext, useState } from "react";
import { PAGE_SIZE } from "@/constants/constants";

const TacticalCampaignsContext = createContext({});

const TacticalCampaignsProvider = ({ children }) => {
  const [tacticalCampaigns, setTacticalCampaigns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = PAGE_SIZE;

  return (
    <TacticalCampaignsContext.Provider
      value={{
        tacticalCampaigns,
        setTacticalCampaigns,
        page,
        setPage,
        pageSize,
        searchText,
        setSearchText,
        totalPages,
        setTotalPages,
      }}
    >
      {children}
    </TacticalCampaignsContext.Provider>
  );
};

export { TacticalCampaignsContext, TacticalCampaignsProvider };
