import React from "react";
import ContractStatus from "@/components/ContractStatus";
import ConditionalLink from "@/components/ConditionalLink";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";
import { useTranslation } from "react-i18next";

export const useContractTable = () => {
  const { t } = useTranslation();
  return [
    {
      field: "contractName",
      headerName: t("contractsPage.datagridColumns.name"),
      type: "string",
      flex: 3,
      maxWidth: 300,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.contractName) {
          return null;
        }
        return params.row.contractName;
      },
      renderCell: (params) => {
        if (!params.row.contractName) {
          return "--";
        }
        return (
          <ConditionalLink
            title={params.row.contractName}
            url={params.row.contractRoute + params.row.id}
          />
        );
      },
    },
    {
      field: "contractId",
      headerName: t("contractsPage.datagridColumns.contractId"),
      type: "string",
      flex: 1.5,
      maxWidth: 150,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.contractId) {
          return null;
        }
        return params.row.contractId;
      },
      renderCell: (params) => {
        if (!params.row.contractId) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.contractId} />;
      },
    },
    {
      field: "accountname",
      headerName: t("contractsPage.datagridColumns.businessPartnerName"),
      type: "string",
      flex: 1.75,
      maxWidth: 175,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.account?.name) {
          return null;
        }
        return params.row.account?.name;
      },
      renderCell: (params) => {
        if (!params.row.account?.name) {
          return "--";
        }
        return (
          <ConditionalLink
            title={params.row.account?.name}
            url={params.row.businessPartnerRoute + params.row.account?.id}
          />
        );
      },
    },
    {
      field: "upfrontDiscountname",
      headerName: t("contractsPage.datagridColumns.upfrontDiscountMatrix"),
      type: "string",
      flex: 2,
      maxWidth: 200,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.upfrontDiscount?.name) {
          return null;
        }
        return params.row.upfrontDiscount?.name;
      },
      renderCell: (params) => {
        if (!params.row.upfrontDiscount?.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.upfrontDiscount?.name} />;
      },
    },
    {
      field: "owner?.name",
      headerName: t("contractsPage.datagridColumns.owner"),
      type: "string",
      flex: 3.25,
      maxWidth: 325,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.owner?.name) {
          return null;
        }
        return params.row.owner?.name;
      },
      renderCell: (params) => {
        if (!params.row.owner?.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.owner?.name} />;
      },
    },
    {
      field: "salesChannelname",
      headerName: t("contractsPage.datagridColumns.salesChannel"),
      type: "string",
      flex: 1.25,
      maxWidth: 125,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.salesChannel?.name) {
          return null;
        }
        return params.row.salesChannel?.name;
      },
      renderCell: (params) => {
        if (!params.row.salesChannel?.name) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.salesChannel?.name} />;
      },
    },
    {
      field: "contractStatusname",
      headerName: t("contractsPage.datagridColumns.status"),
      type: "string",
      flex: 1.5,
      maxWidth: 150,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.contractStatus?.name) {
          return null;
        }
        return params.row.contractStatus?.name;
      },
      renderCell: (params) => {
        if (!params.row.contractStatus?.name) {
          return "--";
        }
        return <ContractStatus status={params.row.contractStatus?.name} />;
      },
    },
    {
      field: "startDate",
      headerName: t("contractsPage.datagridColumns.startDate"),
      type: "date",
      flex: 1,
      maxWidth: 100,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.startDate) {
          return null;
        }
        return new Date(params.row.startDate);
      },
      renderCell: (params) => {
        if (!params.row.startDate) {
          return "--";
        }
        return (
          <ConditionalTooltip
            title={
              params.row.startDate &&
              convertISODateToDayMonthYear(params.row.startDate)
            }
          />
        );
      },
    },
    {
      field: "endDate",
      headerName: t("contractsPage.datagridColumns.endDate"),
      type: "date",
      flex: 1,
      maxWidth: 100,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.endDate) {
          return null;
        }
        return new Date(params.row.endDate);
      },
      renderCell: (params) => {
        if (!params.row.endDate) {
          return "--";
        }
        return (
          <ConditionalTooltip
            title={
              params.row.endDate &&
              convertISODateToDayMonthYear(params.row.endDate)
            }
          />
        );
      },
    },
    {
      field: "totalCompletedOrders",
      headerName: t("contractsPage.datagridColumns.completedOrders"),
      type: "number",
      flex: 1.5,
      maxWidth: 150,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (!params.row.totalCompletedOrders) {
          return null;
        }
        return params.row.totalCompletedOrders;
      },
      renderCell: (params) => {
        if (!params.row.totalCompletedOrders) {
          return "--";
        }
        return <ConditionalTooltip title={params.row.totalCompletedOrders} />;
      },
    },
  ];
};