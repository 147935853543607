import React from "react";
import { Link, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, styled } from "@mui/material";

const NavItem = ({ path, icon, name, setOpen }) => {
  const location = useLocation();

  return (
    <>
      {path && (
        <StyledListItem
          onMouseEnter={() => setOpen(true)}
          selected={path === location.pathname}
        >
          <StyledLink component={Link} to={path} sx={{ padding: 0 }}>
            {icon ? <StyledListLicon>{icon}</StyledListLicon> : null}

            <ListItemText primary={name} />
          </StyledLink>
        </StyledListItem>
      )}
    </>
  );
};

const StyledLink = styled(ListItem)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  padding: `${theme.spacing(0.8, 1.6)} !important`,

  "&:hover": {
    backgroundColor: "transparent"
  }
}));

// selected prop depricated in ListItem. It is recommended to use ListItemButton

const StyledListItem = styled(ListItemButton)(() => ({
  height: 40,
  overflow: "hidden",
  padding: 0,
  "&.Mui-selected": {
    backgroundColor: "#e7e9ea",
    boxShadow: "inset 5px 0 0 #1987c9",
    color: "#0b406c",
  },
  "&.Mui-selected:hover, &:hover": {
    backgroundColor: "#e7e9ea",
  },
  "&.Mui-selected svg path": {
    fill: "#0b406c",
  },
}));

const StyledListLicon = styled(ListItemIcon)(({ theme }) => ({
  marginRight: theme.spacing(2),
  minWidth: "auto",
}));

export default NavItem;
