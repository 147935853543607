import React from "react";
import { Controller } from "react-hook-form";

import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TooltipConditionalWrapper from "@/components/TooltipConditionalWrapper";
import { CREATE, EDIT } from "@/constants/constants";

const CheckboxController = ({
  control,
  disabled,
  label,
  mode,
  name,
  view,
  title,
  onCheckboxChange,
  testid
}) => {
  return (
    <>
      {mode === EDIT || mode === CREATE ? (
        <Controller
          name={name}
          control={control}
          label={label}
          render={({ field: { onChange: fieldOnChange, ref, value } }) => (
            <TooltipConditionalWrapper
              condition={title}
              wrapper={(children) => (
                <Tooltip title={label}>{children}</Tooltip>
              )}
            >
              <StyledFormControl disabled={disabled} >
                <StyledFormControlLabel
                  label={label}
                  onChange={(event, values) => {
                    fieldOnChange(event, values);
                    onCheckboxChange && onCheckboxChange(event, values);
                  }}
                  checked={value}
                  control={<Checkbox data-testid={testid} checked={!!value} />}
                  inputRef={ref}
                />
              </StyledFormControl>
            </TooltipConditionalWrapper>
          )}
        />
      ) : (
        <StyledContainer>
          {view ? (
            <StyledCheckBoxIcon color="primary" />
          ) : (
            <StyledCheckBoxOutlinedIcon />
          )}

          <StyledBoldLabel>{label}</StyledBoldLabel>
        </StyledContainer>
      )}
    </>
  );
};

const StyledFormControl = styled(FormControl)(() => ({
  width: "100%",
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: theme?.shape?.borderRadius,
  margin: theme.spacing(0),

  "& .MuiFormControlLabel-label": {
    fontSize: theme?.typography?.body1?.fontSize,
    lineHeight: "1.1876em",
    transform: "scale(0.75)",
    transformOrigin: "left",
  },
}));

const StyledContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  marginBottom: 4,
  marginTop: 8,
}));

const StyledCheckBoxIcon = styled(CheckBoxIcon)(() => ({
  margin: 9,
}));

const StyledCheckBoxOutlinedIcon = styled(CheckBoxOutlineBlankIcon)(() => ({
  margin: 9,
}),
);

const StyledBoldLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme?.typography?.body1?.fontSize,
  lineHeight: "1.1876em",
  transform: "scale(0.75)",
  transformOrigin: "left",
  fontWeight: theme?.typography?.fontWeightBold,
}));

export default CheckboxController;
