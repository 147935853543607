import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { onLCP, onFID, onCLS, onTTFB, onINP } from "web-vitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "@/App";
import { appInsights } from "@/components/AppInsights/AppInsights";
import './configuration/i18n';
appInsights.loadAppInsights();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
)
/**
 * @author Rob Cornelius
 * @function sendToAppInsights
 * @param {*} data data from web-vitals event
 * @returns void
 * @descrition makes call to app insights to pass the data to  the BE
 */
const sendToAppInsights = (data) => {
  appInsights.trackMetric({
    name: data.name,
    average: parseFloat(data.value),
  });
};
/**
 * @function onLCP
 * @description Largest Contentful Paint
 * @link https://web.dev/lcp/
 */
onLCP(sendToAppInsights);
/**
 * @function onFID
 * @description First Input Delay
 * @link https://web.dev/fid/
 */
onFID(sendToAppInsights);
/**
 * @function onCLS
 * @description Cumulative Layout Shift
 * @link https://web.dev/cls/
 */
onCLS(sendToAppInsights);
/**
 * @function onTTFB
 * @description Time to First Byte
 * @link https://web.dev/ttfb
 */
onTTFB(sendToAppInsights);
/**
 * @function onINP
 * @description Interaction to NextPaint
 * @link https://web.dev/inp/
 */
onINP(sendToAppInsights);
