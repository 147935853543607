import React, { useEffect } from "react";
import utils from "@/utils/common";
import useToken from "@/utils/useToken";

export const LogoutPage = () => {
  useEffect(() => {
    //1. clear tokens from local storage
    //2. log out from sgate
    let idToken = useToken.getIdToken();
    utils.redirectToSGateForLogout(idToken);
  }, []);

  return <></>;
};
