const updateContractMapper = (
  id,
  contractName,
  contractId,
  marketCode,
  originalContractStatus,
  account,
  {
    bmw_saleschannel,
    bmw_contractcategoryid,
    bmw_documenttemplate,
    bmw_contractstatusglobaloptionset,
    bmw_exoticcontract,
    bmw_startdate,
    bmw_endofcontract,
    bmw_duration,
    bmw_contracttemplateid,
    bmw_terms_and_conditions,
    bmw_datesigned,
    bmw_datetosubmitclaimdocuments,
    bmw_signedbynsc,
    bmw_lastorderdate,
    bmw_lastagreementdate,
    bmw_totalcompletedorders,
    bmw_bulkapprovalteam,
    bmw_leasedvehicleorders,
  },
) => ({
  id,
  contractName,
  contractId,
  marketCode,
  account: account
    ? {
        id: account?.id,
        logicalName: account?.logicalName,
        name: account?.name,
      }
    : null,
  salesChannel: { value: bmw_saleschannel ? bmw_saleschannel : null },
  contractCategory: bmw_contractcategoryid ? {
    id: bmw_contractcategoryid?.id,
    logicalName: bmw_contractcategoryid?.logicalName,
    name: bmw_contractcategoryid?.name,
  } : null,
  template: bmw_documenttemplate ? bmw_documenttemplate : null,
  contractStatus: { value: bmw_contractstatusglobaloptionset ? bmw_contractstatusglobaloptionset : null },
  customised: bmw_exoticcontract === 20,
  startDate :bmw_startdate,
  endDate :bmw_endofcontract,
  days: bmw_duration ? bmw_duration : null,
  upfrontDiscount: bmw_contracttemplateid
    ? {
        id: bmw_contracttemplateid?.id,
        logicalName: bmw_contracttemplateid?.logicalName,
        name: bmw_contracttemplateid?.name,
      }
    : null,
    ...( bmw_contractstatusglobaloptionset !==  originalContractStatus
      ? {  bmw_contractstatusglobaloptionset: { value:  originalContractStatus } }
      : {}),
  termsConditions: bmw_terms_and_conditions ? bmw_terms_and_conditions : null,
  dateSigned : bmw_datesigned,
  lastDateSubmitClaim : bmw_datetosubmitclaimdocuments,
  signedByNSC: bmw_signedbynsc === 20,
  lastCompletedOrder : bmw_lastorderdate,
  lastAgreementDate : bmw_lastagreementdate,
  totalCompletedOrders : bmw_totalcompletedorders,
  ownVehicleOrders : bmw_bulkapprovalteam,
  leasedVehicleOrders : bmw_leasedvehicleorders
});

export { updateContractMapper };
