export const createBPRoleMapper =  ({
    bmw_parentid,
    bmw_childid,
    bmw_type,
    bmw_startdate,
    bmw_enddate,
}) => {
    return {
        parentAccount: bmw_parentid || {},
        childAccount: bmw_childid || {},
        type: bmw_type || {},
        startDate: bmw_startdate || "",
        endDate: bmw_enddate || "",
    };
}